{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.JQCtrls;

{$DEFINE NOPP}

interface

uses
  Classes, WebLib.Graphics, SysUtils, Web, WebLib.Controls, js, libJQuery, jqwidget,
  WebLib.Menus, WEBLib.ExtCtrls;

type
  TJQXDayOfWeek = (dowMonday, dowTuesday, dowWednesday, dowThursday,
    dowFriday, dowSaturday, dowSunday, dowLocaleDefault);
  TJQXInputKind = (diDate, diTime, diDateTime);
  TJQXMenuMode = (mmHorizontal, mmVertical, mmPopup);
  TJQXColorMode = (cmHue, cmSaturation);
  TJQXSymbolPosition = (spLeft, spRight);
  TJQXInputMode = (imAdvanced, imSimple);
  //TJQXRatingPrecision = (rpFull, rpHalf);
  TJQXProgressOrientation = (poHorizontal, poVertical);
  TJQXProgressLayout = (plNormal, plReverse);
  TJQXTemplate = (ttDefault, ttPrimary, ttSuccess, ttWarning, ttDanger, ttInfo);
  TJQXHorizontalPosition = (hpLeft, hpRight, hpBoth);
  TJQXVerticalPosition = (vpTop, vpBottom, vpBoth);
  TJQXSliderMode = (smDefault, smFixed);
  TJQXKnobMarkType = (mtCircle, mtLine);
  TJQXKnobPointerType = (ptArrow, ptCircle, ptLine);
  TJQXPosition = (xpLeft, xpTop, xpCenter, xpBottom, xpRight, xpTopLeft,
    xpBottomLeft, xpTopRight, xpBottomRight);
  TJQXImagePosition = (ipAfterImage, ipAboveImage, ipBelowImage, ipBeforeImage, ipOverlay);
  TJQXGroupMode = (gmDefault, gmCheckBox, gmRadioButton);
  TJQXTagCloudSort = (tcsNone, tcsLabel, tcsValue);
  TJQXSortOrder = (soAscending, soDescending);
  TJQXTextCase = (tcOriginal, tcUpperCase, tcLowerCase, tcFirstUpper, tcCamelCase);
  TJQXAnimationType = (atNone, atFade, atSlide);
  TJQXTabPosition = (tpTop, tpBottom);
  TJQXToggleMode = (tmClick, tmDoubleClick, tmMouseEnter);

  TJQXCalendarEventArgs = class(TPersistent)
  private
    FDate: TDateTime;
  published
    property Date: TDateTime read FDate write FDate;
  end;

  TJQXDateEvent = procedure(Sender: TObject; Event: TJQXCalendarEventArgs) of object;

  TJQXMenuEventArgs = class(TPersistent)
  private
    FSource: TMenuItem;
  published
    property Source: TMenuItem read FSource write FSource;
  end;

  TJQXItemEvent = procedure(Sender: TObject; Event: TJQXMenuEventArgs) of object;

  TJQXSliderEventArgs = class(TPersistent)
  private
    FValue: Double;
    FMaximumValue: Double;
    FMinimumValue: Double;
  published
    property Value: Double read FValue write FValue;
    property MinimumValue: Double read FMinimumValue write FMinimumValue;
    property MaximumValue: Double read FMaximumValue write FMaximumValue;
  end;

  TJQXSliderEvent = procedure(Sender: TObject; Event: TJQXSliderEventArgs) of object;

  TJQXButtonEventArgs = class(TPersistent)
  private
    FButtonIndex: Integer;
  published
    property ButtonIndex: Integer read FButtonIndex write FButtonIndex;
  end;

  TJQXButtonEvent = procedure(Sender: TObject; Event: TJQXButtonEventArgs) of object;

  TJQXTagEventArgs = class(TPersistent)
  private
    FTagIndex: Integer;
    FDisplayIndex: Integer;
    FTagLabel: String;
    FTagValue: Integer;
  published
    property TagIndex: Integer read FTagIndex write FTagIndex;
    property TagLabel: String read FTagLabel write FTagLabel;
    property TagValue: Integer read FTagValue write FTagValue;
    property DisplayIndex: Integer read FDisplayIndex write FDisplayIndex;
  end;

  TJQXTagEvent = procedure(Sender: TObject; Event: TJQXTagEventArgs) of object;

  TJQXTabEventArgs = class(TPersistent)
  private
    FTabIndex: Integer;
  published
    property TabIndex: Integer read FTabIndex write FTabIndex;
  end;

  TJQXTabEvent = procedure(Sender: TObject; Event: TJQXTabEventArgs) of object;


  TJQXCustomControl = class(TjQueryCustomControl)
  private
    FTheme: string;
  protected
    function GetTheme(ATheme: string): string;
    function RequiredBaseURL: string; override;
    procedure RequiredScriptLoaded(Event: TJSEvent); override;
  public
    procedure SetTheme(AValue: string); virtual;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Theme: string read FTheme write SetTheme;
    property Visible;
  end;

  TJQXCalendar = class(TJQXCustomControl)
  private
    FFirstDayOfWeek: TJQXDayOfWeek;
    FDate: TDateTime;
    FEndDate: TDateTime;
    FIsLoaded: Boolean;
    FMaxDate: TDateTime;
    FMinDate: TDateTime;
    FMultiSelect: Boolean;
    FOtherMonthDays: Boolean;
    FShowToday: Boolean;
    FWeekNumbers: Boolean;
    FOnDateClick: TJQXDateEvent;
    FOnNavigateClick: TJQXDateEvent;
    function HandleDateClick(Event: TJQXEvent): Boolean; virtual;
    function HandleNavigateClick(Event: TJQXEvent): Boolean; virtual;
  protected
    procedure UpdateElement; override;
    procedure UpdateSize; reintroduce;

    procedure SetDate(AValue: TDateTime);
    function GetDate: TDateTime;
    procedure SetEndDate(AValue: TDateTime);
    function GetEndDate: TDateTime;

    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
    procedure SetFirstDayOfWeek(AValue: TJQXDayOfWeek);
    procedure SetMaxDate(AValue: TDateTime);
    procedure SetMinDate(AValue: TDateTime);
    procedure SetMultiSelect(AValue: boolean);
    procedure SetOtherMonthDays(AValue: boolean);
    procedure SetShowToday(AValue: boolean);
    procedure SetWeekNumbers(AValue: boolean);
  public
    procedure InitJQuery; override;
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property FirstDayOfWeek: TJQXDayOfWeek read FFirstDayOfWeek write SetFirstDayOfWeek;
    property Date: TDateTime read GetDate write SetDate;
    property EndDate: TDateTime read GetEndDate write SetEndDate;
    property MaxDate: TDateTime read FMaxDate write SetMaxDate;
    property MinDate: TDateTime read FMinDate write SetMinDate;
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect;
    property OtherMonthDays: Boolean read FOtherMonthDays write SetOtherMonthDays;
    property ShowToday: Boolean read FShowToday write SetShowToday;
    property WeekNumbers: Boolean read FWeekNumbers write SetWeekNumbers;
    property OnDateClick: TJQXDateEvent read FOnDateClick write FOnDateClick;
    property OnNavigateClick: TJQXDateEvent read FOnNavigateClick write FOnNavigateClick;
  end;

  TWebJQXCalendar = class(TJQXCalendar);

  TJQXDateTimeInput = class(TJQXCustomControl)
  private
    FFirstDayOfWeek: TJQXDayOfWeek;
    FDate: TDateTime;
    FEndDate: TDateTime;
    FIsLoaded: Boolean;
    FKind: TJQXInputKind;
    FMaxDate: TDateTime;
    FMinDate: TDateTime;
    FMultiSelect: boolean;
    FShowToday: boolean;
    FWeekNumbers: boolean;
    FOnDateClick: TJQXDateEvent;
    function HandleDateClick(Event: TJQXEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;

    procedure SetDate(AValue: TDateTime);
    function GetDate: TDateTime;
    procedure SetEndDate(AValue: TDateTime);
    function GetEndDate: TDateTime;

    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
    procedure SetFirstDayOfWeek(AValue: TJQXDayOfWeek);
    procedure SetKind(AValue: TJQXInputKind);
    procedure SetMaxDate(AValue: TDateTime);
    procedure SetMinDate(AValue: TDateTime);
    procedure SetMultiSelect(AValue: boolean);
    procedure SetShowToday(AValue: boolean);
    procedure SetWeekNumbers(AValue: boolean);
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property FirstDayOfWeek: TJQXDayOfWeek read FFirstDayOfWeek write SetFirstDayOfWeek;
    property Date: TDateTime read GetDate write SetDate;
    property EndDate: TDateTime read GetEndDate write SetEndDate;
    property Kind: TJQXInputKind read FKind write SetKind;
    property MaxDate: TDateTime read FMaxDate write SetMaxDate;
    property MinDate: TDateTime read FMinDate write SetMinDate;
    property MultiSelect: boolean read FMultiSelect write SetMultiSelect;
    property ShowToday: boolean read FShowToday write SetShowToday;
    property WeekNumbers: boolean read FWeekNumbers write SetWeekNumbers;
    property OnDateClick: TJQXDateEvent read FOnDateClick write FOnDateClick;
  end;

  TWebJQXDateTimeInput = class(TJQXDateTimeInput);

  TJQXMenu = class(TJQXCustomControl)
  private
    FIsLoaded: Boolean;
    FMode: TJQXMenuMode;
    FMenu: TMainMenu;
    FUpdateCount: Integer;
    FOnItemClick: TJQXItemEvent;
    function HandleItemClick(Event: TJQXElementEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    function GetMenuSource(Item: TMenuItem): string;
    function GetItemByName(AMenu: TMenuItem; Name: string): TMenuItem;

    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
    procedure SetMode(AValue: TJQXMenuMode);
    procedure SetMenu(AValue: TMainMenu);
    procedure ToggleItem(AMenuItem: TMenuItem; Disabled: Boolean);
  public
    procedure CreateInitialize; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure SetTheme(AValue: string); override;
    procedure SetItemEnabled(AMenuItem: TMenuItem);
    procedure SetItemDisabled(AMenuItem: TMenuItem);
  published
    property Mode: TJQXMenuMode read FMode write SetMode default mmHorizontal;
    property Menu: TMainMenu read FMenu write SetMenu;
    property OnItemClick: TJQXItemEvent read FOnItemClick write FOnItemClick;
  end;

  TWebJQXMenu = class(TJQXMenu);

  TJQXMaskedInput = class(TJQXCustomControl)
  private
    FMask: string;
    FText: string;
    FOnChange: TNotifyEvent;
    FIsLoaded: boolean;
    function HandleMaskedInputChange(Event: TJQXElementEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;

    function GetText: string;
    procedure SetMask(AValue: string);
    procedure SetText(AValue: string);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property Mask: string read FMask write SetMask;
    property Text: string read GetText write SetText;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXMaskedInput = class(TJQXMaskedInput);

  TJQXColorPicker = class(TJQXCustomControl)
  private
    FColor: TColor;
    FColorMode: TJQXColorMode;
    FOnChange: TNotifyEvent;
    FUpdateCount: Integer;
    FIsLoaded: boolean;
    function HandleChange(Event: TJQXEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;

    function GetColor: TColor;
    procedure SetColorPickerColor(AValue: TColor);
    procedure SetColorMode(AValue: TJQXColorMode);
  published
    property Color: TColor read GetColor write SetColorPickerColor default clBlack;
    property ColorMode: TJQXColorMode read FColorMode write SetColorMode default cmSaturation;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXColorPicker = class(TJQXColorPicker);

  TJQXComboBox = class(TJQXCustomControl)
  private
    FAutoComplete: Boolean;
    FDisabled: TList;
    FItemIndex: Integer;
    FItems: TStringList;
    FSelected: TList;
    FMultiSelect: Boolean;
    FTextHint: string;
    FOnChange: TNotifyEvent;
    FIsLoaded: Boolean;
    FText: string;
    FOnExit: TNotifyEvent;
    function HandleChange(Event: TJQXEvent): Boolean; virtual;
    function HandleExit(Event: TJQXEvent): Boolean; virtual;
    procedure SetText(const Value: string);
    function GetText: string;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure HandleItemsChanged(Sender: TObject);
    function GetDisabled(AIndex: Integer): Boolean;
    procedure SetDisabled(AIndex: Integer; AValue: Boolean);
    function GetSelected(AIndex: Integer): Boolean;
    procedure SetSelected(AIndex: Integer; AValue: Boolean);
    procedure SetAutoComplete(AValue: Boolean);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: Boolean); override;
    procedure SetMultiSelect(AValue: Boolean);
    procedure SetTextHint(AValue: string);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;

    function ItemsToJSON: string;
    function GetItemIndex: Integer;
    procedure SetItemIndex(AIndex: Integer);
    procedure SetItems(AItems: TStringList);
    procedure SetTheme(AValue: string); override;

    property Disabled[i: Integer]: Boolean read GetDisabled write SetDisabled;
    property Selected[i: Integer]: Boolean read GetSelected write SetSelected;
  published
    property AutoComplete: Boolean read FAutoComplete write SetAutoComplete default false;
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property Items: TStringList read FItems write SetItems;
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect default false;
    property TextHint: string read FTextHint write SetTextHint;
    property Text: string read GetText write SetText;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnExit: TNotifyEvent read FOnExit write FOnExit;
  end;

  TWebJQXComboBox = class(TJQXComboBox);

  TJQXDropDownList = class(TJQXCustomControl)
  private
    FDisabled: TList;
    FItemIndex: Integer;
    FItems: TStringList;
    FSelected: TList;
    FMultiSelect: Boolean;
    FTextHint: string;
    FOnChange: TNotifyEvent;
    FIsLoaded: Boolean;
    function HandleChange(Event: TJQXEvent): Boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure HandleItemsChanged(Sender: TObject);
    function GetDisabled(AIndex: Integer): Boolean;
    procedure SetDisabled(AIndex: Integer; AValue: Boolean);
    function GetSelected(AIndex: Integer): Boolean;
    procedure SetSelected(AIndex: Integer; AValue: Boolean);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: Boolean); override;
    procedure SetMultiSelect(AValue: Boolean);
    procedure SetTextHint(AValue: string);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;

    function ItemsToJSON: string;
    function GetItemIndex: Integer;
    procedure SetItemIndex(AIndex: Integer);
    procedure SetItems(AItems: TStringList);
    procedure SetTheme(AValue: string); override;

    property Disabled[i: Integer]: Boolean read GetDisabled write SetDisabled;
    property Selected[i: Integer]: Boolean read GetSelected write SetSelected;
  published
    property ItemIndex: Integer read GetItemIndex write SetItemIndex default -1;
    property Items: TStringList read FItems write SetItems;
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect default false;
    property TextHint: string read FTextHint write SetTextHint;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXDropDownList = class(TJQXDropDownList);

  TJQXNumberInput = class(TJQXCustomControl)
  private
    FDigits: Integer;
    FDecimalDigits: Integer;
    FInputMode: TJQXInputMode;
    FMaxValue: Double;
    FMinValue: Double;
    FShowSpinButtons: Boolean;
    FSpinButtonsStep: Integer;
    FSymbol: string;
    FSymbolPosition: TJQXSymbolPosition;
    FValue: Double;
    FOnChange: TNotifyEvent;
    FIsLoaded: boolean;
    function HandleNumberInputChange(Event: TJQXElementEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;

    function GetValue: Double;
    procedure SetDigits(AValue: Integer);
    procedure SetDecimalDigits(AValue: Integer);
    procedure SetInputMode(AValue: TJQXInputMode);
    procedure SetMaxValue(AValue: Double);
    procedure SetMinValue(AValue: Double);
    procedure SetShowSpinButtons(AValue: Boolean);
    procedure SetSpinButtonsStep(AValue: Integer);
    procedure SetSymbol(AValue: string);
    procedure SetSymbolPosition(AValue: TJQXSymbolPosition);
    procedure SetValue(AValue: Double);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property Digits: Integer read FDigits write SetDigits;
    property DecimalDigits: Integer read FDecimalDigits write SetDecimalDigits;
    property InputMode: TJQXInputMode read FInputMode write SetInputMode;
    property MaxValue: Double read FMaxValue write SetMaxValue;
    property MinValue: Double read FMinValue write SetMinValue;
    property ShowSpinButtons: Boolean read FShowSpinButtons write SetShowSpinButtons;
    property SpinButtonsStep: Integer read FSpinButtonsStep write SetSpinButtonsStep;
    property Symbol: String read FSymbol write SetSymbol;
    property SymbolPosition: TJQXSymbolPosition read FSymbolPosition write SetSymbolPosition;
    property Value: Double read GetValue write SetValue;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXNumberInput = class(TJQXNumberInput);

  TJQXRating = class(TJQXCustomControl)
  private
    FItemCount: Integer;
    //FPrecision: TJQXRatingPrecision;
    FValue: Double;
    FOnChange: TNotifyEvent;
    FIsLoaded: boolean;
    function HandleRatingChange(Event: TJQXElementEvent): boolean; virtual;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;

    function GetValue: Double;
    procedure SetItemCount(AValue: Integer);
    //procedure SetPrecision(AValue: TJQXRatingPrecision);
    procedure SetValue(AValue: Double);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
  published
    property ItemCount: Integer read FItemCount write SetItemCount;
    //property Precision: TJQXRatingPrecision read FPrecision write SetPrecision;
    property Value: Double read GetValue write SetValue;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXRating = class(TJQXRating);

  TJQXButton = class(TJQXCustomControl)
  private
    FIsLoaded: boolean;
    FOnClick: TNotifyEvent;
    FCaption: string;
    FRoundedBorders: Boolean;
    FImageURL: string;
    FImageHeight: Integer;
    FImagePosition: TJQXPosition;
    FCaptionPosition: TJQXPosition;
    FImageWidth: Integer;
    FCaptionImageRelation: TJQXImagePosition;
    FTemplate: TJQXTemplate;
    function HandleClick(Event: TJQXElementEvent): boolean; virtual;
    procedure SetCaption(const Value: string);
    procedure SetRoundedBorders(const Value: Boolean);
    procedure SetCaptionImageRelation(const Value: TJQXImagePosition);
    procedure SetCaptionPosition(const Value: TJQXPosition);
    procedure SetImagePosition(const Value: TJQXPosition);
    procedure SetImageHeight(const Value: Integer);
    procedure SetImageURL(const Value: string);
    procedure SetImageWidth(const Value: Integer);
    procedure SetTemplate(const Value: TJQXTemplate);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetTop(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property Caption: string read FCaption write SetCaption;
    property CaptionPosition: TJQXPosition read FCaptionPosition write SetCaptionPosition default xpLeft;
    property CaptionImageRelation: TJQXImagePosition read FCaptionImageRelation write SetCaptionImageRelation default ipAfterImage;
    property ImageURL: string read FImageURL write SetImageURL;
    property ImagePosition: TJQXPosition read FImagePosition write SetImagePosition default xpLeft;
    property ImageHeight: Integer read FImageHeight write SetImageHeight default 16;
    property ImageWidth: Integer read FImageWidth write SetImageWidth default 16;
    property RoundedBorders: Boolean read FRoundedBorders write SetRoundedBorders default true;
    property Template: TJQXTemplate read FTemplate write SetTemplate default ttDefault;
    property OnClick: TNotifyEvent read FOnClick write FOnClick;
  end;

  TWebJQXButton = class(TJQXButton);

  TJQXButtonGroup = class(TJQXCustomControl)
  private
    FSelectedButton: TList;
    FIsLoaded: boolean;
    FTemplate: TJQXTemplate;
    FMode: TJQXGroupMode;
    FEnableHover: Boolean;
    FItems: TStringList;
    FOnClick: TJQXButtonEvent;
    function HandleClick(Event: TJQXEvent): boolean; virtual;
    procedure SetTemplate(const Value: TJQXTemplate);
    procedure SetMode(const Value: TJQXGroupMode);
    procedure SetEnableHover(const Value: Boolean);
    procedure SetItems(const AItems: TStringList);
    function GetButtonSelect(Button: Integer): Boolean;
    procedure SetButtonSelect(Button: Integer; const Value: Boolean);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    procedure FixMargin;
    procedure DoItemsChanged(Sender: TObject);
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetTheme(AValue: string); override;

    property ButtonSelect[Button: Integer]: Boolean read GetButtonSelect write SetButtonSelect;
  published
    property EnableHover: Boolean read FEnableHover write SetEnableHover default True;
    property Items: TStringList read FItems write SetItems;
    property Mode: TJQXGroupMode read FMode write SetMode default gmDefault;
    property Template: TJQXTemplate read FTemplate write SetTemplate default ttDefault;
    property OnClick: TJQXButtonEvent read FOnClick write FOnClick;
  end;

  TWebJQXButtonGroup = class(TJQXButtonGroup);

  TJQXKnob = class;
  TJQXKnobAppearance = class;

  TJQXKnobLabels = class(TPersistent)
  private
    FOwner: TJQXKnobAppearance;
    FVisible: Boolean;
    FStep: Integer;
    FOffset: Integer;
    procedure SetOffset(const Value: Integer);
    procedure SetStep(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
  public
    constructor Create(AOwner: TJQXKnobAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Offset: Integer read FOffset write SetOffset default 88;
    property Step: Integer read FStep write SetStep default 10;
    property Visible: Boolean read FVisible write SetVisible default true;
  end;

  TJQXKnobProgressBar = class(TPersistent)
  private
    FOwner: TJQXKnobAppearance;
    FBorderColor: TColor;
    FSize: Integer;
    FBackgroundColor: TColor;
    FOffset: Integer;
    FColor: TColor;
    procedure SetBackgroundColor(const Value: TColor);
    procedure SetBorderColor(const Value: TColor);
    procedure SetColor(const Value: TColor);
    procedure SetOffset(const Value: Integer);
    procedure SetSize(const Value: Integer);
  public
    constructor Create(AOwner: TJQXKnobAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Color: TColor read FColor write SetColor default clWebSilver;
    property Size: Integer read FSize write SetSize default 9;
    property Offset: Integer read FOffset write SetOffset default 60;
    property BackgroundColor: TColor read FBackgroundColor write SetBackgroundColor default clWebGray;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clWebBlack;
  end;

  TJQXKnobMarks = class(TPersistent)
  private
    FOwner: TJQXKnobAppearance;
    FOffset: Integer;
    FMajorSize: Integer;
    FColorProgress: TColor;
    FWidth: Integer;
    FBorderColorRemaining: TColor;
    FBorderColorProgress: TColor;
    FMinorInterval: Integer;
    FSize: Integer;
    FColorRemaining: TColor;
    FMajorInterval: Integer;
    FMarkType: TJQXKnobMarkType;
    procedure SetOffset(const Value: Integer);
    procedure SetBorderColorProgress(const Value: TColor);
    procedure SetBorderColorRemaining(const Value: TColor);
    procedure SetColorProgress(const Value: TColor);
    procedure SetColorRemaining(const Value: TColor);
    procedure SetMajorInterval(const Value: Integer);
    procedure SetMajorSize(const Value: Integer);
    procedure SetMarkType(const Value: TJQXKnobMarkType);
    procedure SetMinorInterval(const Value: Integer);
    procedure SetSize(const Value: Integer);
    procedure SetWidth(const Value: Integer);
  public
    constructor Create(AOwner: TJQXKnobAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property ColorRemaining: TColor read FColorRemaining write SetColorRemaining default clGray;
    property BorderColorRemaining: TColor read FBorderColorRemaining write SetBorderColorRemaining default clGray;
    property ColorProgress: TColor read FColorProgress write SetColorProgress default clSilver;
    property BorderColorProgress: TColor read FBorderColorProgress write SetBorderColorProgress default clSilver;
    property MarkType: TJQXKnobMarkType read FMarkType write SetMarkType default mtLine;
    property Offset: Integer read FOffset write SetOffset default 71;
    property Width: Integer read FWidth write SetWidth default 3;
    property Size: Integer read FSize write SetSize default 6;
    property MajorSize: Integer read FMajorSize write SetMajorSize default 9;
    property MajorInterval: Integer read FMajorInterval write SetMajorInterval default 10;
    property MinorInterval: Integer read FMinorInterval write SetMinorInterval default 2;
  end;

  TJQXKnobPointer = class(TPersistent)
  private
    FOwner: TJQXKnobAppearance;
    FOffset: Integer;
    FColor: TColor;
    FWidth: Integer;
    FVisible: Boolean;
    FSize: Integer;
    FPointerType: TJQXKnobPointerType;
    FBorderColor: TColor;
    procedure SetOffset(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
    procedure SetColor(const Value: TColor);
    procedure SetPointerType(const Value: TJQXKnobPointerType);
    procedure SetSize(const Value: Integer);
    procedure SetWidth(const Value: Integer);
    procedure SetBorderColor(const Value: TColor);
  public
    constructor Create(AOwner: TJQXKnobAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property PointerType: TJQXKnobPointerType read FPointerType write SetPointerType default ptArrow;
    property Color: TColor read FColor write SetColor default clGray;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clSilver;
    property Size: Integer read FSize write SetSize default 59;
    property Offset: Integer read FOffset write SetOffset default 49;
    property Visible: Boolean read FVisible write SetVisible default true;
    property Width: Integer read FWidth write SetWidth default 20;
  end;

  TJQXKnobAppearance = class(TPersistent)
  private
    FOwner: TJQXKnob;
    FLabels: TJQXKnobLabels;
    FProgressBar: TJQXKnobProgressBar;
    FMarks: TJQXKnobMarks;
    FPointer: TJQXKnobPointer;
    FBorderColor: TColor;
    FColor: TColor;
    FBorderWidth: Integer;
    procedure SetBorderColor(const Value: TColor);
    procedure SetBorderWidth(const Value: Integer);
    procedure SetColor(const Value: TColor);
  public
    constructor Create(AOwner: TJQXKnob); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Color: TColor read FColor write SetColor default clNone;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clNone;
    property BorderWidth: Integer read FBorderWidth write SetBorderWidth default 1;
    property Labels: TJQXKnobLabels read FLabels write FLabels;
    property ProgressBar: TJQXKnobProgressBar read FProgressBar write FProgressBar;
    property Marks: TJQXKnobMarks read FMarks write FMarks;
    property Pointer: TJQXKnobPointer read FPointer write FPointer;
  end;

  TJQXKnob = class(TJQXCustomControl)
  private
    FValue: Double;
    FOnChange: TNotifyEvent;
    FIsLoaded: boolean;
    FEndAngle: Integer;
    FStartAngle: Integer;
    FAppearance: TJQXKnobAppearance;
    FMaximum: Integer;
    FMinimum: Integer;
    FStep: Integer;
    procedure SetEndAngle(const Value: Integer);
    procedure SetStartAngle(const Value: Integer);
    function HandleKnobChange(Event: TJQXElementEvent): boolean; virtual;
    procedure SetAppearance(const Value: TJQXKnobAppearance);
    procedure SetMaximum(const Value: Integer);
    procedure SetMinimum(const Value: Integer);
    procedure SetStep(const Value: Integer);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;

    function GetValue: Double;
    procedure SetValue(AValue: Double);
    procedure SetEnabled(Value: boolean); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Appearance: TJQXKnobAppearance read FAppearance write SetAppearance;
    property Maximum: Integer read FMaximum write SetMaximum default 100;
    property Minimum: Integer read FMinimum write SetMinimum default 0;
    property Step: Integer read FStep write SetStep default 1;
    property Value: Double read GetValue write SetValue;
    property StartAngle: Integer read FStartAngle write SetStartAngle default 120;
    property EndAngle: Integer read FEndAngle write SetEndAngle default 420;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXKnob = class(TJQXKnob);

  TJQXProgressBar = class;
  TJQXProgressBarColors = class;

  TJQXProgressBarColor = class(TCollectionItem)
  private
    FStop: Integer;
    FOwner: TJQXProgressBarColors;
    FColor: TColor;
    procedure SetStop(const Value: Integer);
    procedure SetColor(const Value: TColor);
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  protected
    function GetDisplayName: string; override;
  published
    property Color: TColor read FColor write SetColor;
    property Stop: Integer read FStop write SetStop;
  end;

  TJQXProgressBarColors = class(TCollection)
  private
    FOwner: TJQXProgressBar;
    function GetItems(Index: Integer): TJQXProgressBarColor;
    procedure SetItems(Index: Integer; const Value: TJQXProgressBarColor);
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TJQXProgressBar); overload;
    function GetOwner: TPersistent; override;
    function Add: TJQXProgressBarColor; reintroduce;
    function Insert(Index: Integer):TJQXProgressBarColor; reintroduce;
    property Items[Index: Integer]: TJQXProgressBarColor read GetItems write SetItems; default;
  end;

  TJQXProgressBar = class(TJQXCustomControl)
  private
    FValue: Integer;
    FIsLoaded: boolean;
    FLayout: TJQXProgressLayout;
    FShowValue: Boolean;
    FOrientation: TJQXProgressOrientation;
    FMaximum: Integer;
    FMinimum: Integer;
    FAnimationDuration: Integer;
    FColorRanges: TJQXProgressBarColors;
    FTemplate: TJQXTemplate;
    procedure SetLayout(const Value: TJQXProgressLayout);
    procedure SetOrientation(const Value: TJQXProgressOrientation);
    procedure SetShowValue(const Value: Boolean);
    procedure SetMaximum(const Value: Integer);
    procedure SetMinimum(const Value: Integer);
    procedure SetAnimationDuration(const Value: Integer);
    procedure SetColorRanges(const Value: TJQXProgressBarColors);
    procedure SetTemplate(const Value: TJQXTemplate);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    function ColorsToArray: TJSArray;

    procedure SetValue(AValue: Integer);
    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetTheme(AValue: string); override;
  published
    property AnimationDuration: Integer read FAnimationDuration write SetAnimationDuration default 300;
    property ColorRanges: TJQXProgressBarColors read FColorRanges write SetColorRanges;
    property Maximum: Integer read FMaximum write SetMaximum default 100;
    property Minimum: Integer read FMinimum write SetMinimum default 0;
    property Value: Integer read FValue write SetValue;
    property ShowValue: Boolean read FShowValue write SetShowValue default True;
    property Template: TJQXTemplate read FTemplate write SetTemplate default ttDefault;
    property Orientation: TJQXProgressOrientation read FOrientation write SetOrientation default poHorizontal;
    property Layout: TJQXProgressLayout read FLayout write SetLayout default plNormal;
  end;

  TWebJQXProgressBar = class(TJQXProgressBar);

  TJQXSlider = class(TJQXCustomControl)
  private
    FValue: Double;
    FOnChange: TJQXSliderEvent;
    FIsLoaded: boolean;
    FLayout: TJQXProgressLayout;
    FMaximumValue: Double;
    FTemplate: TJQXTemplate;
    FMinorTickSize: Integer;
    FShowRangeSlider: Boolean;
    FMaximum: Double;
    FMinimumValue: Double;
    FTicksFrequency: Integer;
    FMinimum: Double;
    FShowRange: Boolean;
    FShowButtons: Boolean;
    FShowTicks: Boolean;
    FMinorTicksFrequency: Integer;
    FButtonsPosition: TJQXHorizontalPosition;
    FTicksPosition: TJQXVerticalPosition;
    FTickSize: Integer;
    FShowMinorTicks: Boolean;
    FStep: Double;
    FMode: TJQXSliderMode;
    function HandleSliderChange(Event: TJQXEvent): boolean; virtual;
    procedure SetButtonsPosition(const Value: TJQXHorizontalPosition);
    procedure SetLayout(const Value: TJQXProgressLayout);
    procedure SetMaximum(const Value: Double);
    procedure SetMaximumValue(const Value: Double);
    procedure SetMinimum(const Value: Double);
    procedure SetMinimumValue(const Value: Double);
    procedure SetMinorTicksFrequency(const Value: Integer);
    procedure SetMinorTickSize(const Value: Integer);
    procedure SetShowButtons(const Value: Boolean);
    procedure SetShowRange(const Value: Boolean);
    procedure SetShowRangeSlider(const Value: Boolean);
    procedure SetShowTicks(const Value: Boolean);
    procedure SetTemplate(const Value: TJQXTemplate);
    procedure SetTicksFrequency(const Value: Integer);
    procedure SetTickSize(const Value: Integer);
    procedure SetTicksPosition(const Value: TJQXVerticalPosition);
    procedure SetShowMinorTicks(const Value: Boolean);
    procedure SetStep(const Value: Double);
    procedure SetMode(const Value: TJQXSliderMode);
    function GetMaximumValue: Double;
    function GetMinimumValue: Double;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure UpdateSize; reintroduce;
    procedure BindEvents; override;

    function GetValue: Double;
    procedure SetValue(AValue: Double);
    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property ButtonsPosition: TJQXHorizontalPosition read FButtonsPosition write SetButtonsPosition default hpBoth;
    property TicksPosition: TJQXVerticalPosition read FTicksPosition write SetTicksPosition default vpBoth;
    property TicksFrequency: Integer read FTicksFrequency write SetTicksFrequency default 2;
    property TickSize: Integer read FTickSize write SetTickSize default 5;
    property MinorTicksFrequency: Integer read FMinorTicksFrequency write SetMinorTicksFrequency default 2;
    property MinorTickSize: Integer read FMinorTickSize write SetMinorTickSize default 5;
    property ShowButtons: Boolean read FShowButtons write SetShowButtons default true;
    property ShowTicks: Boolean read FShowTicks write SetShowTicks default true;
    property ShowMinorTicks: Boolean read FShowMinorTicks write SetShowMinorTicks default true;
    property ShowRange: Boolean read FShowRange write SetShowRange default true;
    property ShowRangeSlider: Boolean read FShowRangeSlider write SetShowRangeSlider default false;
    property MaximumValue: Double read GetMaximumValue write SetMaximumValue default 10;
    property MinimumValue: Double read GetMinimumValue write SetMinimumValue default 0;
    property Maximum: Double read FMaximum write SetMaximum default 10;
    property Minimum: Double read FMinimum write SetMinimum default 0;
    property Mode: TJQXSliderMode read FMode write SetMode default smDefault;
    property Step: Double read FStep write SetStep default 2;
    property Template: TJQXTemplate read FTemplate write SetTemplate default ttDefault;
    property Layout: TJQXProgressLayout read FLayout write SetLayout default plNormal;
    property Value: Double read GetValue write SetValue;
    property OnChange: TJQXSliderEvent read FOnChange write FOnChange;
  end;

  TWebJQXSlider = class(TJQXSlider);

  TJQXRangeSelector = class(TJQXCustomControl)
  private
    FOnChange: TNotifyEvent;
    FIsLoaded: boolean;
    FMaximumValue: Double;
    FMaximum: Double;
    FMinimumValue: Double;
    FMinimum: Double;
    FResizable: Boolean;
    FMinorTicksInterval: Integer;
    FMajorTicksInterval: Integer;
    FShowMinorTicks: Boolean;
    FShowMajorTicks: Boolean;
    FMoveOnClick: Boolean;
    FShowMarkers: Boolean;
    function HandleRangeChange(Event: TJQXEvent): boolean; virtual;
    procedure SetMaximum(const Value: Double);
    procedure SetMaximumValue(const Value: Double);
    procedure SetMinimum(const Value: Double);
    procedure SetMinimumValue(const Value: Double);
    function GetMaximumValue: Double;
    function GetMinimumValue: Double;
    procedure SetMajorTicksInterval(const Value: Integer);
    procedure SetMinorTicksInterval(const Value: Integer);
    procedure SetResizable(const Value: Boolean);
    procedure SetShowMinorTicks(const Value: Boolean);
    procedure SetShowMajorTicks(const Value: Boolean);
    procedure SetMoveOnClick(const Value: Boolean);
    procedure SetShowMarkers(const Value: Boolean);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;

    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property MaximumValue: Double read GetMaximumValue write SetMaximumValue default 10;
    property MinimumValue: Double read GetMinimumValue write SetMinimumValue default 0;
    property Maximum: Double read FMaximum write SetMaximum default 10;
    property Minimum: Double read FMinimum write SetMinimum default 0;
    property MajorTicksInterval: Integer read FMajorTicksInterval write SetMajorTicksInterval default 5;
    property MinorTicksInterval: Integer read FMinorTicksInterval write SetMinorTicksInterval default 1;
    property MoveOnClick: Boolean read FMoveOnClick write SetMoveOnClick default True;
    property Resizable: Boolean read FResizable write SetResizable default True;
    property ShowMajorTicks: Boolean read FShowMajorTicks write SetShowMajorTicks default True;
    property ShowMinorTicks: Boolean read FShowMinorTicks write SetShowMinorTicks default True;
    property ShowMarkers: Boolean read FShowMarkers write SetShowMarkers default True;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TWebJQXRangeSelector = class(TJQXRangeSelector);

  TJQXTagCloud = class;
  TJQXCloudTags = class;

  TJQXCloudTag = class(TCollectionItem)
  private
    FOwner: TJQXCloudTags;
    FTagValue: Integer;
    FTag: Integer;
    FTagName: String;
    FTagLabel: String;
    procedure SetTagName(const Value: String);
    procedure SetTagValue(const Value: Integer);
    procedure SetTagLabel(const Value: String);
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  protected
    function GetDisplayName: string; override;
  published
    property Tag: Integer read FTag write FTag;
    property TagLabel: String read FTagLabel write SetTagLabel;
    property TagName: String read FTagName write SetTagName;
    property TagValue: Integer read FTagValue write SetTagValue;
  end;

  TJQXCloudTags = class(TCollection)
  private
    FOwner: TJQXTagCloud;
    function GetItems(Index: Integer): TJQXCloudTag;
    procedure SetItems(Index: Integer; const Value: TJQXCloudTag);
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TJQXTagCloud); overload;
    function GetOwner: TPersistent; override;
    function Add: TJQXCloudTag; reintroduce;
    function Insert(Index: Integer):TJQXCloudTag; reintroduce;
    property Items[Index: Integer]: TJQXCloudTag read GetItems write SetItems; default;
  end;

  TJQXTagCloud = class(TJQXCustomControl)
  private
    FIsLoaded: boolean;
    FItems: TJQXCloudTags;
    FSortBy: TJQXTagCloudSort;
    FOnClick: TJQXTagEvent;
    FSortOrder: TJQXSortOrder;
    FTextCase: TJQXTextCase;
    FDisplayLimit: Integer;
    FDisplayMinValue: Integer;
    FDisplayTopWeighted: Boolean;
    FMaxColor: TColor;
    FMinColor: TColor;
    FDisplayMaxValue: Integer;
    FMaxFontSize: Integer;
    FMinFontSize: Integer;
    procedure SetSortBy(const Value: TJQXTagCloudSort);
    procedure SetSortOrder(const Value: TJQXSortOrder);
    procedure SetTextCase(const Value: TJQXTextCase);
    procedure SetDisplayLimit(const Value: Integer);
    procedure SetDisplayMaxValue(const Value: Integer);
    procedure SetDisplayMinValue(const Value: Integer);
    procedure SetDisplayTopWeighted(const Value: Boolean);
    procedure SetMaxColor(const Value: TColor);
    procedure SetMinColor(const Value: TColor);
    procedure SetMaxFontSize(const Value: Integer);
    procedure SetMinFontSize(const Value: Integer);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;

    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
    function DataToArray: TJSArray;
    function HandleItemClick(Event: TJQXEvent): boolean; virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DisplayLimit: Integer read FDisplayLimit write SetDisplayLimit default -1;
    property DisplayTopWeighted: Boolean read FDisplayTopWeighted write SetDisplayTopWeighted default False;
    property DisplayMaxValue: Integer read FDisplayMaxValue write SetDisplayMaxValue default -1;
    property DisplayMinValue: Integer read FDisplayMinValue write SetDisplayMinValue default -1;
    property Items: TJQXCloudTags read FItems write FItems;
    property MaxColor: TColor read FMaxColor write SetMaxColor default clNone;
    property MinColor: TColor read FMinColor write SetMinColor default clNone;
    property MaxFontSize: Integer read FMaxFontSize write SetMaxFontSize default 36;
    property MinFontSize: Integer read FMinFontSize write SetMinFontSize default 8;
    property SortBy: TJQXTagCloudSort read FSortBy write SetSortBy default tcsNone;
    property SortOrder: TJQXSortOrder read FSortOrder write SetSortOrder default soAscending;
    property TextCase: TJQXTextCase read FTextCase write SetTextCase default tcOriginal;
    property OnClick: TJQXTagEvent read FOnClick write FOnClick;
  end;

  TWebJQXTagCloud = class(TJQXTagCloud);

  TJQXResponsivePanel = class(TJQXCustomControl)
  private
    FIsLoaded: boolean;
    FOnOpen: TNotifyEvent;
    FAutoClose: Boolean;
    FOnClose: TNotifyEvent;
    FOnCollapse: TNotifyEvent;
    FOnExpand: TNotifyEvent;
    FToggleButtonSize: Integer;
    FCollapseBreakPoint: Integer;
    FAnimationType: TJQXAnimationType;
    function HandleOpen(Event: TJQXElementEvent): boolean; virtual;
    function HandleClose(Event: TJQXElementEvent): boolean; virtual;
    function HandleCollapse(Event: TJQXElementEvent): boolean; virtual;
    function HandleExpand(Event: TJQXElementEvent): boolean; virtual;
    procedure SetAutoClose(const Value: Boolean);
    procedure SetCollapsBreakPoint(const Value: Integer);
    procedure SetToggleButtonSize(const Value: Integer);
    procedure SetAnimationType(const Value: TJQXAnimationType);
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    function GetContentID: string;
    function GetButtonID: string;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;

    procedure Open;
    procedure Close;
    procedure Refresh;
  published
    property AnimationType: TJQXAnimationType read FAnimationType write SetAnimationType default atNone;
    property AutoClose: Boolean read FAutoClose write SetAutoClose default True;
    property CollapseBreakPoint: Integer read FCollapseBreakPoint write SetCollapsBreakPoint default 1000;
    property ToggleButtonSize: Integer read FToggleButtonSize write SetToggleButtonSize default 30;

    property OnOpen: TNotifyEvent read FOnOpen write FOnOpen;
    property OnClose: TNotifyEvent read FOnClose write FOnClose;
    property OnCollapse: TNotifyEvent read FOnCollapse write FOnCollapse;
    property OnExpand: TNotifyEvent read FOnExpand write FOnExpand;
  end;

  TWebJQXResponsivePanel = class(TJQXResponsivePanel);

  TJQXTabSheet = class(TDivPanel)
  private
    FCaption: string;
  protected
    procedure BindEvents; override;
  public
    procedure CreateInitialize; override;
  published
    property Caption: string read FCaption write FCaption;
  end;

  TWebJQXTabSheet = class(TJQXTabSheet);

  TJQXTabs = class(TJQXCustomControl)
  private
    FIsLoaded: boolean;
    FTabIndex: Integer;
    FScrollPosition: TJQXHorizontalPosition;
    FCollapsible: Boolean;
    FToggleMode: TJQXToggleMode;
    FSelectionTracker: Boolean;
    FReorder: Boolean;
    FEnableHover: Boolean;
    FPosition: TJQXTabPosition;
    FEnableScrollAnimation: Boolean;
    FScrollStep: Integer;
    FOnTabClick: TJQXTabEvent;
    FOnSelected: TJQXTabEvent;
    function HandleTabClick(Event: TJQXEvent): boolean; virtual;
    function HandleSelected(Event: TJQXEvent): boolean; virtual;
    procedure SetTabIndex(const Value: Integer);
    procedure SetCollapsible(const Value: Boolean);
    procedure SetEnableHover(const Value: Boolean);
    procedure SetEnableScrollAnimation(const Value: Boolean);
    procedure SetPosition(const Value: TJQXTabPosition);
    procedure SetReorder(const Value: Boolean);
    procedure SetScrollPosition(const Value: TJQXHorizontalPosition);
    procedure SetSelectionTracker(const Value: Boolean);
    procedure SetToggleMode(const Value: TJQXToggleMode);
    procedure SetScrollStep(const Value: Integer);
    function GetTabIndex: Integer;
  protected
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure BindEvents; override;
    procedure SetEnabled(Value: boolean); override;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
  public
    procedure CreateInitialize; override;
    procedure SetTheme(AValue: string); override;
  published
    property Collapsible: Boolean read FCollapsible write SetCollapsible default False;
    property EnableHover: Boolean read FEnableHover write SetEnableHover default True;
    property EnableScrollAnimation: Boolean read FEnableScrollAnimation write SetEnableScrollAnimation default True;
    property Position: TJQXTabPosition read FPosition write SetPosition default tpTop;
    property Reorder: Boolean read FReorder write SetReorder default False;
    property SelectionTracker: Boolean read FSelectionTracker write SetSelectionTracker default False;
    property ScrollPosition: TJQXHorizontalPosition read FScrollPosition write SetScrollPosition default hpRight;
    property ScrollStep: Integer read FScrollStep write SetScrollStep default 70;
    property ToggleMode: TJQXToggleMode read FToggleMode write SetToggleMode default tmClick;
    property TabIndex: Integer read GetTabIndex write SetTabIndex default 0;

    property OnTabClick: TJQXTabEvent read FOnTabClick write FOnTabClick;
    property OnSelected: TJQXTabEvent read FOnSelected write FOnSelected;
  end;

  TWebJQXTabs = class(TJQXTabs);

implementation

uses
  WEBLib.Dialogs;

var
  TMSJQWIDGETSLIBRARYURL: string = '';

function JSDateToTDate(AValue: TJSDate): TDateTime;
var
  y, m, d: Word;
begin
  Result := 0;

  if not Assigned(AValue) then
    Exit;

  y := AValue.FullYear;
  m := AValue.Month + 1;
  d := AValue.Date;
  Result := EncodeDate(y,m,d);
end;

function TDateToJSDate(AValue: TDateTime): TJSDate;
var
  y, m, d: Word;
begin
  Result := TJSDate.New(1970, 0, 1);

  if not Assigned(AValue) then
    Exit;

  DecodeDate(AValue, y, m, d);
  Result := TJSDate.New(y, m - 1, d);
end;

{ TJQXCustomControl }

function TJQXCustomControl.GetTheme(ATheme: string): string;
begin
  result := 'base';
  if ATheme <> '' then
    result := ATheme;
end;

function TJQXCustomControl.RequiredBaseURL: string;
var
  i: integer;
  el: TJSElement;
  scr: TJSHTMLScriptElement;
  s: string;
  found: boolean;

begin
  if (TMSJQWIDGETSLIBRARYURL <> '') then
  begin
    Result := TMSJQWIDGETSLIBRARYURL;
    Exit;
  end;

  Result := '';

  found := false;

  for i := 0 to document.head.children.length - 1 do
  begin
    el := TJSElement(document.head.children[i]);
    if (el.tagName = 'SCRIPT') then
    begin
      scr := TJSHTMLScriptElement(el);
      if (pos('jqxcore.js', scr.src) > 0) then
      begin
        s := scr.src;
        found := true;
        break;
      end;
    end;
  end;

  if found then
  begin
    TMSJQWIDGETSLIBRARYURL := Copy(s, 1, Length(s) - 10);
    Result := TMSJQWIDGETSLIBRARYURL;
  end
  else
  begin
    TMSJQWIDGETSLIBRARYURL := 'notfound';
    ShowMessage('No jQWidgets library specified in project HTML file.'#13#10'See "Manage JavaScript Libraries"');
  end;
end;

procedure TJQXCustomControl.RequiredScriptLoaded(Event: TJSEvent);
begin
  inherited;

  if ControlScriptCountLoaded = ControlScriptCount then
  begin
    ScriptLoaded := true;
    InitJQuery;
  end;
end;

procedure TJQXCustomControl.SetTheme(AValue: string);
begin
  FTheme := AValue;
end;

{ TJQXCalendar }

procedure TJQXCalendar.CreateInitialize;
begin
  inherited;
  FDate := Now;
  FFirstDayOfWeek := dowMonday;
  FIsLoaded := false;
  FMaxDate := EncodeDate(2100, 12, 31);
  FMinDate := EncodeDate(1970, 1, 1);
  FMultiSelect := False;
  FOtherMonthDays := True;
  FShowToday := False;
  FWeekNumbers := False;
  Enabled := True;
  Height := 220;
  Width := 220;

  RequiredScripts.Add('jqxdatetimeinput.js');
  RequiredScripts.Add('jqxcalendar.js');
  RequiredScripts.Add('globalization/globalize.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXCalendar.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar(New(['width', Width, 'height', Height]));
    TJQXWidgetCalendar(JQuery(GetJQID)).on('change', @HandleDateClick);
    TJQXWidgetCalendar(JQuery(GetJQID)).on('viewChange', @HandleNavigateClick);
    UpdateElement;
  end;
end;

procedure TJQXCalendar.UpdateElement;
var
  dow: Integer;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    case FFirstDayOfWeek of
      dowMonday: dow := 1;
      dowTuesday: dow := 2;
      dowWednesday: dow := 3;
      dowThursday: dow := 4;
      dowFriday: dow := 5;
      dowSaturday: dow := 6;
      dowSunday: dow := 0;
      dowLocaleDefault: dow := 1;
    end;

    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar(New(['width', Width, 'height', Height]));
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('firstDayOfWeek', dow);
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('setMaxDate', TDateToJSDate(FMaxDate));
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('setMinDate', TDateToJSDate(FMinDate));
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('showOtherMonthDays', FOtherMonthDays);
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('showFooter', FShowToday);
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('showWeekNumbers', FWeekNumbers);
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('disabled', not Enabled);
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('theme', GetTheme(Theme));

    SetDate(FDate);
  end;
end;

procedure TJQXCalendar.UpdateSize;
begin
  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar(New(['width', Width, 'height', Height]));
end;

function TJQXCalendar.HandleDateClick(Event: TJQXEvent): Boolean ;
var
  Args: TJQXCalendarEventArgs;
begin
  if Assigned(OnDateClick) then
  begin
    Args := TJQXCalendarEventArgs.Create;
    Args.Date := JSDateToTDate(TJSDate(Event.Args.Properties['date']));
    FDate := Args.Date;
    OnDateClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXCalendar.HandleNavigateClick(Event: TJQXEvent): Boolean ;
var
  Args: TJQXCalendarEventArgs;
begin
  if Assigned(OnNavigateClick) then
  begin
    Args := TJQXCalendarEventArgs.Create;
    Args.Date := JSDateToTDate(TJSDate(Event.Args.Properties['date']));
    OnNavigateClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TJQXCalendar.SetDate(AValue: TDateTime);
begin
  FDate := AValue;

  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin

    if MultiSelect then
    begin
      TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('selectionMode', 'range');
      TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('setRange', TDateToJSDate(FDate), TDateToJSDate(FEndDate));
    end
    else
    begin
      TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('selectionMode', 'default');
      TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('val', TDateToJSDate(FDate));
    end;
  end;
end;

function TJQXCalendar.GetDate: TDateTime;
var
  seldate: TDateTime;
  jso: TJSObject;
begin
  Result := FDate;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if MultiSelect then
    begin
      jso := TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('getRange');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso.Properties['from']));
    end
    else
    begin
      jso := TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('getDate');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso));
    end;

    Result := seldate;
  end;
end;

procedure TJQXCalendar.SetEndDate(AValue: TDateTime);
begin
  FEndDate := AValue;
  UpdateElement;
end;

function TJQXCalendar.GetEndDate: TDateTime;
var
  seldate: TDateTime;
  jso: TJSObject;
begin
  Result := FDate;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if MultiSelect then
    begin
      jso := TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('getRange');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso.Properties['to']));
    end
    else
    begin
      jso := TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('getDate');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso));
    end;

    Result := seldate;
  end;
end;

procedure TJQXCalendar.SetFirstDayOfWeek(AValue: TJQXDayOfWeek);
begin
  FFirstDayOFWeek := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetMaxDate(AValue: TDateTime);
begin
  if (AValue < 0) then
    Exit;

  FMaxDate := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetMinDate(AValue: TDateTime);
begin
  if (AValue < 0) then
    Exit;

  FMinDate := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetMultiSelect(AValue: Boolean );
begin
  FMultiSelect := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetOtherMonthDays(AValue: Boolean );
begin
  FOtherMonthDays := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetShowToday(AValue: Boolean );
begin
  FShowToday := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetCalendar(JQuery(GetJQID)).jqxCalendar('theme', GetTheme(AValue))
end;

procedure TJQXCalendar.SetWeekNumbers(AValue: Boolean );
begin
  FWeekNumbers := AValue;
  UpdateElement;
end;

procedure TJQXCalendar.SetEnabled(Value: Boolean );
begin
  inherited;
  UpdateElement;
end;

procedure TJQXCalendar.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;

procedure TJQXCalendar.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;


{ TJQXDateTimeInput }

procedure TJQXDateTimeInput.CreateInitialize;
begin
  inherited;
  FDate := Date;
  FFirstDayOfWeek := dowMonday;
  FIsLoaded := False;
  FKind := diTime;
  FMaxDate := 0;
  FMinDate := 0;
  FMultiSelect := False;
  FShowToday := False;
  FWeekNumbers := False;
  Height := 21;
  Width := 220;

  RequiredScripts.Add('jqxdatetimeinput.js');
  RequiredScripts.Add('jqxcalendar.js');
  RequiredScripts.Add('globalization/globalize.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXDateTimeInput.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).on('valueChanged', @HandleDateClick);
    UpdateElement;
  end;
end;

procedure TJQXDateTimeInput.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('theme', GetTheme(Theme));
  end;
end;

function TJQXDateTimeInput.HandleDateClick(Event: TJQXEvent): Boolean ;
var
  Args: TJQXCalendarEventArgs;
  jso: TJSObject;
begin
  if Assigned(OnDateClick) then
  begin
    Args := TJQXCalendarEventArgs.Create;
    if MultiSelect then
    begin
      jso := TJSObject(Event.Args.Properties['date']);
      Args.Date := JSDateToTDate(TJSDate(jso.Properties['to']));
    end
    else
      Args.Date := JSDateToTDate(TJSDate(Event.Args.Properties['date']));

    FDate := Args.Date;
    OnDateClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TJQXDateTimeInput.SetDate(AValue: TDateTime);
var
  seldate: string;
begin
  FDate := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if Kind = diTime then
    begin
      seldate := FormatDateTime('HH:mm:SS', AValue, TFormatSettings.Invariant);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('val', seldate);
    end
    else if Kind = diDate then
    begin
      seldate := FormatDateTime('YYYY-MM-DD', AValue, TFormatSettings.Invariant);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('setDate', seldate);
    end
    else
    begin
      seldate := FormatDateTime('YYYY-MM-DD HH:mm:SS', AValue, TFormatSettings.Invariant);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('setDate', seldate);
    end;
  end;

end;

function TJQXDateTimeInput.GetDate: TDateTime;
var
  seldate: TDateTime;
  jso: TJSObject;
begin
  Result := FDate;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if MultiSelect then
    begin
      jso := TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('getRange');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso.Properties['from']));
    end
    else
    begin
      jso := TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('getDate');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso));
    end;

    Result := seldate;
  end;
end;

procedure TJQXDateTimeInput.SetEndDate(AValue: TDateTime);
begin
  FEndDate := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('setRange', TDateToJSDate(FDate), TDateToJSDate(AValue));
end;

function TJQXDateTimeInput.GetEndDate: TDateTime;
var
  seldate: TDateTime;
  jso: TJSObject;
begin
  Result := FEndDate;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if MultiSelect then
    begin
      jso := TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('getRange');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso.Properties['to']));
    end
    else
    begin
      jso := TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('getDate');
      if Assigned(jso) then
        seldate := JSDateToTDate(TJSDate(jso));
    end;

    Result := seldate;
  end;
end;

procedure TJQXDateTimeInput.SetFirstDayOfWeek(AValue: TJQXDayOfWeek);
var
  dow: Integer;
begin
  FFirstDayOFWeek := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    case AValue of
      dowMonday: dow := 1;
      dowTuesday: dow := 2;
      dowWednesday: dow := 3;
      dowThursday: dow := 4;
      dowFriday: dow := 5;
      dowSaturday: dow := 6;
      dowSunday: dow := 0;
      dowLocaleDefault: dow := 1;
    end;

    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('firstDayOfWeek', dow);
  end;
end;

procedure TJQXDateTimeInput.SetKind(AValue: TJQXInputKind);
begin
  FKind := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if AValue = diDate then
    begin
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showCalendarButton', True);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showTimeButton', False);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('formatString', 'dd/MM/yyyy');
    end
    else if AValue = diTime then
    begin
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showCalendarButton', False);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showTimeButton', True);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('formatString', 'HH:mm:ss');
    end
    else
    begin
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showCalendarButton', True);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showTimeButton', True);
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('formatString', 'dd/MM/yyyy HH:mm:ss');
    end;
  end;
end;

procedure TJQXDateTimeInput.SetMaxDate(AValue: TDateTime);
begin
  FMaxDate := AValue;

  if not FIsLoaded then
    Exit;

  if (FMaxDate < 0) then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('setMaxDate', TDateToJSDate(AValue));
end;

procedure TJQXDateTimeInput.SetMinDate(AValue: TDateTime);
begin
  FMinDate := AValue;

  if not FIsLoaded then
    Exit;

  if (FMinDate < 0) then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('setMinDate', TDateToJSDate(AValue));
end;

procedure TJQXDateTimeInput.SetMultiSelect(AValue: Boolean );
begin
  FMultiSelect := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if (AValue) then
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('selectionMode', 'range')
    else
      TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('selectionMode', 'default');
  end;
end;

procedure TJQXDateTimeInput.SetShowToday(AValue: Boolean );
begin
  FShowToday := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showFooter', AValue);
end;

procedure TJQXDateTimeInput.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('theme', GetTheme(AValue))
end;

procedure TJQXDateTimeInput.SetWeekNumbers(AValue: Boolean );
begin
  FWeekNumbers := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('showWeekNumbers', AValue);
end;

procedure TJQXDateTimeInput.SetEnabled(Value: Boolean );
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('disabled', not Value)
end;

procedure TJQXDateTimeInput.SetWidth(AValue: Integer);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('width', IntToStr(AValue) + 'px');
end;

procedure TJQXDateTimeInput.SetHeight(AValue: Integer);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDateTimeInput(JQuery(GetJQID)).jqxDateTimeInput('height', IntToStr(AValue) + 'px');
end;


{ TJQXMenu }

procedure TJQXMenu.CreateInitialize;
begin
  inherited;
  FIsLoaded := False;
  Mode := mmHorizontal;
  Height := 20;
  Width := 220;

  RequiredScripts.Add('jqxmenu.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXMenu.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TJQXMenu.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      UpdateElement;
  end;
end;

procedure TJQXMenu.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
//    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu(string(TJSJSON.parse('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}')));
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('width', IntToStr(Width) + 'px');
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('height', IntToStr(Height) + 'px');
    TJQXWidgetMenu(JQuery(GetJQID)).on('itemclick', @HandleItemClick);
    UpdateElement;
  end;
end;

function TJQXMenu.GetMenuSource(Item: TMenuItem): string;
var
  I: Integer;
  it: TMenuItem;
begin
  result := '';

  if Assigned(Item.Items) then
  begin
    for I := 0 to Item.Items.Count - 1 do
    begin
      it := TMenuItem(Item.Items[I]);
      if I > 0 then
        result := result + ', ';

      result := result + '{ "label": "' + it.Caption + '", "id": "' + it.Name + '", "items": ' + GetMenuSource(it) + ' }';
    end;
  end;

  result := '[' + result + ']';
end;

function TJQXMenu.GetItemByName(AMenu: TMenuItem; Name: string): TMenuItem;
var
  I: Integer;
  it: TMenuItem;
begin
  Result := nil;

  if Assigned(AMenu.Items) then
  begin
    for I := 0 to AMenu.Items.Count - 1 do
    begin
      it := TMenuItem(AMenu.Items.Items[I]);

      if it.Name = Name then
      begin
        Result := it;
        if Assigned(it.OnClick) then
          Result.OnClick := it.OnClick;
        Exit;
      end;

      if not Assigned(Result) then
        Result := GetItemByName(it, Name);
    end;
  end;
end;

procedure TJQXMenu.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) and Assigned(Menu) then
  begin
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu(TJSJSON.parseObject('{"source": ' + GetMenuSource(Menu.Items) + ', "width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));

    case Mode of
      mmHorizontal: TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('mode', 'horizontal');
      mmVertical: TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('mode', 'vertical');
      mmPopup: TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('mode', 'popup');
    end;

    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('theme', GetTheme(Theme));
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('disabled', not Enabled);
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('width', IntToStr(Width) + 'px');
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('height', IntToStr(Height) + 'px');
  end;
end;

function TJQXMenu.HandleItemClick(Event: TJQXElementEvent): Boolean;
var
  Args: TJQXMenuEventArgs;
  h: TJSHTMLElement;
  it: TMenuItem;
begin
    Args := TJQXMenuEventArgs.Create;
    h := TJSHTMLElement(Event.Args);
    it := GetItemByName(Menu.Items, h.id);
    if Assigned(it) then
    begin
      Args.Source := it;

      if Assigned(it.OnClick) then
        it.OnClick;

      if Assigned(OnItemClick) then
        OnItemClick(Self, Args);

      if Assigned(Menu.OnChange) then
        Menu.OnChange(Self, it, False);
    end;
    Args.Free;

    Result := True;
end;

procedure TJQXMenu.SetMode(AValue: TJQXMenuMode);
begin
  FMode := AValue;
  UpdateElement;
end;

procedure TJQXMenu.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('theme', GetTheme(AValue))
end;

procedure TJQXMenu.SetMenu(AValue: TMainMenu);
begin
  if Assigned(AValue) then
  begin
    FMenu := AValue;
    FMenu.Visible := False;
    UpdateElement;
  end;
end;

procedure TJQXMenu.SetEnabled(Value: Boolean );
begin
  inherited;
  UpdateElement;
end;

procedure TJQXMenu.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXMenu.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXMenu.ToggleItem(AMenuItem: TMenuItem; Disabled: Boolean);
begin
  if not FIsLoaded then
    Exit;

  if not Assigned(AMenuItem) then
    Exit;

  if AMenuItem.Name = '' then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetMenu(JQuery(GetJQID)).jqxMenu('disable', AMenuItem.Name, Disabled);
end;

procedure TJQXMenu.SetItemDisabled(AMenuItem: TMenuItem);
begin
  ToggleItem(AMenuItem, True);
end;

procedure TJQXMenu.SetItemEnabled(AMenuItem: TMenuItem);
begin
  ToggleItem(AMenuItem, False);
end;

{ TJQXMaskedInput }

procedure TJQXMaskedInput.CreateInitialize;
begin
  inherited;
  FMask := '(###)###-####';
  Height := 21;
  Width := 120;
  FIsLoaded := False;

  RequiredScripts.Add('jqxmaskedinput.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXMaskedInput.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + ', "mask": "' + mask + '"}'));
    TJQXWidgetMaskedInput(JQuery(GetJQID)).on('valueChanged', @HandleMaskedInputChange);
    UpdateElement;
  end;
end;

procedure TJQXMaskedInput.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if not FIsLoaded then
    begin
      if Assigned(Container) then
      begin

        if Assigned(document.getElementById(GetJQID)) then
        begin
          TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + ', "mask": "' + mask + '"}'));
          TJQXWidgetMaskedInput(JQuery(GetJQID)).on('valueChanged', @HandleMaskedInputChange);
          UpdateElement;
        end;
      end;
    end;
  end;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;

    if FIsLoaded then
    begin
      TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + ', "mask": "' + mask + '"}'));
      TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('disabled', not Enabled);
      TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('value', Text);
      TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('theme', GetTheme(theme))
//      TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('readOnly', True);
    end;
  end;
end;

function TJQXMaskedInput.HandleMaskedInputChange(Event: TJQXElementEvent): Boolean ;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

function TJQXMaskedInput.GetText: string;
begin
  inherited;

  if not FIsLoaded then
    Exit;

  Result := TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('value');
  if not Assigned(Result) then
    Result := FText;
end;

procedure TJQXMaskedInput.SetMask(AValue: string);
begin
  inherited;
  FMask := AValue;
  UpdateElement;
end;

procedure TJQXMaskedInput.SetText(AValue: string);
begin
  inherited;
  FText := AValue;

  if not FIsLoaded then
    Exit;

  TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('value', AValue);
  UpdateElement;
end;

procedure TJQXMaskedInput.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetMaskedInput(JQuery(GetJQID)).jqxMaskedInput('theme', GetTheme(AValue))
end;

procedure TJQXMaskedInput.SetEnabled(Value: Boolean );
begin
  inherited;
  UpdateElement;
end;

procedure TJQXMaskedInput.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXMaskedInput.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

{ TJQXColorPicker }

procedure TJQXColorPicker.CreateInitialize;
begin
  inherited;
  Color := clRed;
  ColorMode := cmSaturation;
  Enabled := True;
  Height := 200;
  Width := 200;
  FIsLoaded := False;

  RequiredScripts.Add('jqxcolorpicker.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXColorPicker.BindEvents;
begin
  //disabled
end;

procedure TJQXColorPicker.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TJQXColorPicker.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      UpdateElement;
  end;
end;

procedure TJQXColorPicker.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetColorPicker(JQuery(GetJQID)).on('colorchange', @HandleChange);
    UpdateElement;
  end;
end;

procedure TJQXColorPicker.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
  if FIsLoaded then
  begin
    TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('setColor', '#' + ColorToHex(FColor));
    if ColorMode = cmHue then
      TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('colorMode', 'hue')
    else
      TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('colorMode', 'saturation');
    TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('disabled', not Enabled);
    TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('width', IntToStr(Width) + 'px');
    TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('height', IntToStr(Height) + 'px');
  end;
  end;
end;

function TJQXColorPicker.HandleChange(Event: TJQXEvent): Boolean ;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

function TJQXColorPicker.GetColor: TColor;
var
  o: TJSObject;
begin
  Result := FColor;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      o := TJSObject(TJQXWidgetColorPicker(JQuery(GetJQID)).jqxColorPicker('getColor'));
      if Assigned(0) then
        Result := HexToColor(string(o.Properties['hex']));
    end;
  end;
end;

procedure TJQXColorPicker.SetColorPickerColor(AValue: TColor);
begin
  FColor := AValue;
  UpdateElement;
end;

procedure TJQXColorPicker.SetColorMode(AValue: TJQXColorMode);
begin
  FColorMode := AValue;
  UpdateElement;
end;

procedure TJQXColorPicker.SetEnabled(Value: Boolean );
begin
  inherited;
  UpdateElement;
end;

procedure TJQXColorPicker.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXColorPicker.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;


{ TJQXComboBox }

procedure TJQXComboBox.CreateInitialize;
begin
  inherited;
  FAutoComplete := false;
  FItemIndex := -1;
  FItems := TStringList.Create;
  FItems.OnChange := HandleItemsChanged;
  FDisabled := TList.Create;
  FSelected := TList.Create;
  FMultiSelect := False;
  Enabled := True;
  Height := 21;
  Width := 200;
  FIsLoaded := False;

  RequiredScripts.Add('jqxbuttons.js');
  RequiredScripts.Add('jqxscrollbar.js');
  RequiredScripts.Add('jqxlistbox.js');
  RequiredScripts.Add('jqxcombobox.js');

  ScriptLoaded := not AddRequiredScripts;
end;

destructor TJQXComboBox.Destroy;
begin
  FItems.Free;
  FDisabled.Free;
  FSelected.Free;
  inherited;
end;

procedure TJQXComboBox.HandleItemsChanged(Sender: TObject);
begin
  UpdateElement;
end;

function TJQXComboBox.ItemsToJSON: string;
var
  I: Integer;
begin
  for I := 0 to FItems.Count - 1 do
  begin
    if I > 0 then
      Result := Result + ', ';
    Result := Result + '"' + FItems[I] + '"';
  end;

  Result := '[' + Result + ']';
end;

procedure TJQXComboBox.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetComboBox(JQuery(GetJQID)).on('change', @HandleChange);
    TJQXWidgetComboBox(JQuery(GetJQID + ' input')).on('blur', @HandleExit);
    UpdateElement;
  end;
end;

procedure TJQXComboBox.UpdateElement;
var
  I: Integer;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox(TJSJSON.parseObject('{"source": ' + ItemsToJSON + '}'));
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('disabled', not Enabled);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('selectedIndex', FItemIndex);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('width', IntToStr(Width) + 'px');
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('height', IntToStr(Height) + 'px');
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('enableBrowserBoundsDetection', True);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('checkboxes', FMultiSelect);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('autoComplete', FAutoComplete);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('placeHolder', FTextHint);
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('theme', GetTheme(theme));
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('val', FText);

      for I := 0 to Items.Count - 1 do
      begin
        if Disabled[I] then
          TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('disableAt', I)
        else
          TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('enableAt', I);

        if FSelected.Count > I then
        begin
          if FSelected.Items[I] then
            TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('checkIndex', I)
          else
            TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('uncheckIndex', I);
        end;
      end;
    end;
  end;
end;

function TJQXComboBox.HandleChange(Event: TJQXEvent): Boolean ;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

function TJQXComboBox.HandleExit(Event: TJQXEvent): Boolean;
begin
  if Assigned(OnExit) then
    OnExit(Self);
  Result := True;
end;

function TJQXComboBox.GetItemIndex: Integer;
begin
  Result := FItemIndex;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    Result := StrToInt(TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('selectedIndex').ToString);
end;

function TJQXComboBox.GetDisabled(AIndex: Integer): Boolean ;
begin
  if (AIndex < FDisabled.Count) then
    Result := Boolean(FDisabled.Items[AIndex])
  else
    Result := False;
end;

procedure TJQXComboBox.SetDisabled(AIndex: Integer; AValue: Boolean);
begin
  while (AIndex >= FDisabled.Count) do
    FDisabled.Add(false);

  FDisabled.Items[AIndex] := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if AValue then
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('disableAt', AIndex)
    else
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('enableAt', AIndex);
  end;
end;

function TJQXComboBox.GetSelected(AIndex: Integer): Boolean ;
var
  items: TJSArray;
  o: TJSObject;
  I: integer;
begin
  if (AIndex < FSelected.Count) then
    Result := Boolean(FSelected.Items[AIndex])
  else
    Result := False;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    Result := False;

    items := TJSArray(TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('getCheckedItems'));

    for I := 0 to items.Length - 1 do
    begin
      o := TJSObject(items[I]);
      if o.Properties['index'] = AIndex then
        Result := True;
    end;
  end;
end;

function TJQXComboBox.GetText: string;
begin
  inherited;

  if not FIsLoaded then
    Exit;

  Result := TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('val').toString;
  if not Assigned(Result) then
    Result := FText;
end;

procedure TJQXComboBox.SetSelected(AIndex: Integer; AValue: Boolean);
begin
  while (AIndex >= FSelected.Count) do
    FSelected.Add(false);

  FSelected.Items[AIndex] := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if AValue then
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('checkIndex', AIndex)
    else
      TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('uncheckIndex', AIndex);
  end;
end;

procedure TJQXComboBox.SetItemIndex(AIndex: Integer);
begin
  FItemIndex := AIndex;
  UpdateElement;
end;

procedure TJQXComboBox.SetItems(AItems: TStringList);
begin
  FItems.Assign(AItems);
  UpdateElement;
end;

procedure TJQXComboBox.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXComboBox.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXComboBox.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXComboBox.SetMultiSelect(AValue: Boolean);
begin
  FMultiSelect := AValue;
  UpdateElement;
end;

procedure TJQXComboBox.SetAutoComplete(AValue: Boolean);
begin
  FAutoComplete := AValue;
  UpdateElement;
end;

procedure TJQXComboBox.SetText(const Value: string);
begin
  FText := Value;
  ItemIndex := -1;
  UpdateElement;
end;

procedure TJQXComboBox.SetTextHint(AValue: string);
begin
  FTextHint := AValue;
  UpdateElement;
end;

procedure TJQXComboBox.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetComboBox(JQuery(GetJQID)).jqxComboBox('theme', GetTheme(AValue))
end;

{ TJQXDropDownList }

procedure TJQXDropDownList.CreateInitialize;
begin
  inherited;
  FItemIndex := -1;
  FItems := TStringList.Create;
  FItems.OnChange := HandleItemsChanged;
  FDisabled := TList.Create;
  FSelected := TList.Create;
  FMultiSelect := False;
  FTextHint := '';
  Enabled := True;
  Height := 21;
  Width := 200;
  FIsLoaded := False;

  RequiredScripts.Add('jqxbuttons.js');
  RequiredScripts.Add('jqxscrollbar.js');
  RequiredScripts.Add('jqxlistbox.js');
  RequiredScripts.Add('jqxcombobox.js');
  RequiredScripts.Add('jqxdropdownlist.js');

  ScriptLoaded := not AddRequiredScripts;
end;

destructor TJQXDropDownList.Destroy;
begin
  FItems.Free;
  FDisabled.Free;
  FSelected.Free;
  inherited;
end;

function TJQXDropDownList.ItemsToJSON: string;
var
  I: Integer;
begin
  for I := 0 to FItems.Count - 1 do
  begin
    if I > 0 then
      Result := Result + ', ';
    Result := Result + '"' + FItems[I] + '"';
  end;

  Result := '[' + Result + ']';
end;

procedure TJQXDropDownList.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetDropDownList(JQuery(GetJQID)).on('change', @HandleChange);
    UpdateElement;
  end;
end;

procedure TJQXDropDownList.UpdateElement;
var
  I: Integer;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList(TJSJSON.parseObject('{"source": ' + ItemsToJSON + '}'));
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('disabled', not Enabled);
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('selectedIndex', FItemIndex);
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('width', IntToStr(Width) + 'px');
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('height', IntToStr(Height) + 'px');
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('enableBrowserBoundsDetection', True);
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('checkboxes', FMultiSelect);
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('placeHolder', FTextHint);
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('theme', GetTheme(Theme));

      for I := 0 to Items.Count - 1 do
      begin
        if Disabled[I] then
          TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('disableAt', I)
        else
          TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('enableAt', I);

        if FSelected.Count > I then
        begin
          if FSelected.Items[I] then
            TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('checkIndex', I)
          else
            TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('uncheckIndex', I);
        end;
      end;
    end;
  end;
end;

function TJQXDropDownList.HandleChange(Event: TJQXEvent): Boolean ;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

procedure TJQXDropDownList.HandleItemsChanged(Sender: TObject);
begin
  UpdateElement;
end;

function TJQXDropDownList.GetItemIndex: Integer;
begin
  Result := FItemIndex;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    Result := StrToInt(TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('selectedIndex').ToString);
end;

function TJQXDropDownList.GetDisabled(AIndex: Integer): Boolean ;
begin
  if (AIndex < FDisabled.Count) then
    Result := Boolean(FDisabled.Items[AIndex])
  else
    Result := False;
end;

procedure TJQXDropDownList.SetDisabled(AIndex: Integer; AValue: Boolean);
begin
  while (AIndex >= FDisabled.Count) do
    FDisabled.Add(false);

  FDisabled.Items[AIndex] := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if AValue then
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('disableAt', AIndex)
    else
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('enableAt', AIndex);
  end;
end;

function TJQXDropDownList.GetSelected(AIndex: Integer): Boolean ;
var
  items: TJSArray;
  o: TJSObject;
  I: integer;
begin
  if (AIndex < FSelected.Count) then
    Result := Boolean(FSelected.Items[AIndex])
  else
    Result := False;

  if Assigned(ElementHandle) then
  begin
    Result := False;

  if not FIsLoaded then
    Exit;

    items := TJSArray(TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('getCheckedItems'));

    for I := 0 to items.Length - 1 do
    begin
      o := TJSObject(items[I]);
      if o.Properties['index'] = AIndex then
        Result := True;
    end;
  end;
end;

procedure TJQXDropDownList.SetSelected(AIndex: Integer; AValue: Boolean);
begin
  while (AIndex >= FSelected.Count) do
    FSelected.Add(false);

  FSelected.Items[AIndex] := AValue;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if AValue then
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('checkIndex', AIndex)
    else
      TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('uncheckIndex', AIndex);
  end;
end;

procedure TJQXDropDownList.SetItemIndex(AIndex: Integer);
begin
  FItemIndex := AIndex;
  UpdateElement;
end;

procedure TJQXDropDownList.SetItems(AItems: TStringList);
begin
  FItems.Assign(AItems);
  UpdateElement;
end;

procedure TJQXDropDownList.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXDropDownList.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXDropDownList.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXDropDownList.SetMultiSelect(AValue: Boolean);
begin
  FMultiSelect := AValue;
  UpdateElement;
end;

procedure TJQXDropDownList.SetTextHint(AValue: string);
begin
  FTextHint := AValue;
  UpdateElement;
end;

procedure TJQXDropDownList.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetDropDownList(JQuery(GetJQID)).jqxDropDownList('theme', GetTheme(AValue))
end;

{ TJQXNumberInput }

procedure TJQXNumberInput.CreateInitialize;
begin
  inherited;
  FDigits := 3;
  FDecimalDigits := 0;
  FInputMode := imAdvanced;
  FMaxValue := 100;
  FMinValue := 0;
  FShowSpinButtons := True;
  FSpinButtonsStep := 1;
  FSymbol := '';
  FSymbolPosition := spLeft;
  FValue := 0;
  Height := 21;
  Width := 120;
  FIsLoaded := False;

  RequiredScripts.Add('jqxnumberinput.js');
  RequiredScripts.Add('jqxbuttons.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXNumberInput.BindEvents;
begin
  //disabled
end;

procedure TJQXNumberInput.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetNumberInput(JQuery(GetJQID)).on('valueChanged', @HandleNumberInputChange);
    UpdateElement;
  end;
end;

procedure TJQXNumberInput.UpdateElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('disabled', not Enabled);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('spinButtons', FShowSpinButtons);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('spinButtonsStep', FSpinButtonsStep);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('symbol', FSymbol);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('theme', GetTheme(Theme));

      if FSymbolPosition = spLeft then
        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('symbolPosition', 'left')
      else
        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('symbolPosition', 'right');

      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('setDecimal', FValue);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('digits', FDigits);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('decimalDigits', FDecimalDigits);

      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('max', FMaxValue);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('min', FMinValue);
//        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('max', 99999999);
//        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('min', -99999999);

      if FInputMode = imAdvanced then
        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('inputMode', 'advanced')
      else
        TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('inputMode', 'simple');

      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('decimalSeparator', TFormatSettings.Invariant.DecimalSeparator);
      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('groupSeparator', TFormatSettings.Invariant.ThousandSeparator);

//      TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('readOnly', True);
    end;
  end;
end;

function TJQXNumberInput.HandleNumberInputChange(Event: TJQXElementEvent): Boolean;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  Result := True;
end;

{$HINTS OFF}
function TJQXNumberInput.GetValue: Double;
var
  id: string;
  dbl: double;
begin
  Result := FValue;

  if not FIsLoaded then
    Exit;

  id := GetJQID;

  asm
    dbl = $(id).jqxNumberInput('getDecimal');
  end;

  Result := dbl;
end;
{$HINTS ON}

procedure TJQXNumberInput.SetDigits(AValue: Integer);
begin
  if AValue > 0 then
    FDigits := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetDecimalDigits(AValue: Integer);
begin
  if AValue >= 0 then
    FDecimalDigits := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetInputMode(AValue: TJQXInputMode);
begin
  FInputMode := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetMaxValue(AValue: Double);
begin
  if AValue <= 99999999 then
    FMaxValue := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetMinValue(AValue: Double);
begin
  if AValue >= -99999999 then
    FMinValue := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetShowSpinButtons(AValue: Boolean);
begin
  FShowSpinButtons := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetSpinButtonsStep(AValue: Integer);
begin
  FSpinButtonsStep := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetSymbol(AValue: string);
begin
  FSymbol := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetSymbolPosition(AValue: TJQXSymbolPosition);
begin
  FSymbolPosition := AValue;
  UpdateElement;
end;

procedure TJQXNumberInput.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('theme', GetTheme(AValue))
end;

procedure TJQXNumberInput.SetValue(AValue: Double);
begin
  FValue := AValue;

  if not FIsLoaded then
    Exit;

  TJQXWidgetNumberInput(JQuery(GetJQID)).jqxNumberInput('setDecimal', AValue);
  UpdateElement;
end;

procedure TJQXNumberInput.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXNumberInput.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXNumberInput.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

{ TJQXRating }

procedure TJQXRating.CreateInitialize;
begin
  inherited;
  FItemCount := 5;
  //FPrecision := TJQXRatingPrecision;
  FValue := 0;
  Height := 20;
  Width := 100;
  FIsLoaded := False;

  RequiredScripts.Add('jqxrating.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXRating.BindEvents;
begin
  //disabled
end;

procedure TJQXRating.InitJQuery;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;

      TJQXWidgetRating(JQuery(GetJQID)).jqxRating(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
//    TJQXWidgetRating(JQuery(GetJQID)).jqxRating(TJSJSON.parse('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + ', "precision": 0.5}'));
    TJQXWidgetRating(JQuery(GetJQID)).on('change', @HandleRatingChange);
    UpdateElement;
  end;
end;

procedure TJQXRating.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetRating(JQuery(GetJQID)).jqxRating(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
      TJQXWidgetRating(JQuery(GetJQID)).jqxRating('disabled', not Enabled);
      TJQXWidgetRating(JQuery(GetJQID)).jqxRating('setValue', FValue);
      TJQXWidgetRating(JQuery(GetJQID)).jqxRating('count', FItemCount);
      TJQXWidgetRating(JQuery(GetJQID)).jqxRating('itemHeight', Height);

      if FItemCount > 0 then
        TJQXWidgetRating(JQuery(GetJQID)).jqxRating('itemWidth', Width/FItemCount)
      else
        TJQXWidgetRating(JQuery(GetJQID)).jqxRating('itemWidth', Width);

//      if FPrecision = rpFull then
//        TJQXWidgetRating(JQuery(GetJQID)).jqxRating(TJSJSON.parse('{"precision": 1}'))
//       else
//        TJQXWidgetRating(JQuery(GetJQID)).jqxRating(TJSJSON.parse('{"precision": 0.5}'));
//
//      if FPrecision = rpFull then
//        OutputDebugString('precision: full')
//      else
//        OutputDebugString('precision: half');
//      OutputDebugString(TJQXWidgetRating(JQuery(GetJQID)).jqxRating('precision').ToString);
    end;
  end;
end;

function TJQXRating.HandleRatingChange(Event: TJQXElementEvent): Boolean;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  Result := True;
end;

function TJQXRating.GetValue: Double;
var
  s: string;
begin

  if not FIsLoaded then
    Exit;

  s := TJQXWidgetRating(JQuery(GetJQID)).jqxRating('getValue').ToString;
  if s <> '' then
    Result := StrToFloat(s, TFormatSettings.Invariant);

  if not Assigned(Result) then
    Result := FValue;
end;

procedure TJQXRating.SetValue(AValue: Double);
begin
  FValue := AValue;

  if not FIsLoaded then
    Exit;

  UpdateElement;
end;

procedure TJQXRating.SetItemCount(AValue: Integer);
begin
  if AValue > 0 then
    FItemCount := AValue;
  UpdateElement;
end;

//procedure TJQXRating.SetPrecision(AValue: TJQXRatingPrecision);
//begin
//  FPrecision := AValue;
//  UpdateElement;
//end;

procedure TJQXRating.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXRating.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXRating.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;


{ TJQXButton }

procedure TJQXButton.BindEvents;
begin
  //disabled
end;

procedure TJQXButton.CreateInitialize;
begin
  inherited;
  FCaption := Name;
  FCaptionPosition := xpLeft;
  FCaptionImageRelation := ipAfterImage;
  FImageURL := '';
  FImagePosition := xpLeft;
  FImageHeight := 16;
  FImageWidth := 16;
  FRoundedBorders := True;
  FTemplate := ttDefault;
  Width := 96;
  Height := 25;
  ElementPosition := epAbsolute;

  RequiredScripts.Add('jqxbuttons.js');

  ScriptLoaded := not AddRequiredScripts;
end;

function TJQXButton.HandleClick(Event: TJQXElementEvent): boolean;
begin
  Result := False;

  if not Enabled then
    Exit;

  if Assigned(OnClick) then
    OnClick(Self);

  Result := True;
end;

procedure TJQXButton.InitJQuery;
var
  elbutton: TJSElement;
  elid: string;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    elbutton := document.createElement('INPUT');
    elbutton['id'] := GetID + 'Button';
    ElementHandle.appendChild(elbutton);

    elid := GetJQID + 'Button';

    FIsLoaded := True;
    TJQXWidgetButton(JQuery(elid)).jqxButton(TJSJSON.parseObject('{"value": "' + FCaption + '", "width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetButton(JQuery(elid)).on('click', @HandleClick);
    UpdateElement;
  end;
end;

procedure TJQXButton.SetCaption(const Value: string);
begin
  FCaption := Value;
  UpdateElement;
end;

procedure TJQXButton.SetCaptionImageRelation(const Value: TJQXImagePosition);
begin
  FCaptionImageRelation := Value;
  UpdateElement;
end;

procedure TJQXButton.SetCaptionPosition(const Value: TJQXPosition);
begin
  FCaptionPosition := Value;
  UpdateElement;
end;

procedure TJQXButton.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButton.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButton.SetImagePosition(const Value: TJQXPosition);
begin
  FImagePosition := Value;
  UpdateElement;
end;

procedure TJQXButton.SetImageHeight(const Value: Integer);
begin
  FImageHeight := Value;
  UpdateElement;
end;

procedure TJQXButton.SetImageURL(const Value: string);
begin
  FImageURL := Value;
  UpdateElement;
end;

procedure TJQXButton.SetImageWidth(const Value: Integer);
begin
  FImageWidth := Value;
  UpdateElement;
end;

procedure TJQXButton.SetRoundedBorders(const Value: Boolean);
begin
  FRoundedBorders := Value;
  UpdateElement;
end;

procedure TJQXButton.SetTemplate(const Value: TJQXTemplate);
begin
  FTemplate := Value;
  UpdateElement;
end;

procedure TJQXButton.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetButton(JQuery(GetJQID + 'Button')).jqxButton('theme', GetTheme(AValue))
end;

procedure TJQXButton.SetTop(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButton.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButton.UpdateElement;
var
  sborders: string;
  stpos: string;
  sipos: string;
  sirel: string;
  stemplate: string;
  elid: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin

      elid := GetJQID + 'Button';

      TJQXWidgetButton(JQuery(elid)).jqxButton(TJSJSON.parseObject('{"value": "' + FCaption + '", "width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
      TJQXWidgetButton(JQuery(elid)).jqxButton('disabled', not Enabled);
      TJQXWidgetButton(JQuery(elid)).jqxButton('value', FCaption);
      TJQXWidgetButton(JQuery(elid)).jqxButton('theme', GetTheme(Theme));

      case CaptionPosition of
        xpLeft: stpos := 'left';
        xpTop: stpos := 'top';
        xpCenter: stpos := 'center';
        xpBottom: stpos := 'bottom';
        xpRight: stpos := 'right';
        xpTopLeft: stpos := 'topLeft';
        xpBottomLeft: stpos := 'bottomLeft';
        xpTopRight: stpos := 'topRight';
        xpBottomRight: stpos := 'bottomRight';
      end;

      TJQXWidgetButton(JQuery(elid)).jqxButton('textPosition', stpos);

      case ImagePosition of
        xpLeft: sipos := 'left';
        xpTop: sipos := 'top';
        xpCenter: sipos := 'center';
        xpBottom: sipos := 'bottom';
        xpRight: sipos := 'right';
        xpTopLeft: sipos := 'topLeft';
        xpBottomLeft: sipos := 'bottomLeft';
        xpTopRight: sipos := 'topRight';
        xpBottomRight: sipos := 'bottomRight';
      end;

      TJQXWidgetButton(JQuery(elid)).jqxButton('imgPosition', sipos);
      TJQXWidgetButton(JQuery(elid)).jqxButton('imgWidth', FImageWidth);
      TJQXWidgetButton(JQuery(elid)).jqxButton('imgHeight', FImageHeight);
      TJQXWidgetButton(JQuery(elid)).jqxButton('imgSrc', FImageURL);

      case CaptionImageRelation of
        ipAfterImage: sirel := 'imageBeforeText';
        ipAboveImage: sirel := 'textAboveImage';
        ipBelowImage: sirel := 'imageAboveText';
        ipBeforeImage: sirel := 'textBeforeImage';
        ipOverlay: sirel := 'overlay';
      end;
      TJQXWidgetButton(JQuery(elid)).jqxButton('textImageRelation', sirel);

      case Template of
        ttDefault: stemplate := 'default';
        ttPrimary: stemplate := 'primary';
        ttSuccess: stemplate := 'success';
        ttWarning: stemplate := 'warning';
        ttDanger: stemplate := 'danger';
        ttInfo: stemplate := 'info';
      end;
      TJQXWidgetButton(JQuery(elid)).jqxButton('template', stemplate);

      sborders := '';
      if FRoundedBorders then
        sborders := 'all';
      TJQXWidgetButton(JQuery(elid)).jqxButton('roundedCorners', sborders);
    end;
  end;
end;

{ TJQXButtonGroup }

procedure TJQXButtonGroup.BindEvents;
begin
 //disabled
end;

procedure TJQXButtonGroup.CreateInitialize;
begin
  inherited;
  FSelectedButton := TList.Create;
  FEnableHover := True;
  FItems := TStringList.Create;
  FItems.OnChange := DoItemsChanged;
  FMode := gmDefault;
  FTemplate := ttDefault;
  Width := 250;
  Height := 25;

  FItems.Add('Button 1');

  RequiredScripts.Add('jqxbuttongroup.js');
  RequiredScripts.Add('jqxradiobutton.js');

  ScriptLoaded := not AddRequiredScripts;
end;

destructor TJQXButtonGroup.Destroy;
begin
  FSelectedButton.Free;
  FItems.Free;
  inherited;
end;

procedure TJQXButtonGroup.DoItemsChanged(Sender: TObject);
begin
  UpdateElement;
end;

{$HINTS OFF}
procedure TJQXButtonGroup.FixMargin;
var
  jqid: string;
begin
  jqid := GetJQID;
  asm
    $(jqid).children().first().css({
      marginLeft: '0px'
    });
  end;
end;
{$HINTS ON}

function TJQXButtonGroup.GetButtonSelect(Button: Integer): Boolean;
var
  a: TJSArray;
  o: TJSObject;
  I: Integer;
  v: String;
begin
  if (Button < FSelectedButton.Count) then
    Result := Boolean(FSelectedButton.Items[Button])
  else
    Result := False;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin

    if Mode = gmRadioButton then
    begin
      o := TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('getSelection');
      if Assigned(o) then
      begin
        I := StrToInt((o).ToString);
        Result := I = Button;
      end;
    end
    else if Mode = gmCheckBox then
    begin
      a := TJSArray(TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('getSelection'));

      for I := 0 to a.Length - 1 do
      begin
        v := string(a[I]);
        if (v <> '') then
        begin
          Result := StrToInt(v) = Button;
          if Result then
            Exit;
        end;
      end;
    end;
  end;
end;

function TJQXButtonGroup.HandleClick(Event: TJQXEvent): boolean;
var
  Args: TJQXButtonEventArgs;
begin
  Result := False;

  if not Enabled then
    Exit;

  if Assigned(OnClick) then
  begin
    Args := TJQXButtonEventArgs.Create;
    Args.ButtonIndex := StrToInt(string(Event.Args.Properties['index']));
    OnClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TJQXButtonGroup.InitJQuery;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetButtonGroup(JQuery(GetJQID)).on('buttonclick', @HandleClick);
    UpdateElement;
  end;
end;

procedure TJQXButtonGroup.SetButtonSelect(Button: Integer;
  const Value: Boolean);
begin
  while (Button >= FSelectedButton.Count) do
    FSelectedButton.Add(false);

  if (ButtonSelect[Button] = True) and (Value) then
    Exit;

  FSelectedButton.Items[Button] := Value;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('setSelection', Button)
end;

procedure TJQXButtonGroup.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButtonGroup.SetEnableHover(const Value: Boolean);
begin
  FEnableHover := Value;
  UpdateElement;
end;

procedure TJQXButtonGroup.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButtonGroup.SetItems(const AItems: TStringList);
begin
  FItems.Assign(AItems);
  UpdateElement;
end;

procedure TJQXButtonGroup.SetMode(const Value: TJQXGroupMode);
begin
  FMode := Value;
  UpdateElement;
end;

procedure TJQXButtonGroup.SetTemplate(const Value: TJQXTemplate);
begin
  FTemplate := Value;
  UpdateElement;
end;

procedure TJQXButtonGroup.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('theme', GetTheme(AValue));
    FixMargin;
  end;
end;

procedure TJQXButtonGroup.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXButtonGroup.UpdateElement;
var
  smode: string;
  stemplate: string;
  I: integer;
  innerHTML: string;
begin
  inherited;

  if not Assigned(Container) then
    Exit;

  innerHTML := '';

  if FItems.Count > 0 then
  begin
    for I := 0 to FItems.Count - 1 do
    begin
      if FItems[I] <> '' then
        innerHTML := innerHTML + '<div style="height:' + IntToStr(Height) + 'px">' + FItems[I] + '</div>'
      else
        innerHTML := innerHTML + '<div style="height:' + IntToStr(Height) + 'px">&nbsp;</div>';
    end;
  end;
  Container.innerHTML := innerHTML;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));

      case FMode of
        gmDefault: smode := 'default';
        gmCheckBox: smode := 'checkbox';
        gmRadioButton: smode := 'radio';
      end;
      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('mode', smode);

      case Template of
        ttDefault: stemplate := 'default';
        ttPrimary: stemplate := 'primary';
        ttSuccess: stemplate := 'success';
        ttWarning: stemplate := 'warning';
        ttDanger: stemplate := 'danger';
        ttInfo: stemplate := 'info';
      end;
      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('template', stemplate);

      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('enableHover', FEnableHover);
      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('disabled', not Enabled);
      TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('theme', GetTheme(Theme));

      if Mode <> gmDefault then
      begin
        for I := 0 to Items.Count - 1 do
          TJQXWidgetButtonGroup(JQuery(GetJQID)).jqxButtonGroup('setSelection', ButtonSelect[I])
      end;
    end;

    FixMargin;
  end;
end;

{ TJQXKnob }

procedure TJQXKnob.CreateInitialize;
begin
  inherited;
  FAppearance := TJQXKnobAppearance.Create(Self);
  FValue := 0;
  FStartAngle := 120;
  FEndAngle := 420;
  FMaximum := 100;
  FMinimum := 0;
  FStep := 1;
  Width := 200;
  Height := 200;
  FIsLoaded := False;

  RequiredScripts.Add('jqxdraw.js');
  RequiredScripts.Add('jqxknob.js');

  ScriptLoaded := not AddRequiredScripts;
end;

destructor TJQXKnob.Destroy;
begin
  FAppearance.Free;
  inherited;
end;

procedure TJQXKnob.BindEvents;
begin
  //disabled
end;

procedure TJQXKnob.InitJQuery;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
//    TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"startAngle": ' + IntToStr(FStartAngle) + ', "endAngle": ' + IntToStr(FEndAngle) + ', "width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetKnob(JQuery(GetJQID)).on('change', @HandleKnobChange);
    UpdateElement;
  end;
end;

procedure TJQXKnob.UpdateElement;
var
  sline: string;
  spointer: string;
  sbgcolor: string;
  sbcolor: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"labels": {"offset": "'
        + IntToStr(Appearance.Labels.Offset) + '%", "step": ' + IntToStr(Appearance.Labels.Step) + ', "visible": '
        + LowerCase(BoolToStr(Appearance.Labels.Visible, True)) + '}}'));

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"progressBar": {"style": { "fill": "'
        + ColorToHTML(Appearance.ProgressBar.Color) + '"}, "size": "'
        + IntToStr(Appearance.ProgressBar.Size) + '%", "offset": "'
        + IntToStr(Appearance.ProgressBar.Offset) + '%", "background": {"fill": "'
        + ColorToHTML(Appearance.Progressbar.BackgroundColor) + '", "stroke": "'
        + ColorToHTML(Appearance.ProgressBar.BorderColor) + '"}}}'));

      sline := 'line';
      if Appearance.Marks.MarkType = mtCircle then
        sline := 'circle';

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"marks": {"colorRemaining": {"color": "'
        + ColorToHTML(Appearance.Marks.ColorRemaining) + '", "border": "'
        + ColorToHTML(Appearance.Marks.BorderColorRemaining) + '"}, "colorProgress": {"color": "'
        + ColorToHTML(Appearance.Marks.ColorProgress) + '", "border": "'
        + ColorToHTML(Appearance.Marks.BorderColorProgress) + '"}, "type": "'
        + sline + '", "offset": "' + IntToStr(Appearance.Marks.Offset) + '%", "thickness": '
        + IntToStr(Appearance.Marks.Width) + ', "size": "' + IntToStr(Appearance.Marks.Size)
        + '%", "majorSize": "' + IntToStr(Appearance.Marks.MajorSize) + '%", "majorInterval": '
        + IntToStr(Appearance.Marks.MajorInterval) + ', "minorInterval": ' + IntToStr(Appearance.Marks.MinorInterval) + '}}'));

//      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"dial": {"innerRadius": "40%", "outerRadius": "50%", "style": { "stroke": "#ff0000", "strokeWidth": 1, "fill": { "color": "#66707e", "gradientType": "linear", "gradientStops": [[0, 1], [50, 0.4], [100, 1]] }}}}'));

      case Appearance.Pointer.PointerType of
        ptArrow: spointer := 'arrow';
        ptCircle: spointer := 'circle';
        ptLine: spointer := 'line';
      end;

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"pointer": {"type": "'
        + spointer + '", "style": { "fill": "'
        + ColorToHTML(Appearance.Pointer.Color) + '", "stroke": "'
        + ColorToHTML(Appearance.Pointer.BorderColor) + '" }, "size": "'
        + IntToStr(Appearance.Pointer.Size) + '%", "offset": "'
        + IntToStr(Appearance.Pointer.Offset) + '%", "thickness": ' + IntToStr(Appearance.Pointer.Width) + ' }}'));

      sbgcolor := 'transparent';
      if Appearance.Color <> clNone then
        sbgcolor := ColorToHTML(Appearance.Color);

      sbcolor := 'transparent';
      if Appearance.BorderColor <> clNone then
        sbcolor := ColorToHTML(Appearance.BorderColor);

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob(TJSJSON.parseObject('{"style": {"fill": "'
        + sbgcolor + '", "stroke": "' + sbcolor + '", "strokeWidth": ' + IntToStr(Appearance.BorderWidth) + '}}'));

      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('disabled', not Enabled);
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('step', FStep);
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('max', FMaximum);
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('min', FMinimum);
      TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('value', FValue);
    end;
  end;
end;

function TJQXKnob.HandleKnobChange(Event: TJQXElementEvent): Boolean;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  Result := True;
end;

function TJQXKnob.GetValue: Double;
var
  s: string;
  o: TJSObject;
begin
  if not FIsLoaded then
    Exit;

  o := TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('value');
  s := o.ToString;
  Result := StrToFloat(s, TFormatSettings.Invariant);
  if not Assigned(Result) then
    Result := FValue;
end;

procedure TJQXKnob.SetValue(AValue: Double);
begin
  FValue := AValue;

  if not FIsLoaded then
    Exit;

  TJQXWidgetKnob(JQuery(GetJQID)).jqxKnob('val', AValue);
  UpdateElement;
end;

procedure TJQXKnob.SetAppearance(const Value: TJQXKnobAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TJQXKnob.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXKnob.SetEndAngle(const Value: Integer);
begin
  FEndAngle := Value;
  UpdateElement;
end;

procedure TJQXKnob.SetHeight(AValue: Integer);
begin
  inherited;
end;

procedure TJQXKnob.SetWidth(AValue: Integer);
begin
  inherited;
end;

procedure TJQXKnob.SetMaximum(const Value: Integer);
begin
  FMaximum := Value;
  UpdateElement;
end;

procedure TJQXKnob.SetMinimum(const Value: Integer);
begin
  FMinimum := Value;
  UpdateElement;
end;

procedure TJQXKnob.SetStartAngle(const Value: Integer);
begin
  FStartAngle := Value;
  UpdateElement;
end;

procedure TJQXKnob.SetStep(const Value: Integer);
begin
  FStep := Value;
  UpdateElement;
end;

{ TJQXProgressBar }

procedure TJQXProgressBar.CreateInitialize;
begin
  inherited;
  FAnimationDuration := 300;
  FColorRanges := TJQXProgressBarColors.Create(Self);
  FMaximum := 100;
  FMinimum := 0;
  FValue := 0;
  Width := 200;
  Height := 21;
  FShowValue := True;
  FOrientation := poHorizontal;
  FLayout := plNormal;
  FTemplate := ttDefault;
  FIsLoaded := False;

  RequiredScripts.Add('jqxprogressbar.js');

  ScriptLoaded := not AddRequiredScripts;
end;

destructor TJQXProgressBar.Destroy;
begin
  FColorRanges.Free;
  inherited;
end;

procedure TJQXProgressBar.InitJQuery;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    UpdateElement;
  end;
end;

procedure TJQXProgressBar.UpdateElement;
var
  sorientation: string;
  slayout: string;
  stemplate: string;
  id: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      id := GetJQID;

      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('animationDuration', FAnimationDuration);

      sorientation := 'horizontal';
      if Orientation = poVertical then
        sorientation := 'vertical';
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('orientation', sorientation);

      slayout := 'normal';
      if Layout = plReverse then
        slayout := 'reverse';
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('layout', slayout);

      case Template of
        ttDefault: stemplate := 'default';
        ttPrimary: stemplate := 'primary';
        ttSuccess: stemplate := 'success';
        ttWarning: stemplate := 'warning';
        ttDanger: stemplate := 'danger';
        ttInfo: stemplate := 'info';
      end;
      TJQXWidgetProgressBar(JQuery(id)).jqxProgressBar('template', stemplate);

      asm
        let template = $(id).jqxProgressBar('template');
        $(id).find('.jqx-fill-state-pressed').addClass('jqx-' + template);
      end;

      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('disabled', not Enabled);
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('max', FMaximum);
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('min', FMinimum);
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('value', FValue);
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar(New(['colorRanges', ColorsToArray]));
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('showText', ShowValue);
      TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('theme', GetTheme(Theme));
    end;
  end;
end;

function TJQXProgressBar.ColorsToArray: TJSArray;
var
  I: integer;
begin
  Result := TJSArray.New;

  for I := 0 to ColorRanges.Count - 1 do
    Result.Push(New(['stop', ColorRanges[I].Stop, 'color', ColorToHTML(ColorRanges[I].Color)]));
end;

procedure TJQXProgressBar.SetValue(AValue: Integer);
begin
  FValue := AValue;

  if not FIsLoaded then
    Exit;

  TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('val', AValue);
  UpdateElement;
end;

procedure TJQXProgressBar.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXProgressBar.SetAnimationDuration(const Value: Integer);
begin
  FAnimationDuration := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetColorRanges(const Value: TJQXProgressBarColors);
begin
  FColorRanges.Assign(Value);
  UpdateElement;
end;

procedure TJQXProgressBar.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXProgressBar.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXProgressBar.SetLayout(const Value: TJQXProgressLayout);
begin
  FLayout := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetMaximum(const Value: Integer);
begin
  FMaximum := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetMinimum(const Value: Integer);
begin
  FMinimum := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetOrientation(const Value: TJQXProgressOrientation);
begin
  FOrientation := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetShowValue(const Value: Boolean);
begin
  FShowValue := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetTemplate(const Value: TJQXTemplate);
begin
  FTemplate := Value;
  UpdateElement;
end;

procedure TJQXProgressBar.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetProgressBar(JQuery(GetJQID)).jqxProgressBar('theme', GetTheme(AValue));
end;

{ TJQXProgressBarColor }

procedure TJQXProgressBarColor.Assign(Source: TPersistent);
begin
  if (Source is TJQXProgressBarColor) then
  begin
    FColor := (Source as TJQXProgressBarColor).Color;
    FStop := (Source as TJQXProgressBarColor).Stop;
  end;
end;

constructor TJQXProgressBarColor.Create(Collection: TCollection);
begin
  inherited;
  FColor := clNone;
  FStop := 0;

  if Collection is TJQXProgressBarColors then
  begin
    FOwner := Collection as TJQXProgressBarColors;
  end;
end;

destructor TJQXProgressBarColor.Destroy;
begin
  inherited;
end;

function TJQXProgressBarColor.GetDisplayName: string;
begin
  Result := 'Color '+ IntToStr(Index);
end;

function TJQXProgressBarColor.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXProgressBarColor.SetColor(const Value: TColor);
begin
  FColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXProgressBarColor.SetStop(const Value: Integer);
begin
  FStop := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXProgressBarColors }

function TJQXProgressBarColors.Add: TJQXProgressBarColor;
begin
  Result := TJQXProgressBarColor(inherited Add);
end;

constructor TJQXProgressBarColors.Create(AOwner: TJQXProgressBar);
begin
  inherited Create(TJQXProgressBarColor);
  FOwner := AOwner;
end;

function TJQXProgressBarColors.GetItems(Index: Integer): TJQXProgressBarColor;
begin
  Result := TJQXProgressBarColor(inherited Items[Index]);
end;

function TJQXProgressBarColors.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TJQXProgressBarColors.Insert(Index: Integer): TJQXProgressBarColor;
begin
  Result := TJQXProgressBarColor(inherited Insert(Index));
end;

procedure TJQXProgressBarColors.SetItems(Index: Integer; const Value: TJQXProgressBarColor);
begin
  inherited Items[Index] := Value;
end;

procedure TJQXProgressBarColors.Update(Item: TCollectionItem);
begin
  inherited;
end;

{ TJQXSlider }

procedure TJQXSlider.CreateInitialize;
begin
  inherited;
  FButtonsPosition := hpBoth;
  FTicksPosition := vpBoth;
  FTicksFrequency := 2;
  FTickSize := 5;
  FMinorTicksFrequency := 2;
  FMinorTickSize := 5;
  FShowButtons := true;
  FShowTicks := true;
  FShowMinorTicks := true;
  FShowRange := true;
  FShowRangeSlider := false;
  FMaximumValue := 10;
  FMinimumValue := 0;
  FMaximum := 10;
  FMinimum := 0;
  FMode := smDefault;
  FStep := 2;
  FTemplate := ttDefault;
  FLayout := plNormal;
  FValue := 0;
  FIsLoaded := False;
  Width := 300;
  Height := 25;

  RequiredScripts.Add('jqxbuttons.js');
  RequiredScripts.Add('jqxslider.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXSlider.BindEvents;
begin
  //disabled
end;

procedure TJQXSlider.InitJQuery;
begin
  inherited;

  if IsUpdating then
    Exit;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    FValue := 5;
    TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('value', FValue);
    TJQXWidgetSlider(JQuery(GetJQID)).on('change', @HandleSliderChange);
    UpdateElement;
  end;
end;

procedure TJQXSlider.UpdateElement;
var
  stemplate: string;
  slayout: string;
  bpos, tpos: string;
  smode: string;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider(TJSJSON.parseObject('{"width": ' + IntToStr(Width)
        + ', "height": ' + IntToStr(Height) + '}'));

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider(TJSJSON.parseObject('{"disabled": ' + LowerCase(BoolToStr(not Enabled, True)) + '}'));

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('rangeSlider', FShowRangeSlider);

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('theme', GetTheme(Theme));

      if ShowRangeSlider then
        TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('setValue', FMinimumValue, FMaximumValue)
      else
        TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('setValue', FValue);

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('max', FMaximum);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('min', FMinimum);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('step', FStep);

      case ButtonsPosition of
        hpBoth: bpos := 'both';
        hpLeft: bpos := 'left';
        hpRight: bpos := 'right';
      end;
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('buttonsPosition', bpos);

      case TicksPosition of
        vpBoth: tpos := 'both';
        vpTop: tpos := 'top';
        vpBottom: tpos := 'bottom';
      end;
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('ticksPosition', tpos);

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('ticksFrequency', FTicksFrequency);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('tickSize', FTickSize);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('minorTicksFrequency', FMinorTicksFrequency);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('minorTickSize', FMinorTickSize);

      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('showTicks', FShowTicks);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('showMinorTicks', FShowMinorTicks);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('showButtons', FShowButtons);
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('showRange', FShowRange);

      case Mode of
        smDefault: smode := 'default';
        smFixed: smode := 'fixed';
      end;
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('mode', smode);

      case Template of
        ttDefault: stemplate := 'default';
        ttPrimary: stemplate := 'primary';
        ttSuccess: stemplate := 'success';
        ttWarning: stemplate := 'warning';
        ttDanger: stemplate := 'danger';
        ttInfo: stemplate := 'info';
      end;
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('template', stemplate);

      slayout := 'normal';
      if Layout = plReverse then
        slayout := 'reverse';
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('layout', slayout);
    end;
  end;
end;

procedure TJQXSlider.UpdateSize;
begin
  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
      TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider(TJSJSON.parseObject('{"width": ' + IntToStr(Width)
        + ', "height": ' + IntToStr(Height) + '}'));
  end;
end;

function TJQXSlider.HandleSliderChange(Event: TJQXEvent): Boolean;
var
  Args: TJQXSliderEventArgs;
  o: TJSObject;
  s: string;
begin
  if Assigned(OnChange) then
  begin
    Args := TJQXSliderEventArgs.Create;

    if not ShowRangeSlider then
    begin
      Args.Value := StrToFloat(string(Event.Args.Properties['value']), TFormatSettings.Invariant);
      FValue := Args.Value;
    end
    else
    begin
      o := TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('getValue');
      if Assigned(o) then
      begin
        s := string(o.Properties['rangeStart']);
        if s <> '' then
          Args.MinimumValue := StrToFloat(s, TFormatSettings.Invariant);
        s := string(o.Properties['rangeEnd']);
        if s <> '' then
          Args.MaximumValue := StrToFloat(s, TFormatSettings.Invariant);
      end;
    end;

    OnChange(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXSlider.GetMaximumValue: Double;
var
  s: string;
  o: TJSObject;
begin
  Result := FMaximumValue;

  if not FIsLoaded then
    Exit;

  if ShowRangeSlider then
  begin
    o := TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('getValue');
    if Assigned(o) then
    begin
      s := string(o.Properties['rangeEnd']);
      if s <> '' then
        Result := StrToFloat(s, TFormatSettings.Invariant);
    end;
  end;
end;

function TJQXSlider.GetMinimumValue: Double;
var
  s: string;
  o: TJSObject;
begin
  Result := FMinimumValue;

  if not FIsLoaded then
    Exit;

  if ShowRangeSlider then
  begin
    o := TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('getValue');
    if Assigned(o) then
    begin
      s := string(o.Properties['rangeStart']);
      if s <> '' then
        Result := StrToFloat(s, TFormatSettings.Invariant);
    end;
  end;
end;

procedure TJQXSlider.SetMaximum(const Value: Double);
begin
  FMaximum := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMaximumValue(const Value: Double);
begin
  FMaximumValue := Value;
  UpdateElement;
end;

function TJQXSlider.GetValue: Double;
var
  s: string;
  o: TJSObject;
begin
  Result := FValue;

  if not FIsLoaded then
    Exit;

  if not ShowRangeSlider then
  begin
    o := TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('value');
    s := o.ToString;
    Result := StrToFloat(s, TFormatSettings.Invariant);
  end;
end;

procedure TJQXSlider.SetValue(AValue: Double);
begin
  FValue := AValue;

  if not FIsLoaded then
    Exit;

  if not ShowRangeSlider then
    TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('value', AValue);
end;

procedure TJQXSlider.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;

procedure TJQXSlider.SetButtonsPosition(const Value: TJQXHorizontalPosition);
begin
  FButtonsPosition := Value;
end;

procedure TJQXSlider.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXSlider.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;

procedure TJQXSlider.SetLayout(const Value: TJQXProgressLayout);
begin
  FLayout := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMinimum(const Value: Double);
begin
  FMinimum := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMinimumValue(const Value: Double);
begin
  FMinimumValue := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMinorTicksFrequency(const Value: Integer);
begin
  FMinorTicksFrequency := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMinorTickSize(const Value: Integer);
begin
  FMinorTickSize := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetMode(const Value: TJQXSliderMode);
begin
  FMode := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetShowButtons(const Value: Boolean);
begin
  FShowButtons := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetShowMinorTicks(const Value: Boolean);
begin
  FShowMinorTicks := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetShowRange(const Value: Boolean);
begin
  FShowRange := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetShowRangeSlider(const Value: Boolean);
begin
  FShowRangeSlider := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetShowTicks(const Value: Boolean);
begin
  FShowTicks := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetStep(const Value: Double);
begin
  FStep := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetTemplate(const Value: TJQXTemplate);
begin
  FTemplate := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetSlider(JQuery(GetJQID)).jqxSlider('theme', GetTheme(AValue))
end;

procedure TJQXSlider.SetTicksFrequency(const Value: Integer);
begin
  FTicksFrequency := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetTickSize(const Value: Integer);
begin
  FTickSize := Value;
  UpdateElement;
end;

procedure TJQXSlider.SetTicksPosition(const Value: TJQXVerticalPosition);
begin
  FTicksPosition := Value;
  UpdateElement;
end;

{ TJQXKnobAppearance }

procedure TJQXKnobAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXKnobAppearance) then
  begin
    FColor := (Source as TJQXKnobAppearance).Color;
    FBorderColor := (Source as TJQXKnobAppearance).BorderColor;
    FBorderWidth := (Source as TJQXKnobAppearance).BorderWidth;
    FLabels := (Source as TJQXKnobAppearance).Labels;
    FProgressBar := (Source as TJQXKnobAppearance).ProgressBar;
    FMarks := (Source as TJQXKnobAppearance).Marks;
    FPointer := (Source as TJQXKnobAppearance).Pointer;
  end;
end;

constructor TJQXKnobAppearance.Create(AOwner: TJQXKnob);
begin
  inherited Create;
  FColor := clNone;
  FBorderColor := clNone;
  FBorderWidth := 1;
  FLabels := TJQXKnobLabels.Create(Self);
  FProgressBar := TJQXKnobProgressBar.Create(Self);
  FMarks := TJQXKnobMarks.Create(Self);
  FPointer := TJQXKnobPointer.Create(Self);
  FOwner := AOwner;
end;

destructor TJQXKnobAppearance.Destroy;
begin
  FLabels.Free;
  FProgressBar.Free;
  FMarks.Free;
  FPointer.Free;
  inherited;
end;

function TJQXKnobAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXKnobAppearance.SetBorderColor(const Value: TColor);
begin
  FBorderColor := Value;
  FOwner.UpdateElement;
end;

procedure TJQXKnobAppearance.SetBorderWidth(const Value: Integer);
begin
  FBorderWidth := Value;
  FOwner.UpdateElement;
end;

procedure TJQXKnobAppearance.SetColor(const Value: TColor);
begin
  FColor := Value;
  FOwner.UpdateElement;
end;

{ TJQXKnobLabels }

procedure TJQXKnobLabels.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXKnobLabels) then
  begin
    FOffset := (Source as TJQXKnobLabels).Offset;
    FStep := (Source as TJQXKnobLabels).Step;
    FVisible := (Source as TJQXKnobLabels).Visible;
  end;
end;

constructor TJQXKnobLabels.Create(AOwner: TJQXKnobAppearance);
begin
  FOffset := 88;
  FStep := 10;
  FVisible := True;
  FOwner := AOwner;
end;

destructor TJQXKnobLabels.Destroy;
begin
  inherited;
end;

function TJQXKnobLabels.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXKnobLabels.SetOffset(const Value: Integer);
begin
  FOffset := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobLabels.SetStep(const Value: Integer);
begin
  FStep := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobLabels.SetVisible(const Value: Boolean);
begin
  FVisible := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXKnobProgressBar }

procedure TJQXKnobProgressBar.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXKnobProgressBar) then
  begin
    FColor := (Source as TJQXKnobProgressBar).Color;
    FSize := (Source as TJQXKnobProgressBar).Size;
    FOffset := (Source as TJQXKnobProgressBar).Offset;
    FBackgroundColor := (Source as TJQXKnobProgressBar).BackgroundColor;
    FBorderColor := (Source as TJQXKnobProgressBar).BorderColor;
  end;
end;

constructor TJQXKnobProgressBar.Create(AOwner: TJQXKnobAppearance);
begin
  FColor := clSilver;
  FSize := 9;
  FOffset := 60;
  FBackgroundColor := clGray;
  FBorderColor := clBlack;
  FOwner := AOwner;
end;

destructor TJQXKnobProgressBar.Destroy;
begin
  inherited;
end;

function TJQXKnobProgressBar.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXKnobProgressBar.SetBackgroundColor(const Value: TColor);
begin
  FBackgroundColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobProgressBar.SetBorderColor(const Value: TColor);
begin
  FBorderColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobProgressBar.SetColor(const Value: TColor);
begin
  FColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobProgressBar.SetOffset(const Value: Integer);
begin
  FOffset := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobProgressBar.SetSize(const Value: Integer);
begin
  FSize := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXKnobMarks }

procedure TJQXKnobMarks.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXKnobMarks) then
  begin
    FOffset := (Source as TJQXKnobMarks).Offset;
    FMajorSize := (Source as TJQXKnobMarks).MajorSize;
    FColorProgress := (Source as TJQXKnobMarks).ColorProgress;
    FWidth := (Source as TJQXKnobMarks).Width;
    FBorderColorRemaining := (Source as TJQXKnobMarks).BorderColorRemaining;
    FBorderColorProgress := (Source as TJQXKnobMarks).BorderColorProgress;
    FMinorInterval := (Source as TJQXKnobMarks).MinorInterval;
    FSize := (Source as TJQXKnobMarks).Size;
    FColorRemaining := (Source as TJQXKnobMarks).ColorRemaining;
    FMajorInterval := (Source as TJQXKnobMarks).MajorInterval;
    FMarkType := (Source as TJQXKnobMarks).MarkType;
  end;
end;

constructor TJQXKnobMarks.Create(AOwner: TJQXKnobAppearance);
begin
  inherited Create;
  FColorRemaining := clGray;
  FBorderColorRemaining := clGray;
  FColorProgress := clSilver;
  FBorderColorProgress := clSilver;
  FMarkType := mtLine;
  FOffset := 71;
  FWidth := 3;
  FSize := 6;
  FMajorSize := 9;
  FMajorInterval := 10;
  FMinorInterval := 2;
  FOwner := AOwner;
end;

destructor TJQXKnobMarks.Destroy;
begin
  inherited;
end;

function TJQXKnobMarks.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXKnobMarks.SetBorderColorProgress(const Value: TColor);
begin
  FBorderColorProgress := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetBorderColorRemaining(const Value: TColor);
begin
  FBorderColorRemaining := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetColorProgress(const Value: TColor);
begin
  FColorProgress := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetColorRemaining(const Value: TColor);
begin
  FColorRemaining := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetMajorInterval(const Value: Integer);
begin
  FMajorInterval := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetMajorSize(const Value: Integer);
begin
  FMajorSize := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetMarkType(const Value: TJQXKnobMarkType);
begin
  FMarkType := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetMinorInterval(const Value: Integer);
begin
  FMinorInterval := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetOffset(const Value: Integer);
begin
  FOffset := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetSize(const Value: Integer);
begin
  FSize := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobMarks.SetWidth(const Value: Integer);
begin
  FWidth := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXKnobPointer }

procedure TJQXKnobPointer.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXKnobPointer) then
  begin
    FPointerType := (Source as TJQXKnobPointer).PointerType;
    FColor := (Source as TJQXKnobPointer).Color;
    FBorderColor := (Source as TJQXKnobPointer).BorderColor;
    FSize := (Source as TJQXKnobPointer).Size;
    FOffset := (Source as TJQXKnobPointer).Offset;
    FVisible := (Source as TJQXKnobPointer).Visible;
    FWidth := (Source as TJQXKnobPointer).Width;
  end;
end;

constructor TJQXKnobPointer.Create(AOwner: TJQXKnobAppearance);
begin
  FPointerType := ptArrow;
  FColor := clGray;
  FBorderColor := clSilver;
  FSize := 59;
  FOffset := 49;
  FVisible := True;
  FWidth := 20;
  FOwner := AOwner;
end;

destructor TJQXKnobPointer.Destroy;
begin
  inherited;
end;

function TJQXKnobPointer.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXKnobPointer.SetBorderColor(const Value: TColor);
begin
  FBorderColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetColor(const Value: TColor);
begin
  FColor := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetOffset(const Value: Integer);
begin
  FOffset := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetPointerType(const Value: TJQXKnobPointerType);
begin
  FPointerType := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetSize(const Value: Integer);
begin
  FSize := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetVisible(const Value: Boolean);
begin
  FVisible := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXKnobPointer.SetWidth(const Value: Integer);
begin
  FWidth := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXRangeSelector }

procedure TJQXRangeSelector.BindEvents;
begin
//disable;
end;

procedure TJQXRangeSelector.CreateInitialize;
begin
  inherited;
  FMinimum := 0;
  FMaximum := 10;
  FMinimumValue := 0;
  FMaximumValue := 10;
  FMajorTicksInterval := 5;
  FMinorTicksInterval := 1;
  FMoveOnClick := True;
  FResizable := True;
  FShowMajorTicks := True;
  FShowMinorTicks := True;
  FShowMarkers := True;
  Height := 60;
  Width := 200;

  RequiredScripts.Add('jqxdata.js');
  RequiredScripts.Add('jqxrangeselector.js');

  ScriptLoaded := not AddRequiredScripts;
end;

function TJQXRangeSelector.GetMaximumValue: Double;
var
  s: string;
  o: TJSObject;
begin
  Result := FMaximumValue;

  if not FIsLoaded then
    Exit;

  o := TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('getRange');
  if Assigned(o) then
  begin
    s := string(o.Properties['to']);
    if s <> '' then
      Result := StrToFloat(s, TFormatSettings.Invariant);
  end;

  FMaximumValue := Result;
end;

function TJQXRangeSelector.GetMinimumValue: Double;
var
  s: string;
  o: TJSObject;
begin
  Result := FMinimumValue;

  if not FIsLoaded then
    Exit;

  o := TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('getRange');
  if Assigned(o) then
  begin
    s := string(o.Properties['from']);
    if s <> '' then
      Result := StrToFloat(s, TFormatSettings.Invariant);
  end;

  FMinimumValue := Result;
end;

function TJQXRangeSelector.HandleRangeChange(Event: TJQXEvent): boolean;
begin
  if Assigned(OnChange) then
    OnChange(Self);
  Result := True;
end;

procedure TJQXRangeSelector.InitJQuery;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height - 50) + '}'));
    TJQXWidgetRangeSelector(JQuery(GetJQID)).on('change', @HandleRangeChange);
    UpdateElement;
  end;
end;

procedure TJQXRangeSelector.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMajorTicksInterval(const Value: Integer);
begin
  FMajorTicksInterval := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMaximum(const Value: Double);
begin
  FMaximum := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMaximumValue(const Value: Double);
begin
  FMaximumValue := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMinimum(const Value: Double);
begin
  FMinimum := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMinimumValue(const Value: Double);
begin
  FMinimumValue := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMinorTicksInterval(const Value: Integer);
begin
  FMinorTicksInterval := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetMoveOnClick(const Value: Boolean);
begin
  FMoveOnClick := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetResizable(const Value: Boolean);
begin
  FResizable := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetShowMajorTicks(const Value: Boolean);
begin
  FShowMajorTicks := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetShowMarkers(const Value: Boolean);
begin
  FShowMarkers := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetShowMinorTicks(const Value: Boolean);
begin
  FShowMinorTicks := Value;
  UpdateElement;
end;

procedure TJQXRangeSelector.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('theme', GetTheme(AValue));
    UpdateElement;
  end;
end;

procedure TJQXRangeSelector.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXRangeSelector.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      ElementHandle.style.setProperty('overflow','visible');
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height-50) + '}'));
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('max', FMaximum);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('min', FMinimum);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector(TJSJSON.parseObject('{"range": {"from": ' + FloatToStr(FMinimumValue, TFormatSettings.Invariant) + ', "to": ' + FloatToStr(FMaximumValue) + '}}'));
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('resizable', FResizable);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('disabled', not Enabled);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('majorTicksInterval', FMajorTicksInterval);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('minorTicksInterval', FMinorTicksInterval);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('moveOnClick', FMoveOnClick);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('showMajorTicks', FShowMajorTicks);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('showMinorTicks', FShowMinorTicks);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('showMarkers', FShowMarkers);
      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('theme', GetTheme(Theme));
//      TJQXWidgetRangeSelector(JQuery(GetJQID)).jqxRangeSelector('snapToTicks', True);
    end;
  end;
end;

{ TJQXTagCloud }

procedure TJQXTagCloud.BindEvents;
begin
//disable;
end;

procedure TJQXTagCloud.CreateInitialize;
var
  ltag: TJQXCloudTag;
begin
  inherited;
  FDisplayLimit := -1;
  FDisplayTopWeighted := False;
  FDisplayMaxValue := -1;
  FDisplayMinValue := -1;
  FItems := TJQXCloudTags.Create(Self);
  FMaxColor := clNone;
  FMinColor := clNone;
  FMaxFontSize := 36;
  FMinFontSize := 8;
  FSortBy := tcsNone;
  FSortOrder := soAscending;
  FTextCase := tcOriginal;
  Height := 200;
  Width := 200;

  RequiredScripts.Add('jqxdata.js');
  RequiredScripts.Add('jqxtagcloud.js');

  ltag := Items.Add;
  ltag.TagLabel := 'Tag Item 1';
  ltag.TagValue := 50;

  ltag := Items.Add;
  ltag.TagLabel := 'Tag Item 2';
  ltag.TagValue := 100;

  ltag := Items.Add;
  ltag.TagLabel := 'Tag Item 3';
  ltag.TagValue := 75;

  ScriptLoaded := not AddRequiredScripts;
end;

function TJQXTagCloud.DataToArray: TJSArray;
var
  I: Integer;
begin
  Result := TJSArray.New;
  for I := 0 to Items.Count - 1 do
  begin
    Result.Push(New(['name', Items[I].TagLabel, 'rating', Items[I].TagValue]));
  end;
end;

destructor TJQXTagCloud.Destroy;
begin
  FItems.Free;
  inherited;
end;

function TJQXTagCloud.HandleItemClick(Event: TJQXEvent): boolean;
var
  Args: TJQXTagEventArgs;
begin
  Result := false;

  if not Enabled then
    Exit;

  if Assigned(OnClick) then
  begin
    Args := TJQXTagEventArgs.Create;
    Args.TagIndex := StrToInt(string(Event.Args.Properties['index']));
    Args.DisplayIndex := StrToInt(string(Event.Args.Properties['visibleIndex']));
    Args.TagLabel := string(Event.Args.Properties['label']);
    Args.TagValue := StrToInt(string(Event.Args.Properties['value']));
    OnClick(Self, Args);
    Args.Free;
  end;

  Result := true;
end;

{$HINTS OFF}
procedure TJQXTagCloud.InitJQuery;
var
  jqid: string;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;

    jqid := GetJQID;
    asm
    var source =
    {
        localData: null,
        dataType: "array",
        dataFields: [
            {name: 'name', type: 'string'},
            {name: 'rating', type: 'number'}
        ]
    };
    var dataAdapter = new $.jqx.dataAdapter(source, {});

    $(jqid).jqxTagCloud({
        source: dataAdapter,
        displayMember: 'name'
    });
    end;

    TJQXWidgetTagCloud(JQuery(GetJQID)).on('itemClick', @HandleItemClick);

    UpdateElement;
  end;
end;
{$HINTS ON}

procedure TJQXTagCloud.SetDisplayLimit(const Value: Integer);
begin
  FDisplayLimit := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetDisplayMaxValue(const Value: Integer);
begin
  FDisplayMaxValue := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetDisplayMinValue(const Value: Integer);
begin
  FDisplayMinValue := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetDisplayTopWeighted(const Value: Boolean);
begin
  FDisplayTopWeighted := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTagCloud.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTagCloud.SetMaxColor(const Value: TColor);
begin
  FMaxColor := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetMaxFontSize(const Value: integer);
begin
  FMaxFontSize := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetMinColor(const Value: TColor);
begin
  FMinColor := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetMinFontSize(const Value: integer);
begin
  FMinFontSize := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetSortBy(const Value: TJQXTagCloudSort);
begin
  FSortBy := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetSortOrder(const Value: TJQXSortOrder);
begin
  FSortOrder := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetTextCase(const Value: TJQXTextCase);
begin
  FTextCase := Value;
  UpdateElement;
end;

procedure TJQXTagCloud.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTagCloud.UpdateElement;
var
  a: TJQDataAdapter;
  ar: TJSArray;
  ssort: string;
  ssortorder: string;
  stextcase: string;
  ilimit: Integer;
  imaxval: Integer;
  cmax: string;
  cmin: string;
  scolor: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      ar := TJSArray.New;
      ar.Push(New(['name', 'name', 'type', 'string']));
      ar.Push(New(['name', 'rating', 'type', 'number']));
//      ar.Push(New(['name', 'url', 'type', 'string']));
      a := TJQDataAdapter.New(New(['datatype', 'array', 'datafields', ar, 'localdata', DataToArray]));
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('source', a);
//      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('source', a, 'displayMember', 'name');

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
//      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('displayMember', 'name');
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('valueMember', 'rating');
//      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('urlMember', 'url');

      ssort := 'none';
      case FSortBy of
        tcsLabel: ssort := 'label';
        tcsValue: ssort := 'value';
      end;

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('sortBy', ssort);

      ssortorder := 'ascending';
      if FSortOrder = soDescending then
        ssortorder := 'descending';

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('sortOrder', ssortorder);

      stextcase := 'none';
      case FTextCase of
        tcUpperCase: stextcase := 'allUpper';
        tcLowerCase: stextcase := 'allLower';
        tcFirstUpper: stextcase := 'firstUpper';
        tcCamelCase: stextcase := 'titleCase';
      end;

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('alterTextCase', stextcase);

      ilimit := 9999;
      if FDisplayLimit >= 0 then
        ilimit := FDisplayLimit;

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('displayLimit', ilimit);
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('takeTopWeightedItems', FDisplayTopWeighted);

      imaxval := 9999;
      if FDisplayMaxValue > FDisplayMinValue then
        imaxval := FDisplayMaxValue;

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('minValueToDisplay', FDisplayMinValue);
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('maxValueToDisplay', imaxval);

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('minFontSize', FMinFontSize);
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('maxFontSize', FMaxFontSize);

      cmax := '';
      if MaxColor <> clNone then
        cmax := ColorToHTML(MaxColor);
      cmin := '';
      if MinColor <> clNone then
        cmin := ColorToHTML(MinColor);

      scolor := '';
      if cmin <> '' then
        scolor := cmin
      else if cmax <> '' then
        scolor := cmax;

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('minColor', cmin);
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('maxColor', cmax);
      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('textColor', scolor);

      TJQXWidgetTagCloud(JQuery(GetJQID)).jqxTagCloud('disabled', not Enabled);
    end;
  end;
end;

{ TJQXCloudTag }

procedure TJQXCloudTag.Assign(Source: TPersistent);
begin
  if (Source is TJQXCloudTag) then
  begin
    FTag := (Source as TJQXCloudTag).Tag;
    FTagName := (Source as TJQXCloudTag).TagName;
    FTagValue := (Source as TJQXCloudTag).TagValue;
  end;
end;

constructor TJQXCloudTag.Create(Collection: TCollection);
begin
  inherited;
  FTag := -1;
  FTagName := 'Tag '+ IntToStr(Index);
  FTagValue := 0;

  if Collection is TJQXCloudTags then
  begin
    FOwner := Collection as TJQXCloudTags;
  end;
end;

destructor TJQXCloudTag.Destroy;
begin
  inherited;
end;

function TJQXCloudTag.GetDisplayName: string;
begin
  Result := 'Tag '+ IntToStr(Index);
end;

function TJQXCloudTag.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXCloudTag.SetTagLabel(const Value: String);
begin
  FTagLabel := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXCloudTag.SetTagName(const Value: String);
begin
  FTagName := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXCloudTag.SetTagValue(const Value: Integer);
begin
  FTagValue := Value;
  FOwner.FOwner.UpdateElement;
end;

{ TJQXCloudTags }

function TJQXCloudTags.Add: TJQXCloudTag;
begin
  Result := TJQXCloudTag(inherited Add);
end;

constructor TJQXCloudTags.Create(AOwner: TJQXTagCloud);
begin
  inherited Create(TJQXCloudTag);
  FOwner := AOwner;
end;

function TJQXCloudTags.GetItems(Index: Integer): TJQXCloudTag;
begin
  Result := TJQXCloudTag(inherited Items[Index]);
end;

function TJQXCloudTags.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TJQXCloudTags.Insert(Index: Integer): TJQXCloudTag;
begin
  Result := TJQXCloudTag(inherited Insert(Index));
end;

procedure TJQXCloudTags.SetItems(Index: Integer; const Value: TJQXCloudTag);
begin
  inherited Items[Index] := Value;
end;

procedure TJQXCloudTags.Update(Item: TCollectionItem);
begin
  inherited;
end;

{ TJQXResponsivePanel }

procedure TJQXResponsivePanel.BindEvents;
begin
//  inherited;
end;

procedure TJQXResponsivePanel.Close;
begin
  TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('close');
end;

procedure TJQXResponsivePanel.CreateInitialize;
begin
  inherited;
  FAnimationType := atNone;
  FAutoClose := True;
  FCollapseBreakpoint := 1000;
  FToggleButtonSize := 30;
  Height := 200;
  Width := 200;
  WidthPercent := 100;
  WidthStyle := ssPercent;

  RequiredScripts.Add('jqxresponsivepanel.js');

  ScriptLoaded := not AddRequiredScripts;
end;

function TJQXResponsivePanel.GetButtonID: string;
begin
  Result := GetJQID + 'BTN';
end;

function TJQXResponsivePanel.GetContentID: string;
begin
  Result := GetJQID + 'DIV';
end;

function TJQXResponsivePanel.HandleClose(Event: TJQXElementEvent): boolean;
begin
  if Assigned(OnClose) then
    OnClose(Self);

  Result := true;
end;

function TJQXResponsivePanel.HandleCollapse(Event: TJQXElementEvent): boolean;
begin
  if Assigned(OnCollapse) then
    OnCollapse(Self);

  Result := true;
end;

function TJQXResponsivePanel.HandleExpand(Event: TJQXElementEvent): boolean;
begin
  if Assigned(OnExpand) then
    OnExpand(Self);

  Result := true;
end;

function TJQXResponsivePanel.HandleOpen(Event: TJQXElementEvent): boolean;
begin
  if Assigned(OnOpen) then
    OnOpen(Self);

  Result := true;
end;

procedure TJQXResponsivePanel.InitJQuery;
var
  idContent: string;
  idButton: string;
  content: TJSElement;
  button: TJSElement;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if not Assigned(Container) then
    Exit;

  idContent := GetID + 'DIV';
  idButton := GetID + 'BTN';

  button := document.createElement('DIV');
  button['id'] := idButton;

  content := document.createElement('DIV');
  content['id'] := idContent;

  while Assigned(Container.firstChild) do
    content.appendChild(Container.children[0]);

  Container.appendChild(button);
  Container.appendChild(content);

  if Assigned(ElementHandle) then
  begin
    idContent := GetContentID;
    idButton := GetButtonID;
    FIsLoaded := True;

    asm
      $(idContent).jqxResponsivePanel({
          toggleButton: $(idButton),
      });
    end;

    TJQXWidgetResponsivePanel(JQuery(idContent)).jqxResponsivePanel(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetResponsivePanel(JQuery(idContent)).on('open', @HandleOpen);
    TJQXWidgetResponsivePanel(JQuery(idContent)).on('close', @HandleClose);
    TJQXWidgetResponsivePanel(JQuery(idContent)).on('collapse', @HandleCollapse);
    TJQXWidgetResponsivePanel(JQuery(idContent)).on('expand', @HandleExpand);
    UpdateElement;
  end;
end;

procedure TJQXResponsivePanel.Open;
begin
  TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('open');
end;

procedure TJQXResponsivePanel.Refresh;
begin
  TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('refresh');
end;

procedure TJQXResponsivePanel.SetAnimationType(const Value: TJQXAnimationType);
begin
  FAnimationType := Value;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetAutoClose(const Value: Boolean);
begin
  FAutoClose := Value;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetCollapsBreakPoint(const Value: Integer);
begin
  FCollapseBreakPoint := Value;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('theme', GetTheme(AValue))
end;

procedure TJQXResponsivePanel.SetToggleButtonSize(const Value: Integer);
begin
  FToggleButtonSize := Value;
  UpdateElement;
end;

procedure TJQXResponsivePanel.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXResponsivePanel.UpdateElement;
var
  satype: string;
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('autoClose', BoolToStr(FAutoClose, True));
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('collapseBreakpoint', FCollapseBreakpoint);
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('toggleButtonSize', FToggleButtonSize);
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('toggleButtonSize', FToggleButtonSize);

    satype := 'none';
    if FAnimationType = aTSlide then
      satype := 'slide'
    else if FAnimationType = aTFade then
      satype := 'fade';

    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('animationType', satype);
    TJQXWidgetResponsivePanel(JQuery(GetContentID)).jqxResponsivePanel('animationDirection', 'top');
  end;
end;

{ TJQXTabs }

procedure TJQXTabs.BindEvents;
begin
//  inherited;
end;

procedure TJQXTabs.CreateInitialize;
begin
  inherited;
  FCollapsible := False;
  FEnableHover := True;
  FEnableScrollAnimation := True;
  FPosition := tpTop;
  FReorder := False;
  FSelectionTracker := False;
  FScrollPosition := hpRight;
  FScrollStep := 70;
  FToggleMode := tmClick;
  Height := 200;
  Width := 200;

  RequiredScripts.Add('jqxtabs.js');

  ScriptLoaded := not AddRequiredScripts;
end;

function TJQXTabs.HandleSelected(Event: TJQXEvent): boolean;
var
  Args: TJQXTabEventArgs;
  index: string;
begin

  GetTabIndex;

  if Assigned(OnSelected) then
  begin
    Args := TJQXTabEventArgs.Create;
    index := string(Event.Args.Properties['item']);
    if index <> '' then
      Args.TabIndex := StrToInt(index)
    else
      Args.TabIndex := -1;
    OnSelected(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXTabs.HandleTabClick(Event: TJQXEvent): boolean;
var
  Args: TJQXTabEventArgs;
  index: string;
begin
  if Assigned(OnTabClick) then
  begin
    Args := TJQXTabEventArgs.Create;
    index := string(Event.Args.Properties['item']);
    if index <> '' then
      Args.TabIndex := StrToInt(index)
    else
      Args.TabIndex := -1;
    OnTabClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TJQXTabs.InitJQuery;
var
  listitem: TJSElement;
  list: TJSElement;
  I: Integer;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not Assigned(Container) then
    Exit;

  if not ScriptLoaded then
    Exit;

  if ControlCount <= 0 then
    Exit;

  list := document.createElement('UL');
  for I := 0 to ControlCount - 1 do
  begin
    listitem := document.createElement('LI');
    listitem.innerHTML := TJQXTabSheet(Controls[I]).Caption;
    list.appendChild(listitem);
  end;
  Container.appendChild(list);

  for i := 0 to ControlCount - 1 do
  begin
    if Controls[i] is TJQXTabSheet then
    begin
      TJQXTabSheet(Controls[i]).GetElementHandle.style.setProperty('top', '');
      TJQXTabSheet(Controls[i]).GetElementHandle.style.setProperty('border-width', '0px');
      TJQXTabSheet(Controls[i]).ElementHandle.style.setProperty('white-space', '');
    end;
  end;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;

    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetTabs(JQuery(GetJQID)).on('selected', @HandleSelected);
    TJQXWidgetTabs(JQuery(GetJQID)).on('tabclick', @HandleTabClick);
    UpdateElement;
  end;
end;

procedure TJQXTabs.SetCollapsible(const Value: Boolean);
begin
  FCollapsible := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetEnabled(Value: boolean);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTabs.SetEnableHover(const Value: Boolean);
begin
  FEnableHover := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetEnableScrollAnimation(const Value: Boolean);
begin
  FEnableScrollAnimation := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTabs.SetPosition(const Value: TJQXTabPosition);
begin
  FPosition := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetReorder(const Value: Boolean);
begin
  FReorder := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetScrollPosition(const Value: TJQXHorizontalPosition);
begin
  FScrollPosition := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetScrollStep(const Value: Integer);
begin
  FScrollStep := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetSelectionTracker(const Value: Boolean);
begin
  FSelectionTracker := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetTabIndex(const Value: Integer);
begin
  FTabIndex := Value;
  UpdateElement;
end;

function TJQXTabs.GetTabIndex: Integer;
var
  index: string;
begin
  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    index := TJSJSON.stringify(TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('selectedItem'));
    if (index <> '') and (index <> 'null') then
      FTabIndex := StrToInt(index);
  end;

  Result := FTabIndex;
end;

procedure TJQXTabs.SetTheme(AValue: string);
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('theme', GetTheme(AValue))
end;

procedure TJQXTabs.SetToggleMode(const Value: TJQXToggleMode);
begin
  FToggleMode := Value;
  UpdateElement;
end;

procedure TJQXTabs.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateElement;
end;

procedure TJQXTabs.UpdateElement;
var
  spos, sscrollpos, stoggle: string;
begin
  inherited;

  if not FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if not Enabled then
      ElementHandle.style.setProperty('pointer-events', 'none')
    else
      ElementHandle.style.setProperty('pointer-events', 'auto');

    spos := 'top';
    if FPosition = tpBottom then
      spos := 'bottom';

    sscrollpos := 'right';
    if FScrollPosition = hpBoth then
      sscrollpos := 'both'
    else if FScrollPosition = hpLeft then
      sscrollpos := 'left';

    stoggle := 'click';
    if FToggleMode = tmDoubleClick then
      stoggle := 'dblclick'
    else if FToggleMode = tmMouseEnter then
      stoggle := 'mouseenter';

    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('collapsible', FCollapsible);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('enabledHover', FEnableHover);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('enableScrollAnimation', FEnableScrollAnimation);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('position', spos);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('reorder', FReorder);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('selectionTracker', FSelectionTracker);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('scrollPosition', sscrollpos);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('scrollStep', FScrollStep);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('toggleMode', stoggle);
    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('theme', GetTheme(Theme));

    TJQXWidgetTabs(JQuery(GetJQID)).jqxTabs('selectedItem', FTabIndex);
  end;
end;

{ TJQXTabSheet }

procedure TJQXTabSheet.BindEvents;
begin
  //disabled
end;

procedure TJQXTabSheet.CreateInitialize;
begin
  inherited;
  Color := clWhite;
end;

end.
