{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Graphics;

{$modeswitch externalclass}

interface

uses
  Classes, Types, Web, JS;

const
  WEBDEFAULTFONT = 'Arial';
  SysDefault = $20000000;

  clNone = -1;
  clBlack = $000000;
  clMaroon = $000080;
  clGreen = $008000;
  clOlive = $008080;
  clNavy = $800000;
  clPurple = $800080;
  clTeal = $808000;
  clGray = $808080;
  clSilver = $C0C0C0;
  clRed = $0000FF;
  clLime = $00FF00;
  clYellow = $00FFFF;
  clBlue = $FF0000;
  clFuchsia = $FF00FF;
  clAqua = $FFFF00;
  clLtGray = $C1C1C1;
  clDkGray = $818181;
  clWhite = $FFFFFF;
  clMoneyGreen = $C0DCC0;
  clSkyBlue = $F0CAA6;
  clCream = $F0FBFF;
  clMedGray = $A0A0A0;

  clDefault = SysDefault;
  clBtnFace = $F0F0F0;
  clWindowText = $010101;
  clWindow = $FEFEFE;
  clHighlight = $D77800;
  clHighlightText = $030303;
  clInfoText = $020202;
  clInfoBk = $E1FFFF;
  clActiveCaption = $D1B499;
  clInactiveCaption =  $DBCDBF;
  clInactiveCaptionText = $000000;
  clHotLight = $CC6600;

  clScrollBar = $C8C8C8;
  clBackground = clBtnFace;
  clMenu = clBtnFace;
  clWindowFrame = $646464;
  clMenuText = clBlack;
  clCaptionText = clWhite;
  clActiveBorder = $B4B4B4;
  clAppWorkSpace = $ABABAB;
  clBtnShadow = $A0A0A0;
  clGrayText = $6D6D6D;
  clBtnText = clBlack;
  clBtnHighlight = clWhite;
  cl3DDkShadow = $696969;
  cl3DLight = $E3E3E3;
  clMenuHighlight = $D77800;
  clMenuBar = $F0F0F0;


  clWebAliceblue = $FFF8F0;
  clWebAntiquewhite = $D7EBFA;
  clWebAqua = $FFFF00;
  clWebAquamarine = $D4FF7F;
  clWebAzure = $FFFFF0;
  clWebBeige = $DCF5F5;
  clWebBisque = $C4E4FF;
  clWebBlack = $000000;
  clWebBlanchedalmond = $CDEBFF;
  clWebBlue = $FF0000;
  clWebBlueviolet = $E22B8A;
  clWebBrown = $2A2AA5;
  clWebBurlywood = $87B8DE;
  clWebCadetblue = $A09E5F;
  clWebChartreuse = $00FF7F;
  clWebChocolate = $1E69D2;
  clWebCoral = $507FFF;
  clWebCornflowerblue = $ED9564;
  clWebCornsilk = $DCF8FF;
  clWebCrimson = $3C14DC;
  clWebCyan = $FFFF00;
  clWebDarkblue = $8B0000;
  clWebDarkcyan = $8B8B00;
  clWebDarkgoldenrod = $0B86B8;
  clWebDarkgray = $A9A9A9;
  clWebDarkgreen = $006400;
  clWebDarkgrey = $A9A9A9;
  clWebDarkkhaki = $6BB7BD;
  clWebDarkmagenta = $8B008B;
  clWebDarkolivegreen = $2F6B55;
  clWebDarkorange = $008CFF;
  clWebDarkorchid = $CC3299;
  clWebDarkred = $00008B;
  clWebDarksalmon = $7A96E9;
  clWebDarkseagreen = $8FBC8F;
  clWebDarkslateblue = $8B3D48;
  clWebDarkslategray = $4F4F2F;
  clWebDarkslategrey = $4F4F2F;
  clWebDarkturquoise = $D1CE00;
  clWebDarkviolet = $D30094;
  clWebDeeppink = $9314FF;
  clWebDeepskyblue = $FFBF00;
  clWebDimgray = $696969;
  clWebDimgrey = $696969;
  clWebDodgerblue = $FF901E;
  clWebFirebrick = $2222B2;
  clWebFloralwhite = $F0FAFF;
  clWebForestgreen = $228B22;
  clWebFuchsia = $FF00FF;
  clWebGainsboro = $DCDCDC;
  clWebGhostwhite = $FFF8F8;
  clWebGold = $00D7FF;
  clWebGoldenrod = $20A5DA;
  clWebGray = $808080;
  clWebGreen = $008000;
  clWebGreenyellow = $2FFFAD;
  clWebGrey = $808080;
  clWebHoneydew = $F0FFF0;
  clWebHotpink = $B469FF;
  clWebIndianred = $5C5CCD;
  clWebIndigo = $82004B;
  clWebIvory = $F0FFFF;
  clWebKhaki = $8CE6F0;
  clWebLavender = $FAE6E6;
  clWebLavenderblush = $F5F0FF;
  clWebLawngreen = $00FC7C;
  clWebLemonchiffon = $CDFAFF;
  clWebLightblue = $E6D8AD;
  clWebLightcoral = $8080F0;
  clWebLightcyan = $FFFFE0;
  clWebLightgoldenrodyellow = $D2FAFA;
  clWebLightgray = $D3D3D3;
  clWebLightgreen = $90EE90;
  clWebLightgrey = $D3D3D3;
  clWebLightpink = $C1B6FF;
  clWebLightsalmon = $7AA0FF;
  clWebLightseagreen = $AAB220;
  clWebLightskyblue = $FACE87;
  clWebLightslategray = $998877;
  clWebLightslategrey = $998877;
  clWebLightsteelblue = $DEC4B0;
  clWebLightyellow = $E0FFFF;
  clWebLtGray = $C0C0C0;
  clWebMedGray = $A4A0A0;
  clWebDkGray = $808080;
  clWebMoneyGreen = $C0DCC0;
  clWebLegacySkyBlue = $F0CAA6;
  clWebCream = $F0FBFF;
  clWebLime = $00FF00;
  clWebLimegreen = $32CD32;
  clWebLinen = $E6F0FA;
  clWebMagenta = $FF00FF;
  clWebMaroon = $000080;
  clWebMediumaquamarine = $AACD66;
  clWebMediumblue = $CD0000;
  clWebMediumorchid = $D355BA;
  clWebMediumpurple = $DB7093;
  clWebMediumseagreen = $71B33C;
  clWebMediumslateblue = $EE687B;
  clWebMediumspringgreen = $9AFA00;
  clWebMediumturquoise = $CCD148;
  clWebMediumvioletred = $8515C7;
  clWebMidnightblue = $701919;
  clWebMintcream = $FAFFF5;
  clWebMistyrose = $E1E4FF;
  clWebMoccasin = $B5E4FF;
  clWebNavajowhite = $ADDEFF;
  clWebNavy = $800000;
  clWebOldlace = $E6F5FD;
  clWebOlive = $008080;
  clWebOlivedrab = $238E6B;
  clWebOrange = $00A5FF;
  clWebOrangered = $0045FF;
  clWebOrchid = $D670DA;
  clWebPalegoldenrod = $AAE8EE;
  clWebPalegreen = $98FB98;
  clWebPaleturquoise = $EEEEAF;
  clWebPalevioletred = $9370DB;
  clWebPapayawhip = $D5EFFF;
  clWebPeachpuff = $B9DAFF;
  clWebPeru = $3F85CD;
  clWebPink = $CBC0FF;
  clWebPlum = $DDA0DD;
  clWebPowderblue = $E6E0B0;
  clWebPurple = $800080;
  clWebRed = $0000FF;
  clWebRosybrown = $8F8FBC;
  clWebRoyalblue = $E16941;
  clWebSaddlebrown = $13458B;
  clWebSalmon = $7280FA;
  clWebSandybrown = $60A4F4;
  clWebSeagreen = $578B2E;
  clWebSeashell = $EEF5FF;
  clWebSienna = $2D52A0;
  clWebSilver = $C0C0C0;
  clWebSkyblue = $EBCE87;
  clWebSlateblue = $CD5A6A;
  clWebSlategray = $908070;
  clWebSlategrey = $908070;
  clWebSnow = $FAFAFF;
  clWebSpringgreen = $7FFF00;
  clWebSteelblue = $B48246;
  clWebTan = $8CB4D2;
  clWebTeal = $808000;
  clWebThistle = $D8BFD8;
  clWebTomato = $4763FF;
  clWebTurquoise = $D0E040;
  clWebViolet = $EE82EE;
  clWebWheat = $B3DEF5;
  clWebWhite = $FFFFFF;
  clWebWhitesmoke = $F5F5F5;
  clWebYellow = $00FFFF;
  clWebYellowgreen = $32CD9A;

  clInactiveBorder = $FCF7F4;

  ANSI_CHARSET = 0;
  DEFAULT_CHARSET = 1;
  SYMBOL_CHARSET = 2;
  MAC_CHARSET = 77;
  SHIFTJIS_CHARSET = 128;
  HANGEUL_CHARSET = 129;
  JOHAB_CHARSET = 130;
  GB2312_CHARSET = 134;
  CHINESEBIG5_CHARSET = 136;
  GREEK_CHARSET = 161;
  TURKISH_CHARSET = 162;
  HEBREW_CHARSET = 177;
  ARABIC_CHARSET = 178;
  BALTIC_CHARSET = 186;
  RUSSIAN_CHARSET = 204;
  THAI_CHARSET = 222;
  EASTEUROPE_CHARSET = 238;
  OEM_CHARSET = 255;

type
  TColor = type NativeInt;

  TVerticalAlignment = (taAlignTop, taAlignBottom, taVerticalCenter);

  TPenStyle = (psSolid, psDash, psDot, psDashDot, psDashDotDot, psClear,
    psInsideFrame, psUserStyle, psAlternate);

  TPenMode = (pmBlack, pmWhite, pmNop, pmNot, pmCopy, pmNotCopy,
    pmMergePenNot, pmMaskPenNot, pmMergeNotPen, pmMaskNotPen, pmMerge,
    pmNotMerge, pmMask, pmNotMask, pmXor, pmNotXor);

  TBrushStyle = (bsSolid, bsClear, bsHorizontal, bsVertical,
    bsFDiagonal, bsBDiagonal, bsCross, bsDiagCross);

  TBrushGradient = (bgNone, bgLinearVert, bgLinearHorz, bgRadial);

  TFontStyle = (fsBold, fsItalic, fsStrikeOut, fsUnderline);

  TFontStyles = set of TFontStyle;

  TFontCharset = 0..255;

  TTextFormats = (tfBottom, tfCalcRect, tfCenter, tfEditControl, tfEndEllipsis,
    tfPathEllipsis, tfExpandTabs, tfExternalLeading, tfLeft, tfModifyString,
    tfNoClip, tfNoPrefix, tfRight, tfRtlReading, tfSingleLine, tfTop,
    tfVerticalCenter, tfWordBreak, tfHidePrefix, tfNoFullWidthCharBreak,
    tfPrefixOnly, tfTabStop, tfWordEllipsis, tfComposited);
  TTextFormat = set of TTextFormats;


  TBinaryString = type string;

  TCanvasPointF = record
    X: Double;
    Y: Double;
  end;

  TCanvasRectF = record
    Left, Top, Right, Bottom: Double;
  end;

  TCanvasSizeF = record
    cx: Double;
    cy: Double;
  end;

  TPen = class(TPersistent)
  private
    FWidth: Integer;
    FColor: TColor;
    FStyle: TPenStyle;
    FMode: TPenMode;
    procedure SetColor(const Value: TColor);
  public
    constructor Create; reintroduce;
    procedure Assign(Source: TPersistent); override;
  published
    property Color: TColor read FColor write SetColor;
    property Mode: TPenMode read FMode write FMode default pmCopy;
    property Width: Integer read FWidth write FWidth default 1;
    property Style: TPenStyle read FStyle write FStyle default psSolid;
  end;

  TGradientColor = class(TPersistent)
  private
    FColor: TColor;
    FStop: single;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; reintroduce;
  published
    property Stop: single read FStop write FStop;
    property Color: TColor read FColor write FColor default clNone;
  end;

  TBrush = class(TPersistent)
  private
    FColor: TColor;
    FStyle: TBrushStyle;
    FGradientColors: TList;
    FGradient: TBrushGradient;
  public
    constructor Create; reintroduce;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure AddGradientColor(AColor: TColor; AStop: single);
    procedure GetGradientColor(AIndex: integer; var AColor: TColor; var AStop: single);
    function GradientCount: integer;
    procedure ClearGradient;
  published
    property Gradient: TBrushGradient read FGradient write FGradient;
    property Color: TColor read FColor write FColor;
    property Style: TBrushStyle read FStyle write FStyle;
  end;

  TFont = class(TPersistent)
  private
    FName: string;
    FSize: Integer;
    FColor: TColor;
    FStyle: TFontStyles;
    FOnChange: TNotifyEvent;
    FHeight: Integer;
    FCharset: TFontCharset;
    FOrientation: integer;
    procedure SetHeight(const Value: Integer);
  protected
    procedure SetName(const AName: string);
    procedure SetSize(const ASize: Integer);
    procedure SetColor(const AColor: TColor);
    procedure SetStyle(const AStyle: TFontStyles);
    procedure DoChange; virtual;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; reintroduce;
    function ToString: string; override;
    property Orientation: integer read FOrientation write FOrientation;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Charset: TFontCharset read FCharset write FCharset;
    property Name: string read FName write SetName;
    property Height: Integer read FHeight write SetHeight;
    property Style: TFontStyles read FStyle write SetStyle;
    property Color: TColor read FColor write SetColor;
    property Size: Integer read FSize write SetSize;
  end;

  TImageType = (itBase64, itBMP, itPNG, itJPEG, itGIF, itSVG);

  TCanvas = class;

  TBitmap = class;

  TBitmapLoadedProc = reference to procedure;

  TGraphic = class(TPersistent)
  private
    FAddToQueue: Boolean;
    FCanvasElement: TJSHTMLCanvasElement;
    FEmpty: Boolean;
    FData: string;
    FDataBin: TBinaryString;
    FCanvas: TCanvas;
    FOnChange: TNotifyEvent;
    FImage: TJSObject;
    FBitmap: TBitmap;
    FURL: String;
    FLoaded: TBitmapLoadedProc;
    FUsedCanvas: boolean;
    procedure SetHeight(const Value: Integer);
    procedure SetWidth(const Value: Integer);
    function GetCanvas: TCanvas;
    function GetData: TBinaryString;
    procedure SetData(const Value: TBinaryString);
  protected
    procedure DoChange;
    procedure SetURL(const URL: string);
    function GetWidth: Integer;
    function GetHeight: Integer;
    procedure RecreateCanvas;
    procedure DoBeginScene(Sender: TObject);
    procedure DoEndScene(Sender: TObject);
    procedure AssignEvents;
    procedure CreateImage;
    procedure LoadFromCache(AData: String; ALoaded: TBitmapLoadedProc = nil);
  public
    class function CreateFromResource(AResource: String): TGraphic; overload;
    class function CreateFromResource(AResource: String; AInstance: NativeUInt): TGraphic; overload;
    class function CreateFromURL(AURL: String): TGraphic; overload;
    class function CreateFromURL(AURL: String; AInstance: NativeUInt): TGraphic; overload;
    function Image: TJSObject;
    function Empty: Boolean;
    function GetBase64Image: string;
    function GetAsImage(AType: TImageType = itBase64): string;
    constructor Create(URL: string); overload;
    constructor Create(Img: TJSObject); overload;
    constructor Create; overload; reintroduce;
    property Width: Integer read GetWidth write SetWidth;
    property Height: Integer read GetHeight write SetHeight;
    procedure CaptureCanvas; virtual;
    procedure LoadFromCanvas(ACanvas: TCanvas); virtual;
    procedure SetSize(AWidth, AHeight: Integer);
    procedure Assign(Source: TPersistent); override;
    procedure LoadFromURL(AURL: string; ALoaded: TBitmapLoadedProc = nil); virtual; overload;
    procedure LoadFromURL(AURL: string; AHInstance: Integer; ALoaded: TBitmapLoadedProc = nil); virtual; overload;
    procedure LoadFromFile(AFileName: string; ALoaded: TBitmapLoadedProc = nil); virtual;
    procedure LoadFromResource(AResource: string); virtual; overload;
    procedure LoadFromResource(AResource: string; AHInstance: Integer); virtual; overload;
    procedure LoadFromStream(AStream: TStream);
    property Canvas: TCanvas read GetCanvas;
    property Bitmap: TBitmap read FBitmap;
  published
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property URL: string read FURL write SetURL;
    property Data: TBinaryString read GetData write SetData;
  end;

  TBitmap = class(TGraphic)
  end;

  TMatrix = record
    m11, m12, m13: Single;
    m21, m22, m23: Single;
    m31, m32, m33: Single;
  end;

  TCanvas = class(TObject)
  private
    FElementCanvas: TJSHTMLCanvasElement;
    FContext: TJSCanvasRenderingContext2D;
    FPen: TPen;
    FBrush: TBrush;
    FFont: TFont;
    FPathOpen: boolean;
    FPathX, FPathY: Double;
    FClipRect: TCanvasRectF;
    FOnEndScene: TNotifyEvent;
    FOnBeginScene: TNotifyEvent;
    FApplyPixelRatio: Boolean;
    procedure SetClipRect(const Value: TCanvasRectF);
  protected
    procedure GetAccuOffset(X,Y: double; var dx,dy: single);
    function GetPixel(X,Y: Single): TColor;
    procedure SetPixel(X,Y: Single; Clr: TColor);
    procedure ApplyStroke;
    procedure ApplyFill;
    procedure ApplyBrush(X1,Y1,X2,Y2: single);
    property OnBeginScene: TNotifyEvent read FOnBeginScene write FOnBeginScene;
    property OnEndScene: TNotifyEvent read FOnEndScene write FOnEndScene;
  public
    constructor Create(AControl: TJSHTMLCanvasElement); overload; virtual;
    constructor Create; overload; reintroduce;
    destructor Destroy; override;
    procedure SetTransform(m11, m12, m21, m22, dx, dy: Double);
    procedure Transform(m11, m12, m21, m22, dx, dy: Double);
    procedure Rotate(Angle: Double);
    procedure Translate(X, Y: Double);
    procedure AngleArc(X, Y: Integer; Radius: Cardinal; StartAngle, SweepAngle: Double); overload;
    procedure AngleArc(X, Y: Double; Radius: Double; StartAngle, SweepAngle: Double); overload;
    procedure CopyRect(const DestRect: TRect; Canvas: TCanvas; SourceRect: TRect);
    procedure MoveTo(X, Y: Integer); overload;
    procedure MoveTo(X, Y: Double); overload;
    procedure LineTo(X, Y: Integer); overload;
    procedure LineTo(X, Y: Double); overload;
    procedure Rectangle(X1, Y1, X2, Y2: Double); overload;
    procedure Rectangle(X1, Y1, X2, Y2: Integer); overload;
    procedure Rectangle(const Rect: TRect); overload;
    procedure Rectangle(const Rect: TCanvasRectF); overload;
    procedure RoundRect(X1, Y1, X2, Y2, X3, Y3: Integer); overload;
    procedure RoundRect(X1, Y1, X2, Y2, X3, Y3: Double); overload;
    procedure RoundRect(const Rect: TRect; CX, CY: Integer); overload;
    procedure RoundRect(const Rect: TCanvasRectF; CX, CY: Double); overload;
    procedure FillRect(const Rect: TRect); overload;
    procedure FillRect(const Rect: TCanvasRectF); overload;
    procedure Arc(X1, Y1, X2, Y2, X3, Y3, X4, Y4: Integer);
    procedure Ellipse(X1, Y1, X2, Y2: Integer); overload;
    procedure Ellipse(X1, Y1, X2, Y2: Double); overload;
    procedure Ellipse(const Rect: TRect); overload;
    procedure Ellipse(const Rect: TCanvasRectF); overload;
    procedure Polyline(const Points: array of TPoint); overload;
    procedure Polyline(const Points: array of TCanvasPointF); overload;
    procedure Polygon(const Points: array of TPoint); overload;
    procedure Polygon(const Points: array of TCanvasPointF); overload;
    procedure TextOut(X, Y: Integer; const Text: string); overload;
    procedure TextOut(X, Y: Double; const Text: string); overload;
    procedure Draw(X, Y: Integer; Graphic: TGraphic); overload;
    procedure Draw(X, Y: Double; Graphic: TGraphic); overload;
    procedure StretchDraw(Rect: TRect; Graphic: TGraphic); overload;
    procedure StretchDraw(Rect: TCanvasRectF; Graphic: TGraphic); overload;
    procedure DrawFocusRect(const Rect: TRect); overload;
    procedure DrawFocusRect(const Rect: TCanvasRectF); overload;
    procedure BeginScene;
    procedure EndScene;
    procedure PathBegin;
    procedure PathClose;
    procedure PathStroke;
    procedure PathFill;
    procedure PathMoveTo(X, Y: Integer); overload;
    procedure PathMoveTo(X, Y: Double); overload;
    procedure PathLineTo(X, Y: Integer); overload;
    procedure PathLineTo(X, Y: Double); overload;
    procedure Save;
    procedure Clip;
    procedure Restore;
    procedure Refresh;
    procedure Clear; overload;
    procedure Clear(AColor: TColor); overload;
    function TextExtent(const Text: string): TCanvasSizeF;
    function TextRect(ARect: TRect; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TRect; overload;
    function TextRect(ARect: TCanvasRectF; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TCanvasRectF; overload;
    function TextRect(ARect: TCanvasRectF; const Text: String; TextFormat: TTextFormat): TCanvasRectF; overload;
    function TextWidth(const Text: string): Double; overload;
    function TextHeight(const Text: string): Double; overload;
    function GetBase64Image: string;
    function GetAsImage(AType: TImageType = itBase64): string;
    procedure DownloadImage(AFileName: string; AType: TImageType = itPNG);
    property Element: TJSHTMLCanvasElement read FElementCanvas;
    property Pen: TPen read FPen;
    property Brush: TBrush read FBrush;
    property Font: TFont read FFont;
    property Pixels[X, Y: Single]: TColor read GetPixel write SetPixel;
    property ClipRect: TCanvasRectF read FClipRect write SetClipRect;
    property Context: TJSCanvasRenderingContext2D read FContext;
    property ApplyPixelRatio: Boolean read FApplyPixelRatio write FApplyPixelRatio default False;
  end;

  TJSCSSStyleDeclarationEx = class(TJSCSSStyleDeclaration)
  public
    filter: string;
  end;

  TJSHTMLElementEx = class(TJSHTMLElement)
  private
    FStyle: TJSCSSStyleDeclarationEx; external name 'style';
  public
  property style: TJSCSSStyleDeclarationEx read FStyle write FStyle;
  end;

  TJSCanvasRenderingContext2DEx = class(TJSCanvasRenderingContext2D)
  private
    FFilter: string; external name 'filter';
  public
    property filter: string read FFilter write FFilter;
  end;


  TURLPicture = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FFilename: string;
    FData: TBinaryString;
    FOnDataChange: TNotifyEvent;
    FWidth: integer;
    FHeight: integer;
    procedure SetData(const Value: TBinaryString);
    function GetData: TBinaryString;
  protected
    procedure Changed; virtual;
    procedure DataChanged; virtual;
  public
    procedure LoadFromFile(AFileName: string);
    property Filename: string read FFilename;
    procedure Assign(Source: TPersistent); override;
    property OnDataChange: TNotifyEvent read FOnDataChange write FOnDataChange;
    property Width: integer read FWidth write FWidth;
    property Height: integer read FHeight write FHeight;
  published
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property Data: TBinaryString read GetData write SetData;
  end;


  TThumbnailCreatedProc = reference to procedure(ADataURL: string);

function ColorToRGB(Color: TColor): Longint;
function ColorToHex(c: TColor): string;
function ColorToHTML(c: TColor): string;
function HTMLToColor(s: string): TColor;

function ColorToString(Color: TColor): string;
function StringToColor(const S: string): TColor;
function CharsetToIdent(ACharSet: TFontCharSet): string;
function IdentToCharset(const S: string): TFontCharSet;

function FontSizeToHTML(sz: Double): string;
function HTMLChar(h: string): integer;
function HexToColor(h: string): TColor;
function RGBToColor(argb: string): TColor;
function FontSizeToPx(sz: Double): Double;
function GetRValue(rgb: NativeInt): Byte;
function GetGValue(rgb: NativeInt): Byte;
function GetBValue(rgb: NativeInt): Byte;
function CreateCanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
function CreateCanvasPointF(X, Y: Double): TCanvasPointF;
function CreateCanvasSizeF(cx, cy: Double): TCanvasSizeF;

function CanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
function CanvasPointF(X, Y: Double): TCanvasPointF;
function CanvasSizeF(cx, cy: Double): TCanvasSizeF;

function RGB(r, g, b: Byte): TColor;
function RGBA(r, g, b, a: Byte): TColor;
function CSSFont(Font: TFont): string;
procedure CreateThumbnail(ADataURL: string; AWidth, AHeight: integer; UseAspectRatio: boolean; ThumbnailReady: TThumbnailCreatedProc);

implementation

uses
  WEBLib.Forms, WEBLib.WEBTools, Math, SysUtils, Contnrs;

type
  TGraphicCache = class
  private
    FImage: TJSObject;
    FID: string;
  public
    constructor Create(AImage: TJSObject; AID: string); reintroduce;
    property Image: TJSObject read FImage;
    property ID: string read FID;
  end;

  TGraphicCacheList = class(TObjectList)
  public
    function Find(AID: string; var FImage: TJSObject): Boolean; virtual;
    function Exists(AID: string): Boolean; virtual;
  end;

var
  FCache: TGraphicCacheList;
  FQueue: TStringList;
  FCacheCount: Integer = 0;

function RGB(r, g, b: Byte): TColor;
begin
  Result := (r or (g shl 8) or (b shl 16));
end;

function RGBA(r, g, b, a: Byte): TColor;
begin
  Result := (r or (g shl 8) or (b shl 16)) or (a shl 24);
end;

function MatrixIdentity: TMatrix;
begin
  Result.m11 := 1;
  Result.m12 := 0;
  Result.m13 := 0;
  Result.m21 := 0;
  Result.m22 := 1;
  Result.m23 := 0;
  Result.m31 := 0;
  Result.m32 := 0;
  Result.m33 := 1;
end;

{$HINTS OFF}
function GetPixelRatio(ACanvas: TCanvas): Single;
var
  res: single;
  ca: TCanvas;
begin
  ca := ACanvas;
  asm
    var ctx = ca.FContext,
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;
    res = dpr / bsr
  end;
  Result := res;
end;
{$HINTS ON}

function MatrixCreateScaling(const AScaleX, AScaleY: Single): TMatrix;
begin
  Result := MatrixIdentity;
  Result.m11 := AScaleX;
  Result.m22 := AScaleY;
end;

function MatrixMultiply(const AMatrix1, AMatrix2: TMatrix): TMatrix;
begin
  Result.m11 := AMatrix1.m11 * AMatrix2.m11 + AMatrix1.m12 * AMatrix2.m21 + AMatrix1.m13 * AMatrix2.m31;
  Result.m12 := AMatrix1.m11 * AMatrix2.m12 + AMatrix1.m12 * AMatrix2.m22 + AMatrix1.m13 * AMatrix2.m32;
  Result.m13 := AMatrix1.m11 * AMatrix2.m13 + AMatrix1.m12 * AMatrix2.m23 + AMatrix1.m13 * AMatrix2.m33;
  Result.m21 := AMatrix1.m21 * AMatrix2.m11 + AMatrix1.m22 * AMatrix2.m21 + AMatrix1.m23 * AMatrix2.m31;
  Result.m22 := AMatrix1.m21 * AMatrix2.m12 + AMatrix1.m22 * AMatrix2.m22 + AMatrix1.m23 * AMatrix2.m32;
  Result.m23 := AMatrix1.m21 * AMatrix2.m13 + AMatrix1.m22 * AMatrix2.m23 + AMatrix1.m23 * AMatrix2.m33;
  Result.m31 := AMatrix1.m31 * AMatrix2.m11 + AMatrix1.m32 * AMatrix2.m21 + AMatrix1.m33 * AMatrix2.m31;
  Result.m32 := AMatrix1.m31 * AMatrix2.m12 + AMatrix1.m32 * AMatrix2.m22 + AMatrix1.m33 * AMatrix2.m32;
  Result.m33 := AMatrix1.m31 * AMatrix2.m13 + AMatrix1.m32 * AMatrix2.m23 + AMatrix1.m33 * AMatrix2.m33;
end;

procedure CreateThumbnail(ADataURL: string; AWidth, AHeight: integer; UseAspectRatio: boolean; ThumbnailReady: TThumbnailCreatedProc);
var
  LImg: TJSElement;

begin
  if (AWidth <=0) or (AHeight <= 0) then
    Exit;

  LImg := document.createElement('IMG');

  asm
    LImg.onload = function() {

      var w = LImg.width;
      var h = LImg.height;

      var neww = AWidth;
      var newh = AHeight;

      if (UseAspectRatio) {
        if ((w/neww) > (h/newh))
        {
          newh = h / (w/neww);
        }
        else
        {
          neww = w / (h/newh);
        }
      }
      var LCanvas = document.createElement('CANVAS');
      var ctx = LCanvas.getContext("2d");
      ctx.drawImage(LImg, 0, 0, neww, newh);
      var res = LCanvas.toDataURL("image/png");
      ThumbnailReady(res);
    }
  end;

  LImg['src'] := ADataURL;
end;

function CSSFont(Font: TFont): string;
var
  res,fs: string;
begin
  res := 'font-family:'+ Font.Name+';';
  res := res + 'font-style: normal;';

  if fsBold in Font.Style then
    res := res + 'font-weight: bold;';

  if fsItalic in Font.Style then
    res := res + 'font-style: italic;';

  fs := '';

  if fsUnderline in Font.Style then
    fs := fs + ' underline';

  if fsStrikeOut in Font.Style then
     fs:= fs + ' line-through';

  if (fs <> '') then
    res := res + 'text-decoration:' + fs+';';

  res := res + 'font-size:'+ IntToStr(Font.Size) + 'pt;';

  Result := res;
end;


function CreateCanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
begin
  Result.Left := Left;
  Result.Top := Top;
  Result.Right := Right;
  Result.Bottom := Bottom;
end;

function CreateCanvasPointF(X, Y: Double): TCanvasPointF;
begin
  Result.X := X;
  Result.Y := Y;
end;

function CreateCanvasSizeF(cx, cy: Double): TCanvasSizeF;
begin
  Result.cx := cx;
  Result.cy := cy;
end;

function CanvasRectF(Left, Top, Right, Bottom: Double): TCanvasRectF;
begin
  Result := CreateCanvasRectF(Left, Top, Right, Bottom);
end;

function CanvasPointF(X, Y: Double): TCanvasPointF;
begin
  Result := CreateCanvasPointF(X,Y);
end;

function CanvasSizeF(cx, cy: Double): TCanvasSizeF;
begin
  Result := CreateCanvasSizeF(cx, cy);
end;


function GetRValue(rgb: NativeInt): Byte;
begin
  Result := Byte(rgb and $FF);
end;

function GetGValue(rgb: NativeInt): Byte;
begin
  Result := Byte((rgb shr 8) and $FF);
end;

function GetBValue(rgb: NativeInt): Byte;
begin
  Result := Byte((rgb shr 16) and $FF);
end;

function ColorToRGB(Color: TColor): Longint;
begin
  Result := Color;
end;

function ColorToHex(c: TColor): string;
var
  s: string;
begin
  asm
    s = c.toString(16);

    while (s.length < 6)
    {
      s = "0" + s;
    }
  end;

  Result := Copy(s,5,2) + Copy(s,3,2) + Copy(s,1,2);
end;

function ColorToHTML(c: TColor): string;
begin
  if c and $FF000000 <> 0 then // it contains alpha part
  begin
    Result := '#' + ColorToHex(c and $FFFFFF) + IntToHex(((c and $FF000000) shr 24) and $FF,2);
  end
  else
    Result := '#' + ColorToHex(c);
end;

function HTMLToColor(s: string): TColor;
begin
  if pos('#',s) = 1 then
    Delete(s,1,1);

  Result := HexToColor(s);
end;

function ColorToVCL(c: TColor): string;
var
  s: string;
begin
  asm
    s = c.toString(16);
    while (s.length < 6)
    {
      s = "0" + s;
    }
  end;
  Result := Copy(s,1,2) + Copy(s,3,2) + Copy(s,5,2);
end;


function ColorToString(Color: TColor): string;
begin
  case Color of
  clNone: Result := 'clNone';
  clBlack: Result := 'clBlack';
  clMaroon: Result := 'clMaroon';
  clGreen: Result := 'clGreen';
  clOlive: Result := 'clOlive';
  clNavy: Result := 'clNavy';
  clPurple: Result := 'clPurple';
  clTeal: Result := 'clTeal';
  clGray: Result := 'clGray';
  clSilver: Result := 'clSilver';
  clRed: Result := 'clRed';
  clLime: Result := 'clLime';
  clYellow: Result := 'clYellow';
  clBlue: Result := 'clBlue';
  clFuchsia: Result := 'clFuchsia';
  clAqua: Result := 'clAqua';
  clWhite: Result := 'clWhite';
  clLtGray: Result := 'clLtGray';
  clDkGray: Result := 'clDkGray';
  clDefault: Result := 'clDefault';
  clBtnFace: Result := 'clBtnFace';
  clWindowText: Result := 'clWindowText';
  clWindow: Result := 'clWindow';
  clHighlight: Result := 'clHighlight';
  clHighlightText: Result :='clHighlightText';
  clInfoText: Result :='clInfoText';
  clInfoBk: Result := 'clInfoBk';
  clActiveCaption: Result := 'clActiveCaption';
  clInactiveCaption: Result := 'clInactiveCaption';
  clHotLight: Result := 'clHotLight'
  else
    Result := '$' + ColorToVCL(Color);
  end;
end;

function StringToColor(const S: string): TColor;
var
  us: string;
  clr: integer;
begin
  us := Uppercase(s);
  if (us = 'CLNONE') then Result := clNone else
  if (us = 'CLBLACK') then Result := clBlack else
  if (us = 'CLMAROON') then Result := clMaroon else
  if (us = 'CLGREEN') then Result := clGreen else
  if (us = 'CLOLIVE') then Result := clOlive else
  if (us = 'CLNAVY') then Result := clNavy else
  if (us = 'CLPURPLE') then Result := clPurple else
  if (us = 'CLTEAL') then Result := clTeal else
  if (us = 'CLGRAY') then Result := clGray else
  if (us = 'CLSILVER') then Result := clSilver else
  if (us = 'CLRED') then Result := clRed else
  if (us = 'CLLIME') then Result := clLime else
  if (us = 'CLYELLOW') then Result := clYellow else
  if (us = 'CLBLUE') then Result := clBlue else
  if (us = 'CLFUCHSIA') then Result := clFuchsia else
  if (us = 'CLAQUA') then Result := clAqua else
  if (us = 'CLWHITE') then Result := clWhite else
  if (us = 'CLLTGRAY') then Result := clLtGray else
  if (us = 'CLDKGRAY') then Result := clDkGray else
  if (us = 'CLDEFAULT') then Result := clDefault else
  if (us = 'CLBTNFACE') then Result := clBtnFace else
  if (us = 'CLWINDOWTEXT') then Result := clWindowText else
  if (us = 'CLWINDOW') then Result := clWindow else
  if (us = 'CLHIGHLIGHT') then Result := clHighlight else
  if (us = 'CLHIGHLIGHTTEXT') then Result := clWhite else
  if (us = 'CLINFOTEXT') then Result := clInfoText else
  if (us = 'CLINFOBK') then Result := clInfoBk else
  if (us = 'CLACTIVECAPTION') then Result := clActiveCaption else
  if (us = 'CLINACTIVECAPTION') then Result := clInactiveCaption else
  if (us = 'CLHOTLIGHT') then Result := clHotLight else
  begin
    clr := HexToColor(us);

    Result := ((clr and $FF) shl 16) or (clr and $FF00) or ((clr and $FF0000) shr 16) or (clr and $FF000000);
  end;
end;


function RGBToColor(argb: string): TColor;
var
  r,g,b: string;
  ri,gi,bi: byte;
  e: integer;
begin
  Result := clNone;

  argb := Trim(argb);

  if pos('RGB', uppercase(argb)) > 0  then
  begin
    delete(argb,1,4);

    r := copy(argb,1, pos(',', argb) -1);
    delete(argb, 1, pos(',', argb) + 1);

    g := copy(argb,1, pos(',', argb) -1);
    delete(argb, 1, pos(',', argb) + 1);

    b := copy(argb,1, pos(')', argb) -1);

    val(r, ri, e);
    val(g, gi, e);
    val(b, bi, e);

    Result := RGB(ri,gi,bi);
  end;
end;

function HexToColor(h: string): TColor;
var
  s: string;
  i: integer;
begin
  s := '';
  for i := 1 to Length(h) do
  begin
    // accept hex chars
    if ((h[i] >='0') and (h[i] <= '9')) or ((h[i] >='A') and (h[i] <= 'F') or ((h[i] >='a') and (h[i] <= 'f'))) then
      s := s + h[i];
  end;

  if length(s) > 6 then
    s := '$' + Copy(s,1,2) + Copy(s,7,2) + Copy(s,5,2) + Copy(s,3,2)
  else
    s := '$' + Copy(s,5,2) + Copy(s,3,2) + Copy(s,1,2);

  Result := StrToInt64(s);
end;

function HTMLChar(h: string): integer;
begin
  // fmt &#xABCD;
  Delete(h,1,3);
  Delete(h,Length(h) - 1, 1);
  Result := StrToInt('0x' + h);
end;

function FontSizeToHTML(sz: Double): string;
begin
  Result := FloatToStr(sz)+'px';
  Result := StringReplace(Result, ',', '.', [rfReplaceAll]);
end;


function CharSetToIdent(ACharSet: TFontCharSet): string;
begin
  case ACharSet of
  ANSI_CHARSET: Result := 'ANSI_CHARSET';
  DEFAULT_CHARSET: Result := 'DEFAULT_CHARSET';
  SYMBOL_CHARSET: Result := 'SYMBOL_CHARSET';
  MAC_CHARSET: Result := 'MAC_CHARSET';
  SHIFTJIS_CHARSET: Result := 'SHIFTJIS_CHARSET';
  HANGEUL_CHARSET: Result := 'HANGEUL_CHARSET';
  JOHAB_CHARSET: Result := 'JOHAB_CHARSET';
  GB2312_CHARSET: Result := 'GB2312_CHARSET';
  CHINESEBIG5_CHARSET: Result := 'CHINESEBIG5_CHARSET';
  GREEK_CHARSET: Result := 'GREEK_CHARSET';
  TURKISH_CHARSET: Result := 'TURKISH_CHARSET';
  HEBREW_CHARSET: Result := 'HEBREW_CHARSET';
  ARABIC_CHARSET: Result := 'ARABIC_CHARSET';
  BALTIC_CHARSET: Result := 'BALTIC_CHARSET';
  RUSSIAN_CHARSET: Result := 'RUSSIAN_CHARSET';
  THAI_CHARSET: Result := 'THAI_CHARSET';
  EASTEUROPE_CHARSET: Result := 'EASTEUROPE_CHARSET';
  OEM_CHARSET: Result := 'OEM_CHARSET';
  end;
end;

function IdentToCharSet(const S: string): TFontCharSet;
begin
  if S = 'ANSI_CHARSET' then Result := ANSI_CHARSET;
  if S = 'DEFAULT_CHARSET' then Result := DEFAULT_CHARSET;
  if S = 'SYMBOL_CHARSET' then Result := SYMBOL_CHARSET;
  if S = 'MAC_CHARSET' then Result := MAC_CHARSET;
  if S = 'SHIFTJIS_CHARSET' then Result := SHIFTJIS_CHARSET;
  if S = 'HANGEUL_CHARSET' then Result := HANGEUL_CHARSET;
  if S = 'JOHAB_CHARSET' then Result := JOHAB_CHARSET;
  if S = 'GB2312_CHARSET' then Result := GB2312_CHARSET;
  if S = 'CHINESEBIG5_CHARSET' then Result := CHINESEBIG5_CHARSET;
  if S = 'GREEK_CHARSET' then Result := GREEK_CHARSET;
  if S = 'TURKISH_CHARSET' then Result := TURKISH_CHARSET;
  if S = 'HEBREW_CHARSET' then Result := HEBREW_CHARSET;
  if S = 'ARABIC_CHARSET' then Result := ARABIC_CHARSET;
  if S = 'BALTIC_CHARSET' then Result := BALTIC_CHARSET;
  if S = 'RUSSIAN_CHARSET' then Result := RUSSIAN_CHARSET;
  if S = 'THAI_CHARSET' then Result := THAI_CHARSET;
  if S = 'EASTEUROPE_CHARSET' then Result := EASTEUROPE_CHARSET;
  if S = 'OEM_CHARSET' then Result := OEM_CHARSET;
end;

{ TPen }

procedure TPen.Assign(Source: TPersistent);
begin
  if (Source is TPen) then
  begin
    FColor := (Source as TPen).Color;
    FStyle := (Source as TPen).Style;
    FWidth := (Source as TPen).Width;
  end
  else
    inherited;
end;

constructor TPen.Create;
begin
  FColor := clBlack;
  FWidth := 1;
  FStyle := psSolid;
  FMode := pmCopy;
end;

procedure TPen.SetColor(const Value: TColor);
begin
  FColor := Value;
end;

{ TBrush }

procedure TBrush.AddGradientColor(AColor: TColor; AStop: single);
var
  gr: TGradientColor;
begin
  gr := TGradientColor.Create;
  gr.Color := AColor;
  gr.Stop := AStop;
  FGradientColors.Add(gr);
end;

procedure TBrush.Assign(Source: TPersistent);
begin
  if (Source is TBrush) then
  begin
    FColor := (Source as TBrush).Color;
    FStyle := (Source as TBrush).Style;
  end
  else
    inherited;
end;

function FontSizeToPx(sz: Double): Double;
begin
  Result := sz * 96 / 72;
end;

procedure TBrush.ClearGradient;
var
  i: integer;
  gr: TGradientColor;
begin
  for i := FGradientColors.Count - 1 downto 0 do
  begin
    gr := TGradientColor(FGradientColors[i]);
    gr.Free;
    FGradientColors.Delete(i);
  end;
end;

constructor TBrush.Create;
begin
  FColor := clWhite;
  FStyle := bsSolid;
  FGradient := bgNone;
  FGradientColors := TList.Create;
end;

destructor TBrush.Destroy;
begin
  FGradientColors.Free;
  inherited;
end;

procedure TBrush.GetGradientColor(AIndex: integer; var AColor: TColor;
  var AStop: single);
begin
  if AIndex < FGradientColors.Count then
  begin
    AColor := TGradientColor(FGradientColors[AIndex]).Color;
    AStop := TGradientColor(FGradientColors[AIndex]).Stop;
  end
  else
    raise Exception.Create('Invalid gradient color index');
end;

function TBrush.GradientCount: integer;
begin
  Result := FGradientColors.Count;
end;

{ TFont }

procedure TFont.Assign(Source: TPersistent);
begin
  if (Source is TFont) then
  begin
    FName := (Source as TFont).Name;
    FColor := (Source as TFont).Color;
    FSize := (Source as TFont).Size;
    FStyle := (Source as TFont).Style;
    DoChange;
  end
  else
    inherited;
end;

function TFont.ToString: string;
var
  s: string;
begin
  s := '';
  if (TFontStyle.fsBold in Style) and (TFontStyle.fsItalic in Style) then
    s := s + 'bold italic'
  else if TFontStyle.fsBold in Style then
    s := s + 'bold'
  else if TFontStyle.fsItalic in Style then
    s := s + 'italic';

  if Size <> 0 then
    Result := s + ' ' + FontSizeToHTML(FontSizeToPx(Size)) + ' ' + Name
  else
    Result := s + ' ' + FontSizeToHTML(FontSizeToPx(12)) + ' ' + Name
end;

procedure TFont.SetName(const AName: string);
begin
  if (FName <> AName) then
  begin
    FName := AName;
    DoChange;
  end;
end;

procedure TFont.SetSize(const ASize: Integer);
begin
  if (FSize <> ASize) then
  begin
    FSize := ASize;
    DoChange;
  end;
end;

procedure TFont.SetColor(const AColor: TColor);
begin
  if (FColor <> AColor) then
  begin
    FColor := AColor;
    DoChange;
  end;
end;

procedure TFont.SetHeight(const Value: Integer);
var
  d: double;
begin
  d := -Value * 72/96;
  FSize := Round(d);

  if (FHeight <> Value) then
  begin
    FHeight := Value;
    DoChange;
  end;
end;

procedure TFont.SetStyle(const AStyle: TFontStyles);
begin
  if (FStyle <> AStyle) then
  begin
    FStyle := AStyle;
    DoChange;
  end;
end;

constructor TFont.Create;
begin
  inherited;
  FName := WEBDEFAULTFONT;
  FSize := 8;
  FHeight := -11;
  FStyle := [];
  FColor := clBlack;
end;

procedure TFont.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TCanvas }

procedure TCanvas.AngleArc(X, Y, Radius: Double; StartAngle,
  SweepAngle: Double);
begin
  if Assigned(FContext) then
  begin
    FContext.beginPath();
    ApplyStroke;
    FContext.arc(X, Y, Radius, StartAngle, StartAngle + SweepAngle);
    FContext.stroke();
  end;
end;

procedure TCanvas.ApplyFill;
begin
  if Assigned(FContext) then
    FContext.fillStyle := ColorToHtml(Brush.Color);
end;

procedure TCanvas.ApplyStroke;
begin
  if Assigned(FContext) then
  begin
    FContext.lineWidth := Pen.Width;
    FContext.strokeStyleAsColor := ColorToHtml(Pen.Color);
    case Pen.Style of
      psSolid: FContext.setlinedash([]);
      psDot: FContext.setlinedash([1,2]);
      psDash: FContext.setlinedash([8,2]);
      psDashDot: FContext.setlinedash([6,2,2,2]);
      psDashDotDot: FContext.setlinedash([6,2,2,2,2,2]);
      psClear: FContext.setlinedash([0, $FFFF]);
    end;
  end;
end;

function AngleOfPoints(x1,y1,x2,y2:double):double;
var
  part1, part2:double;
  angle:double;
begin
  if (x1=x2) and (y1=y2) then
  begin
    Result:=0.0;
    Exit;
  end;

  part1 := abs(y2 - y1);
  if (part1=0) then
  begin
    part1:=0.0000001;
    y1 := y1+0.0000001;
  end;

  part2 := abs(x2- x1);
  if (part2=0) then
  begin
    part2:=0.0000001;
    x1 := x1 + 0.0000001;
  end;

  angle := arctan(part1/part2);

  if ((x1>x2) and (y1<y2)) then angle:=PI-angle;
  if ((x1>x2) and (y1>y2)) then angle:=angle +PI;
  if ((x1<x2) and (y1>y2)) then angle:=2*PI-angle;

  while angle>= 2*PI do
    angle := angle - 2*PI;
  while angle < 0 do
   angle := angle + 2*PI;
  Result := angle;
end;

procedure TCanvas.Arc(X1, Y1, X2, Y2, X3, Y3, X4, Y4: Integer);
var
  rx,ry: integer;
  angleStart,angleEnd: double;
begin
  if Assigned(FContext) then
  begin
    rx := (X2-X1) div 2;
    ry := (Y2-Y1) div 2;

    angleStart := AngleOfPoints(X1+rx,Y1+ry,X4,Y4);
    angleEnd := AngleOfPoints(X1+rx,Y1+ry,X3,Y3);

    FContext.beginPath();
    ApplyStroke;
    FContext.ellipse(X1+rx,Y1+ry,rx,ry,0,angleStart,angleEnd);
    FContext.stroke();
  end;
end;

procedure TCanvas.PathBegin;
begin
  if Assigned(FContext) then
    FContext.beginPath();
end;

procedure TCanvas.BeginScene;
begin
  if Assigned(OnBeginScene) then
    OnBeginScene(Self);
end;

procedure TCanvas.Clear;
begin
  if Assigned(FContext) and Assigned(FElementCanvas) then
    FContext.clearRect(0, 0, FElementCanvas.width, FElementCanvas.height);
end;

procedure TCanvas.Clear(AColor: TColor);
var
  c: TColor;
  s: TBrushStyle;
begin
  Clear;
  if Assigned(FElementCanvas) then
  begin
    c := Brush.Color;
    s := Brush.Style;
    Brush.Color := AColor;
    Brush.Style := bsSolid;
    FillRect(Rect(0, 0, FElementCanvas.width, FElementCanvas.height));
    Brush.Color := c;
    Brush.Style := s;
  end;
end;

procedure TCanvas.Clip;
begin
  if Assigned(FContext) then
    FContext.clip;
end;

procedure TCanvas.CopyRect(const DestRect: TRect; Canvas: TCanvas;
  SourceRect: TRect);
var
  sw,sh: integer;
  dw,dh: integer;
  imgData: TJSImageData;
  ACanvas: TCanvas;
begin
  if Assigned(FContext) then
  begin
    sw := SourceRect.Right - SourceRect.Left;
    sh := SourceRect.Bottom - SourceRect.Top;

    dw := DestRect.Right - DestRect.Left;
    dh := DestRect.Bottom - DestRect.Top;

    imgData := FContext.getImageData(SourceRect.Left, SourceRect.Top, sw, sh);

    ACanvas := TCanvas.Create;
    try
      ACanvas.FElementCanvas.width := sw;
      ACanvas.FElementCanvas.height := sh;

      ACanvas.FContext.putImageData(imgData, 0,0);
      Canvas.FContext.drawImage(ACanvas.FElementCanvas, DestRect.Left, DestRect.Top, dw, dh);
    finally
      ACanvas.Free;
    end;
  end;
end;

constructor TCanvas.Create;
begin
  FElementCanvas := TJSHTMLCanvasElement(document.createElement('CANVAS'));
  Create(FElementCanvas);
end;

constructor TCanvas.Create(AControl: TJSHTMLCanvasElement);
begin
  FElementCanvas := AControl;

  //FContext := AControl.getContextAs2DContext('2d');
  asm
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      this.FContext = AControl.getContext('2d');
    } else {
      this.FContext = AControl.getContext('2d',{ willReadFrequently: true});
    }
  end;

  FPen := TPen.Create;
  FBrush := TBrush.Create;
  FPathOpen := false;
  FFont := TFont.Create;
  FApplyPixelRatio := False;
end;

procedure TCanvas.AngleArc(X, Y: Integer; Radius: Cardinal; StartAngle, SweepAngle: Double);
begin
  AngleArc(Double(X), Double(Y), Double(Radius), Double(StartAngle), Double(SweepAngle));
end;

procedure TCanvas.MoveTo(X,Y: Integer);
begin
  MoveTo(Double(X), Double(Y));
end;

procedure TCanvas.LineTo(X,Y: Integer);
begin
  LineTo(Double(X), Double(Y));
end;

procedure TCanvas.Rectangle(const Rect: TRect);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

{$HINTS OFF}
procedure TCanvas.ApplyBrush(X1, Y1, X2, Y2: Double);
var
  dx,dy,p: single;
  gr: TJSCanvasGradient;
  i: integer;
  clr: string;
  AColor: TColor;
  AStop: single;
begin
  GetAccuOffset(X1,Y1,dx,dy);

  if Brush.Style in [bsHorizontal, bsCross] then
  begin
    p := Y1 + dy;
    FContext.lineWidth := 1;
    FContext.strokeStyleAsColor := ColorToHtml(Brush.Color);
    while p < Y2 do
    begin
      FContext.moveTo(x1,p);
      FContext.lineTo(x2,p);
      p := p + 8;
    end;
  end;

  if Brush.Style in [bsVertical, bsCross] then
  begin
    p := X1 + dx;
    FContext.lineWidth := 1;
    FContext.strokeStyleAsColor := ColorToHtml(Brush.Color);
    while p < X2 do
    begin
      FContext.moveTo(p,y1);
      FContext.lineTo(p,y2);
      p := p + 8;
    end;
  end;

  if Brush.Style in [bsFDiagonal, bsDiagCross] then
  begin
    p := X1 + dx - (X2 - X1);
    FContext.lineWidth := 1;
    FContext.strokeStyleAsColor := ColorToHtml(Brush.Color);
    while p < X2 do
    begin
      FContext.moveTo(p,y1);
      FContext.lineTo(p + (y2-y1),y2);
      p := p + 8;
    end;
  end;

  if Brush.Style in [bsBDiagonal, bsDiagCross] then
  begin
    p := X1 + dx;
    FContext.lineWidth := 1;
    FContext.strokeStyleAsColor := ColorToHtml(Brush.Color);
    while p < X2 + (X2 - X1) do
    begin
      FContext.moveTo(p,y1);
      FContext.lineTo(p - (y2-y1),y2);
      p := p + 8;
    end;
  end;

  if Brush.Gradient <> bgNone then
  begin
    case Brush.Gradient of
    bgLinearHorz: gr := FContext.createLinearGradient(X1, Y1, X2, Y1);
    bgLinearVert: gr := FContext.createLinearGradient(X1, Y1, X1, Y2);
    bgRadial: gr :=  FContext.createRadialGradient(X1 + (X2-X1)/2, Y1+ (Y2 - Y1)/2, 0, X1 + (X2-X1)/2, Y1+ (Y2 - Y1)/2, (X2-X1)/2);
    end;

    clr := ColorToHtml(ColorToRGB(Brush.Color));
    asm
      gr.addColorStop(0,clr);
    end;

    for i := 0 to Brush.GradientCount - 1 do
    begin
      Brush.GetGradientColor(i, AColor, AStop);
      clr := ColorToHtml(ColorToRGB(AColor));
      asm
        gr.addColorStop(AStop,clr);
      end;
    end;

    FContext.fillStyle := gr;
    Exit;
  end;

  FContext.stroke();
end;
{$HINTS ON}

procedure TCanvas.Rectangle(X1, Y1, X2, Y2: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FContext.save;
    FContext.beginPath();
    ApplyStroke;
    ApplyFill;

    GetAccuOffset(X1,Y1,dx,dy);

    asm
      let region = new Path2D();
      region.rect(X1, Y1, X2- X1 + 1, Y2 - Y1 + 1);
      this.FContext.clip(region, "evenodd");
    end;

    ApplyBrush(X1,Y1,X2,Y2);

    if not (Brush.Style in [bsClear, bsHorizontal, bsVertical, bsCross, bsFDiagonal, bsBDiagonal, bsDiagCross]) then
    begin
      FContext.rect(X1+dx, Y1+dy, X2 - X1, Y2 - Y1);
      FContext.fill();
    end;

    if Pen.Style <> psClear then
    begin
      ApplyStroke;
      FContext.beginPath;
      FContext.rect(X1+dx, Y1+dy, X2 - X1, Y2 - Y1);
      FContext.stroke();
    end;

    FContext.restore;
  end;
end;

procedure TCanvas.Rectangle(const Rect: TCanvasRectF);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Refresh;
begin
end;

procedure TCanvas.Restore;
begin
  if Assigned(FContext) then
    FContext.restore();
end;

procedure TCanvas.Rectangle(X1, Y1, X2, Y2: Integer);
begin
  Rectangle(Double(X1), Double(Y1), Double(X2), Double(Y2));
end;

procedure TCanvas.FillRect(const Rect: TRect);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.RoundRect(X1,Y1,X2,Y2,X3,Y3: Integer);
begin
  RoundRect(Double(X1), Double(Y1), Double(X2), Double(Y2), Double(X3), Double(Y3));
end;

procedure TCanvas.RoundRect(const Rect: TRect; CX, CY: Integer);
begin
  RoundRect(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom, CX, CY);
end;

procedure TCanvas.RoundRect(X1, Y1, X2, Y2, X3, Y3: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FContext.beginPath();
    ApplyStroke;
    ApplyFill;

    GetAccuOffset(X1, Y1, dx, dy);

    FContext.moveTo(X1 + X3/2, Y1 + dy);
    FContext.lineTo(X2 - X3/2, Y1 + dy);
    FContext.quadraticCurveTo(X2 + dx,Y1 + dy, X2 + dx, Y1 + dy + Y3/2);

    FContext.lineTo(X2 + dx, Y2 - Y3/2 );
    FContext.quadraticCurveTo(X2 + dx, Y2 + dy, X2 - X3/2 - dx, Y2 + dy);
    FContext.lineTo(X1 + X3/2, Y2 + dy);
    FContext.quadraticCurveTo(X1 + dx, Y2 + dy, X1 + dx, Y2 - Y3/2);
    FContext.lineTo(X1 + dx, Y1 + Y3/2 + dy);
    FContext.quadraticCurveTo(X1 + dx, Y1 + dy, X1 + X3/2 + dx, Y1 + dy);

    if Brush.Style = bsSolid then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();

    FContext.save;

    asm
      let region = new Path2D();
      region.moveTo(X1 + X3/2, Y1 + dy);
      region.lineTo(X2 - X3/2, Y1 + dy);
      region.quadraticCurveTo(X2 + dx,Y1 + dy, X2 + dx, Y1 + dy + Y3/2);
      region.lineTo(X2 + dx, Y2 - Y3/2 );
      region.quadraticCurveTo(X2 + dx, Y2 + dy, X2 - X3/2 - dx, Y2 + dy);
      region.lineTo(X1 + X3/2, Y2 + dy);
      region.quadraticCurveTo(X1 + dx, Y2 + dy, X1 + dx, Y2 - Y3/2);
      region.lineTo(X1 + dx, Y1 + Y3/2 + dy);
      region.quadraticCurveTo(X1 + dx, Y1 + dy, X1 + X3/2 + dx, Y1 + dy);
      this.FContext.clip(region, "evenodd");
    end;

    ApplyBrush(X1,Y1,X2,Y2);

    FContext.restore;
  end;
end;

procedure TCanvas.Ellipse(X1, Y1, X2, Y2: Integer);
begin
  Ellipse(Double(X1), Double(Y1), Double(X2), Double(Y2));
end;

procedure TCanvas.Ellipse(const Rect: TRect);
begin
  Ellipse(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Polyline(const Points: array of TPoint);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    if l = 0 then
      Exit;

    FContext.beginPath();
    ApplyStroke;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Polygon(const Points: array of TPoint);
var
  l, i: Integer;
  x1,y1,x2,y2: integer;

begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    if l = 0 then
      Exit;

    FContext.beginPath();
    ApplyStroke;

    x1 := $FFFF;
    y1 := $FFFF;
    x2 := 0;
    y2 := 0;
    for i := 0 to Length(Points) - 1 do
    begin
      if Points[i].X < x1 then
        x1 := Points[i].X;
      if Points[i].Y < y1 then
        y1 := Points[i].Y;

      if Points[i].X > x2 then
        x2 := Points[i].X;
      if Points[i].Y > y2 then
        y2 := Points[i].Y;
    end;

    if Brush.Gradient <> bgNone then
    begin
      ApplyBrush(x1,y1,x2,y2);
    end
    else
      ApplyFill;

    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    FContext.closePath();

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

function TCanvas.TextExtent(const Text: string): TCanvasSizeF;
begin
  Result.cx := TextWidth(Text);
  Result.cy := TextHeight(Text);
end;

function TCanvas.TextHeight(const Text: string): Double;
begin
  Result := FontSizeToPx(Font.Size);
end;

procedure TCanvas.MoveTo(X, Y: Double);
var
  dx,dy: single;
begin
  if Assigned(FContext) then
  begin
    FPathOpen := true;
    FContext.beginPath();
    ApplyStroke;
    dx := 0;
    dy := 0;
    GetAccuOffset(X, Y, dx, dy);
    FContext.moveTo(X + dx,Y + dy);
  end;
end;

procedure TCanvas.TextOut(X, Y: Double; const Text: string);
var
  tm: TJSTextMetrics;
  DrwText: string;
begin
  if Assigned(FContext) then
  begin
    // remove #0 chars
    asm
      DrwText = Text.replace('\0','');
    end;

    FContext.fillStyle := ColorToHtml(Font.Color);
    FContext.font := Font.ToString;
    FContext.textBaseline := 'top';
    FContext.fillText(DrwText, X, Y);

    if fsUnderline in Font.Style then
    begin
      tm := FContext.measureText(DrwText);
      FContext.fillRect(X, Y + Font.Size *1.4, tm.width, 1);
    end;

    if fsStrikeOut in Font.Style then
    begin
      tm := FContext.measureText(DrwText);
      FContext.fillRect(X, Y + Font.Size *0.7, tm.width, 1);
    end;

  end;
end;

function TCanvas.TextRect(ARect: TCanvasRectF; const Text: String; TextFormat: TTextFormat): TCanvasRectF;
var
  ha: TAlignment;
  va: TVerticalAlignment;
begin
  ha := taLeftJustify;
  va := taAlignTop;

  if tfCenter in TextFormat then
    ha := taCenter
  else
  if tfRight in TextFormat then
    ha := taRightJustify;

  if tfVerticalCenter in TextFormat then
    va := taVerticalCenter
  else
  if tfBottom in TextFormat then
    va := taAlignBottom;


//   (tfBottom, tfCalcRect, tfCenter, tfEditControl, tfEndEllipsis,
//    tfPathEllipsis, tfExpandTabs, tfExternalLeading, tfLeft, tfModifyString,
//    tfNoClip, tfNoPrefix, tfRight, tfRtlReading, tfSingleLine, tfTop,
//    tfVerticalCenter, tfWordBreak, tfHidePrefix, tfNoFullWidthCharBreak,
//    tfPrefixOnly, tfTabStop, tfWordEllipsis, tfComposited);

  Result := TextRect(ARect, Text, tfWordBreak in TextFormat, tfCalcRect in TextFormat, ha, va);
end;

function TCanvas.TextRect(ARect: TCanvasRectF; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TCanvasRectF;
var
  i: Integer;
  s, sn, st: string;
  l: Integer;
  w, mw: Double;
  f: Boolean;
  p: Integer;
  tw: Double;
  th: Double;
  lcnt: Integer;
  rs: TCanvasRectF;
  fws: Double;
  ths: Double;
  ww, wwx, fx: Boolean;

  procedure DrawText(AText: string; AWidth, AHeight: Single);
  begin
    if ww then
    begin
      case AHorizontalAlignment of
        taCenter: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top, AText);
        taLeftJustify: TextOut(ARect.Left, ARect.Top, AText);
        taRightJustify: TextOut(ARect.Right - AWidth, ARect.Top, AText);
      end;
    end
    else
    begin
      case AHorizontalAlignment of
        taCenter:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Left + (ARect.Right - ARect.Left - AWidth) / 2, ARect.Bottom - AHeight, AText);
          end;
        end;
        taLeftJustify:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Left, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Left, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Left, ARect.Bottom - AHeight, AText);
          end;
        end;
        taRightJustify:
        begin
          case AVerticalAlignment of
            taAlignTop: TextOut(ARect.Right - AWidth, ARect.Top, AText);
            taVerticalCenter: TextOut(ARect.Right - AWidth, ARect.Top + (ARect.Bottom - ARect.Top - AHeight) / 2, AText);
            taAlignBottom: TextOut(ARect.Right - AWidth, ARect.Bottom - AHeight, AText);
          end;
        end;
      end;
    end;
  end;

  function FindNextWord(Text: String; var APos: Integer): String;
  var
    l: Integer;
    i: Integer;
  begin
    Result := '';

    l := Length(Text);
    if APos > l then
      Exit;

    i := APos;
    while True do
    begin
      if ((Text[i] = #10) and (Text[i - 1] = #13)) or ((Text[i] = #13) and (Text[i - 1] = #10)) or (Text[i] = ' ') then
      begin
        if Text[i] = ' ' then
          Result := Copy(Text, APos, i - (APos - 1))
        else
          Result := Copy(Text, APos, i - APos);

        Break;
      end
      else if (Text[i] = #10) or (Text[i] = #13) or (Text[i] = ' ') then
      begin
        result := Copy(Text, APos, i - (APos - 1));
        Break;
      end
      else if i >= l then
      begin
        result := Copy(Text, APos, i - (APos - 1));
        Break;
      end
      else
        inc(i);
    end;

    APos := i + 1;
  end;
begin
  ww := WordWrap or (Pos(#13, Text) > 0) or (Pos(#10, Text) > 0);
  wwx := not WordWrap and ((Pos(#13, Text) > 0) or (Pos(#10, Text) > 0));

  if not ww then
  begin
    w := TextWidth(Text);
    th := TextHeight(Text);
    if not Calculate then
      DrawText(Text, w, th);

    Result := CreateCanvasRectF(ARect.Left, ARect.Top, ARect.Left + w, ARect.Top + th);
  end
  else
  begin
    rs := ARect;

    mw := 0;
    i := 1;
    ths := FFont.Size * 0.5;
    lcnt := 0;
    fws := 0;
    tw := 0;
    s := FindNextWord(Text, i);
    w := TextWidth(s);
    th := TextHeight(s) + ths;

    mw := mw + w;
    if (Length(s) > 0) and (s[Length(s)] = ' ') then
      mw := mw + fws;

    fx := False;
    while i <= Length(Text) do
    begin
      l := Length(s);
      if (l >= 2) and (((s[l] = #10) and (s[l - 1] = #13)) or ((s[l] = #13) and (s[l - 1] = #10))) then
      begin
        s := Copy(s, 1, l - 2);
        f := True;
      end
      else if (l >= 1) and ((s[l] = #10) or (s[l] = #13)) then
      begin
        s := Copy(s, 1, l - 1);
        f := True;
      end
      else
        f := False;

      sn := FindNextWord(Text, i);
      w := TextWidth(sn);
      th := Max(th, TextHeight(sn) + ths);

      if (ARect.Left + mw + w > ARect.Right) or f then
      begin
        if (s <> '') and not fx then
        begin
          p := Length(s);

          st := Copy(s, 1, p);

          Inc(lcnt);
          if mw > tw then
            tw := mw;

          if not Calculate then
            DrawText(st, mw, th);

          mw := 0;
        end;

        s := '';

        fx := False;
        if (wwx and f) or not wwx then
          ARect.Top := ARect.Top + th
        else if (wwx and not f) then
          fx := True;

        if (Trunc(ARect.Top) > Trunc(ARect.Bottom - th + ths)) and not Calculate then
          Break;
      end;

      mw := mw + w;
      if (Length(sn) > 0) and (sn[Length(sn)] = ' ') then
        mw := mw + fws;
      s := s + sn;
    end;

    if s <> '' then
    begin
      p := Length(s);
      st := Copy(s, 1, p);
      Inc(lcnt);
      if mw > tw then
        tw := mw;

      if not Calculate then
        DrawText(st, mw, th);
    end;

    Result := CreateCanvasRectF(rs.Left, rs.Top, rs.Left + tw, rs.Top + lcnt * th);
  end;
end;

procedure TCanvas.TextOut(X,Y: Integer; const Text: string);
begin
  TextOut(Double(X), Double(Y), Text);
end;

function TCanvas.TextRect(ARect: TRect; const Text: String; const WordWrap: Boolean = False; const Calculate: Boolean = False; const AHorizontalAlignment: TAlignment = taLeftJustify; const AVerticalAlignment: TVerticalAlignment = taAlignTop): TRect;
var
  r: TCanvasRectF;
begin
  r := TextRect(CreateCanvasRectF(ARect.Left, ARect.Top, ARect.Right, ARect.Bottom), Text, WordWrap, Calculate, AHorizontalAlignment, AVerticalAlignment);
  Result := Rect(Round(r.Left), Round(r.Top), Round(r.Right), Round(r.Bottom));
end;

function TCanvas.TextWidth(const Text: string): Double;
var
  f: string;
  tm: TJSTextMetrics;
begin
  if Assigned(FContext) then
  begin
    f := Font.ToString;
    FContext.font := f;
    tm := FContext.measureText(Text);
    Result := tm.width;
  end;
end;

procedure TCanvas.Transform(m11, m12, m21, m22, dx, dy: Double);
begin
  if Assigned(FContext) then
    FContext.transform(m11, m12, m21, m22, dx, dy);
end;

procedure TCanvas.Translate(X, Y: Double);
begin
  if Assigned(FContext) then
    FContext.translate(X, Y);
end;

procedure TCanvas.Draw(X, Y: Integer; Graphic: TGraphic);
begin
  Draw(Double(X), Double(Y), Graphic);
end;

destructor TCanvas.Destroy;
begin
  inherited;
end;

procedure TCanvas.Draw(X, Y: Double; Graphic: TGraphic);
var
  img: TJSObject;
begin
  if Assigned(FContext) then
  begin
    img := Graphic.Image;

    if Assigned(img) then
    begin
      if Graphic.FUsedCanvas then
        FContext.drawImage(TJSObject(Graphic.FCanvasElement), X, Y)
      else
        FContext.drawImage(img, X, Y);
    end;
  end;
end;

procedure TCanvas.DrawFocusRect(const Rect: TCanvasRectF);
var
  ps: TPenStyle;
begin
  ps := Pen.Style;
  Pen.Style := psDot;
  Pen.Width := 1;
  Pen.Color := clBlack;

  MoveTo(Rect.Left, Rect.Top);
  LineTo(Rect.Right, Rect.Top);
  LineTo(Rect.Right, Rect.Bottom);
  LineTo(Rect.Left, Rect.Bottom);
  LineTo(Rect.Left, Rect.Top);

  Pen.Style := ps;
end;

procedure TCanvas.DrawFocusRect(const Rect: TRect);
begin
  DrawFocusRect(CreateCanvasRectF(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom));
end;

procedure TCanvas.Ellipse(X1, Y1, X2, Y2: Double);
var
  w, h: Double;
  kappa: Double;
  ox, oy, xe, ye, xm, ym: Double;
begin
  if Assigned(FContext) then
  begin
    w := x2 - x1;
    h := y2 - y1;

    kappa := 0.5522848;

    ox := (w / 2) * kappa;
    oy := (h / 2) * kappa;
    xe := x1 + w;
    ye := y1 + h;
    xm := x1 + w / 2;
    ym := y1 + h / 2;

    FContext.save;
    asm
      let region = new Path2D();
      region.moveTo(X1, ym);
      region.bezierCurveTo(X1, ym - oy, xm - ox, Y1, xm, Y1);
      region.bezierCurveTo(xm + ox, Y1, xe, ym - oy, xe, ym);
      region.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
      region.bezierCurveTo(xm - ox, ye, X1, ym + oy, X1, ym);
      region.closePath();
      this.FContext.clip(region, "evenodd");
    end;

    if not (Brush.Style in [bsSolid,bsClear]) and (Brush.Gradient = bgNone) then
      ApplyBrush(X1,Y1,X2,Y2);

    FContext.restore;

    FContext.beginPath();
    ApplyStroke;

    if Brush.Gradient <> bgNone then
    begin
      ApplyBrush(x1,y1,x2,y2);
    end
    else
      ApplyFill;

    FContext.moveTo(X1, ym);
    FContext.bezierCurveTo(X1, ym - oy, xm - ox, Y1, xm, Y1);
    FContext.bezierCurveTo(xm + ox, Y1, xe, ym - oy, xe, ym);
    FContext.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
    FContext.bezierCurveTo(xm - ox, ye, X1, ym + oy, X1, ym);
    FContext.closePath();

    if (Brush.Style = bsSolid) or (Brush.Gradient <> bgNone) then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.GetAccuOffset(X,Y: double; var dx, dy: single);
begin
  dx := 0;
  dy := 0;

  if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(X) = 0) then
    dx := 0.5;

  if (Pen.Width = 1) and (Pen.Style <> psClear) and (Frac(Y) = 0) then
    dy := 0.5;
end;

function TCanvas.GetBase64Image: string;
begin
  Result := '';
  if Assigned(Element) then
    Result := Element.toDataURL();
end;

function TCanvas.GetPixel(X,Y: Single): TColor;
var
  imgd: TJSImageData;
begin
  Result := clNone;
  if Assigned(FContext) then
  begin
    imgd := FContext.getImageData(x, y, 1, 1);
    Result := RGBA(imgd.data[0], imgd.data[1], imgd.data[2], imgd.data[3]);
  end;
end;

function TCanvas.GetAsImage(AType: TImageType): string;
begin
  Result := '';
  if Assigned(Element) then
  begin
    case AType of
    itBase64: Result := Element.toDataURL();
    itPNG: Result := Element.toDataURL('image/png');
    itJPEG: Result := Element.toDataURL('image/jpeg',1.0);
    itBMP: Result := Element.toDataURL('image/bmp');
    itGIF: Result := Element.toDataURL('image/gif');
    itSVG: Result := Element.toDataURL('image/svg+xml');
   end;
  end;
end;

{$HINTS OFF}
procedure TCanvas.DownloadImage( AFileName: string; AType: TImageType = itPNG);
var
  s: string;
  el: TJSHTMLElement;
begin
  if Assigned(Element) then
  begin
    s := GetAsImage(AType);

    el := TJSHTMLElement(document.createElement('a'));
    el['href'] := s;
    if (AFileName <> '') then
      el['download'] := AFileName;
    el.style.setProperty('display','none');
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  end;
end;
{$HINTS ON}

procedure TCanvas.LineTo(X, Y: Double);
var
  dx,dy: single;
  clr: TColor;
begin
  if Assigned(FContext) then
  begin
    dx := 0;
    dy := 0;
    GetAccuOffset(X,Y,dx,dy);

    case Pen.Mode of
    pmBlack: FContext.strokeStyleAsColor := ColorToHtml(clBlack);
    pmWhite: FContext.strokeStyleAsColor := ColorToHtml(clWhite);
    pmXor: FContext.globalCompositeOperation := 'xor';
    pmMask: FContext.globalCompositeOperation := 'source-atop';
    pmNotCopy:
      begin
        clr := ColorToRGB(Pen.Color) xor $FFFFFFFF;
        FContext.strokeStyleAsColor := ColorToHtml(clr);
      end;
    pmNotXor:
      begin
        clr := ColorToRGB(Pen.Color) xor $FFFFFFFF;
        FContext.strokeStyleAsColor := ColorToHtml(clr);
        FContext.globalCompositeOperation := 'xor';
      end;
    end;


    if not FPathOpen then
    begin
      FContext.beginPath();
      ApplyStroke;
      FContext.moveTo(FPathX + dx, FPathY + dy);
    end;

    FContext.lineTo(X + dx, Y + dy);
    FContext.stroke();
    FPathX := X;
    FPathY := Y;

    FPathOpen := false;

    FContext.globalCompositeOperation := 'source-over';
  end;
end;

procedure TCanvas.Save;
begin
  if Assigned(FContext) then
    FContext.save();
end;

procedure TCanvas.SetClipRect(const Value: TCanvasRectF);
begin
  FClipRect := Value;
  if Assigned(FContext) then
  begin
    FContext.beginPath;
    FContext.rect(FClipRect.Left, FClipRect.Top, FClipRect.Right - FClipRect.Left, FClipRect.Bottom - FClipRect.Top);
    FContext.clip;
  end;
end;

procedure TCanvas.SetPixel(X,Y: Single; Clr: TColor);
begin
  if Assigned(FContext) then
  begin
    FContext.fillStyle := ColorToHtml(Clr);
    FContext.fillRect(x,y, 1, 1);
  end;
end;

procedure TCanvas.SetTransform(m11, m12, m21, m22, dx, dy: Double);
var
  m: TMatrix;
  px: Single;
begin
  if Assigned(FContext) then
  begin
    m := MatrixIdentity;
    m.m11 := m11;
    m.m12 := m12;
    m.m21 := m21;
    m.m22 := m22;
    m.m31 := dx;
    m.m32 := dy;

    if ApplyPixelRatio then
    begin
      px := GetPixelRatio(Self);
      m := MatrixMultiply(m, MatrixCreateScaling(px, px));
    end;

    FContext.setTransform(m.m11, m.m12, m.m21, m.m22, m.m31, m.m32);
  end;
end;

procedure TCanvas.StretchDraw(Rect: TCanvasRectF; Graphic: TGraphic);
var
  img: TJSObject;
begin
  if Assigned(FContext) then
  begin
    img := Graphic.Image;
    FContext.drawImage(img, 0, 0, Graphic.width, Graphic.height, Rect.Left, Rect.Top, Rect.Right - Rect.Left, Rect.Bottom - Rect.Top);
  end;
end;

procedure TCanvas.StretchDraw(Rect: TRect; Graphic: TGraphic);
begin
  StretchDraw(CreateCanvasRectF(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom), Graphic);
end;

procedure TCanvas.PathLineTo(X, Y: Integer);
begin
  PathLineTo(Double(X), Double(Y));
end;

procedure TCanvas.PathLineTo(X, Y: Double);
var
  dx, dy: Double;
begin
  if Assigned(FContext) then
  begin
    dx := 0;
    dy := 0;
    GetAccuOffset(X, Y, dx, dy);
    FContext.lineTo(X + dx, Y + dy);
  end;
end;

procedure TCanvas.PathFill;
begin
  if Assigned(FContext) then
  begin
    ApplyFill;

    ApplyBrush(10,10,50,50);

    FContext.fill();
  end;
end;

procedure TCanvas.PathStroke;
begin
  if Assigned(FContext) then
  begin
    ApplyStroke;
    FContext.stroke();
  end;
end;

procedure TCanvas.PathMoveTo(X, Y: Integer);
begin
  PathMoveTo(Double(X), Double(Y));
end;

procedure TCanvas.PathMoveTo(X, Y: Double);
var
  dx, dy: Double;
begin
  if Assigned(FContext) then
  begin
    dx := 0;
    dy := 0;
    GetAccuOffset(X, Y, dx, dy);
    FContext.moveTo(X + dx, Y + dy);
  end;
end;

procedure TCanvas.Polygon(const Points: array of TCanvasPointF);
var
  l, i: Integer;
  x1,y1,x2,y2: Double;

begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    if l = 0 then
      Exit;

    FContext.beginPath();
    ApplyStroke;

    x1 := $FFFF;
    y1 := $FFFF;
    x2 := 0;
    y2 := 0;
    for i := 0 to Length(Points) - 1 do
    begin
      if Points[i].X < x1 then
        x1 := Points[i].X;
      if Points[i].Y < y1 then
        y1 := Points[i].Y;

      if Points[i].X > x2 then
        x2 := Points[i].X;
      if Points[i].Y > y2 then
        y2 := Points[i].Y;
    end;

    if Brush.Gradient <> bgNone then
      ApplyBrush(x1,y1,x2,y2)
    else
      ApplyFill;

    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    FContext.closePath();

    if Brush.Style <> bsClear then
      FContext.fill();

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Polyline(const Points: array of TCanvasPointF);
var
  l, i: Integer;
begin
  if Assigned(FContext) then
  begin
    l := Length(Points);

    if l = 0 then
      Exit;

    FContext.beginPath();
    ApplyStroke;
    i := 0;
    FContext.moveTo(Points[i].X, Points[i].Y);

    while (i < l - 1) do
    begin
      inc(i);
      FContext.lineTo(Points[i].X, Points[i].Y);
    end;

    if Pen.Style <> psClear then
      FContext.stroke();
  end;
end;

procedure TCanvas.Ellipse(const Rect: TCanvasRectF);
begin
  Ellipse(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.PathClose;
begin
  if Assigned(FContext) then
    FContext.closePath();
end;

procedure TCanvas.EndScene;
begin
  if Assigned(OnEndScene) then
    OnEndScene(Self);
end;

procedure TCanvas.FillRect(const Rect: TCanvasRectF);
begin
  Rectangle(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom);
end;

procedure TCanvas.Rotate(Angle: Double);
begin
  if Assigned(FContext) then
    FContext.rotate(Angle);
end;

procedure TCanvas.RoundRect(const Rect: TCanvasRectF; CX, CY: Double);
begin
  RoundRect(Rect.Left, Rect.Top, Rect.Right, Rect.Bottom, CX, CY);
end;

{ TGraphic }

constructor TGraphic.Create(Img: TJSObject);
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  FDataBin := '';
  FURL := '';
  FImage := Img;
  FLoaded := nil;
end;

procedure TGraphic.AssignEvents;
begin
  asm
    var me = this;
    this.FImage.onload = function() {
       me.DoChange();
      };
  end;
end;

procedure TGraphic.CaptureCanvas;
begin
  if Assigned(FCanvas) and Assigned(FCanvas.Element) then
    LoadFromResource(FCanvas.Element.toDataURL());
end;

constructor TGraphic.Create;
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  FDataBin := '';
  FUsedCanvas := False;
  CreateImage;
  AssignEvents;
end;

constructor TGraphic.Create(URL: string);
begin
  FAddToQueue := True;
  FEmpty := True;
  FData := '';
  FDataBin := '';
  Create;
  LoadFromURL(URL);
end;

procedure TGraphic.Assign(Source: TPersistent);
var
  s: string;
begin
  if Assigned(Source) and (Source is TGraphic) and Assigned((Source as TGraphic).FCanvas) then
    LoadFromCanvas((Source as TGraphic).FCanvas)
  else if Assigned(Source) and (Source is TGraphic) and Assigned((Source as TGraphic).FImage) and not (Source as TGraphic).Empty then
  begin
    asm
      s = Source.FImage.src;
    end;

    LoadFromURL(s);
  end
  else if not Assigned(Source) then
  begin
    CreateImage;
    DoChange;
  end;
end;

function TGraphic.Image: TJSObject;
begin
  Result := FImage;
end;

procedure TGraphic.LoadFromCache(AData: String; ALoaded: TBitmapLoadedProc = nil);
var
  dt: string;
  o: TJSObject;
  b: Boolean;
  s: string;
  l: Boolean;
begin
  FData := AData;
  if Pos('DATA:', UpperCase(AData)) > 0 then
  begin
    FDataBin := HexImageEncodeFromBase64(AData);
  end;

  dt := AData;
  if dt = '' then
    Exit;

  FLoaded := ALoaded;
  FUsedCanvas := false;

  if not FCache.Find(dt, o) then
  begin
    b := False;
    s := '';
    asm
      s = this.FImage.src;
      b = (s != '');
    end;

    l := b and (dt <> s);
    if l then
    begin
      CreateImage;
      AssignEvents;
    end;

    if (not l and (FQueue.IndexOf(dt) = -1)) or l then
    begin
      asm
        this.FImage.src = dt;
        dt = this.FImage.src;
      end;

      FData := dt;
      if Pos('DATA:', UpperCase(dt)) > 0 then
        FDataBin := HexImageEncodeFromBase64(dt);

      Inc(FCacheCount);
      if FAddToQueue then
        FQueue.Add(dt);
    end
    else if (not l and (FQueue.IndexOf(dt) <> -1)) then
    begin
      asm
        this.FImage.src = dt;
      end;
    end;
  end
  else
  begin
    FImage := o;
    DoChange;
  end;

  asm
    if (this.FImage) {
      this.FEmpty = (this.FImage.src == '');
    }
  end;
end;

procedure TGraphic.LoadFromCanvas(ACanvas: TCanvas);
begin
  if Assigned(ACanvas) and Assigned(ACanvas.Element) then
    LoadFromResource(ACanvas.Element.toDataURL());
end;

procedure TGraphic.LoadFromFile(AFileName: string; ALoaded: TBitmapLoadedProc);
begin
  LoadFromURL(AFileName, 0, ALoaded);
end;

function TGraphic.GetAsImage(AType: TImageType): string;
begin
  Result := '';
  if Assigned(FCanvas) then
    Result := FCanvas.GetAsImage(AType);
end;

function TGraphic.GetBase64Image: string;
begin
  Result := '';
  if Assigned(FCanvas) then
    Result := FCanvas.GetBase64Image;
end;

procedure TGraphic.LoadFromResource(AResource: string; AHInstance: Integer);
begin
  FEmpty := True;
  LoadFromCache(AResource);
end;

procedure TGraphic.LoadFromResource(AResource: string);
begin
  LoadFromResource(AResource, 0);
end;

procedure TGraphic.LoadFromStream(AStream: TStream);
begin
  DoChange;
end;

procedure TGraphic.LoadFromURL(AURL: string; AHInstance: Integer; ALoaded: TBitmapLoadedProc);
begin
  FEmpty := True;
  LoadFromCache(AURL, ALoaded);
end;

procedure TGraphic.LoadFromURL(AURL: string; ALoaded: TBitmapLoadedProc);
begin
  LoadFromURL(AURL, 0, ALoaded);
end;

procedure TGraphic.RecreateCanvas;
begin
  if not Assigned(FCanvasElement) then
    FCanvasElement := TJSHTMLCanvasElement(document.createElement('CANVAS'));

  if Assigned(FCanvasElement) then
  begin
    FCanvasElement.height := Height;
    FCanvasElement.width := Width;
    if not Assigned(FCanvas) then
    begin
      FCanvas := TCanvas.Create(FCanvasElement);
      FCanvas.OnBeginScene := DoBeginScene;
      FCanvas.OnEndScene := DoEndScene;
    end;
  end;
end;

class function TGraphic.CreateFromResource(AResource: String;
  AInstance: NativeUInt): TGraphic;
begin
  Result := TGraphic.Create;
  Result.LoadFromResource(AResource);
end;

class function TGraphic.CreateFromURL(AURL: String): TGraphic;
begin
  Result := CreateFromURL(AURL, 0);
end;

class function TGraphic.CreateFromURL(AURL: String;
  AInstance: NativeUInt): TGraphic;
begin
  Result := TGraphic.Create;
  Result.LoadFromURL(AURL);
end;

class function TGraphic.CreateFromResource(AResource: string): TGraphic;
begin
  Result := CreateFromResource(AResource, 0);
end;

procedure TGraphic.CreateImage;
begin
  asm
    this.FImage = new Image();
  end;
end;

procedure TGraphic.DoBeginScene(Sender: TObject);
begin
  FCanvas.Clear;
end;

procedure TGraphic.DoChange;
var
  i: Integer;
begin
  FEmpty := (Width = 0) and (Height = 0);
  if not FEmpty and (FData <> '') and not FCache.Exists(FData) then
  begin
    FCache.Add(TGraphicCache.Create(FImage, FData));
    i := FQueue.IndexOf(FData);
    if (i >= 0) and (i <= FQueue.Count - 1) then
      FQueue.Delete(i);

    Dec(FCacheCount);
    if FCacheCount = 0 then
    begin
      if Assigned(Application.OnImageCacheReady) then
        Application.OnImageCacheReady(Application);
    end;
  end;

  if Assigned(FOnChange) then
  begin
    FOnChange(Self);
  end;

  if Assigned(FLoaded) then
  begin
    FLoaded;
    FLoaded := nil;
  end;
end;

procedure TGraphic.DoEndScene(Sender: TObject);
begin
  FAddToQueue := False;
  CaptureCanvas;
  FAddToQueue := True;
end;

function TGraphic.Empty: Boolean;
begin
  Result := FEmpty;
end;

procedure TGraphic.SetData(const Value: TBinaryString);
var
  hx: string;
begin
  if (FDataBin <> Value) then
  begin
    FDataBin := Value;
    hx := HexImageDecodeAsBase64(FDataBin);
    if Pos('PHN2ZyB', hx) > 0 then
      LoadFromResource('data:image/svg+xml;base64,' + hx)
    else
      LoadFromResource('data:image/png;base64,' + hx);
  end;
end;

procedure TGraphic.SetHeight(const Value: Integer);
begin
  asm
    this.FImage.height = Value;
  end;
  RecreateCanvas;
  DoChange;
end;

procedure TGraphic.SetSize(AWidth, AHeight: Integer);
begin
  Width := AWidth;
  Height := AHeight;
end;

procedure TGraphic.SetURL(const URL: string);
begin
  LoadFromURL(URL);
end;

procedure TGraphic.SetWidth(const Value: Integer);
begin
  asm
    this.FImage.width = Value;
  end;
  RecreateCanvas;
  DoChange;
end;

function TGraphic.GetWidth: Integer;
var
  w: integer;
begin
  w := 0;
  if Assigned(FImage) then
  begin
    asm
      w = this.FImage.width;
    end;
  end;
  Result := w;
end;

function TGraphic.GetCanvas: TCanvas;
begin
  if not Assigned(FCanvas) then
    RecreateCanvas;

  Result := FCanvas;
  FUsedCanvas := True;
end;

function TGraphic.GetData: TBinaryString;
begin
  Result := FDataBin;
end;

function TGraphic.GetHeight: Integer;
var
  h: integer;
begin
  h := 0;
  if Assigned(FImage) then
  begin
    asm
      h = this.FImage.height;
    end;
  end;
  Result := h;
end;

{ TGraphicCache }

constructor TGraphicCache.Create(AImage: TJSObject; AID: string);
begin
  FImage := AImage;
  FID := AID;
end;

{ TGraphicCacheList }

function TGraphicCacheList.Exists(AID: string): Boolean;
var
  I: Integer;
  it: TGraphicCache;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    it := Items[I] as TGraphicCache;
    if (it.ID = AID) and Assigned(it.Image) then
    begin
      Result := True;
      Break;
    end;
  end;
end;

function TGraphicCacheList.Find(AID: string; var FImage: TJSObject): Boolean;
var
  I: Integer;
  it: TGraphicCache;
begin
  Result := False;
  for I := 0 to Count - 1 do
  begin
    it := Items[I] as TGraphicCache;
    if (it.ID = AID) and Assigned(it.Image) then
    begin
      FImage := it.Image;
      Result := True;
      Break;
    end;
  end;
end;

{ TURLPicture }

procedure TURLPicture.Assign(Source: TPersistent);
begin
  if (Source is TURLPicture) then
  begin
    FFileName := (Source as TURLPicture).FileName;
    Changed;
  end
  else
    inherited;
end;

procedure TURLPicture.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TURLPicture.DataChanged;
begin
  if Assigned(OnDataChange) then
    OnDataChange(Self);
end;

function TURLPicture.GetData: TBinaryString;
begin
  Result := FData;
end;

procedure TURLPicture.LoadFromFile(AFileName: string);
begin
  FFilename := AFileName;
  Changed;
end;

procedure TURLPicture.SetData(const Value: TBinaryString);
begin
  if (FData <> Value) then
  begin
    FData := Value;
    DataChanged;
  end;
end;

{ TGradientColor }

procedure TGradientColor.Assign(Source: TPersistent);
begin
  if (Source is TGradientColor) then
  begin
    FColor := (Source as TGradientColor).Color;
    FStop := (Source as TGradientColor).Stop;
  end;
end;

constructor TGradientColor.Create;
begin
  FColor := clNone;
  FStop := 0;
end;

initialization
begin
  FCache := TGraphicCacheList.Create;
  FQueue := TStringList.Create;
end;

end.
