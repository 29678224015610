{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCWXCommon;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  SysUtils, WEBLib.TMSFNCGraphics;

type
  TTMSFNCWXLibraryLocation = (llOffline, llOnline);

  function FloatToJSValue(const Value: Extended; const Format: String = '0.###'): string;
  function JSStringToFloat(const Value: string): Double;
  function GetDesignHeaderTextHeight(APadding: Integer = 0): Integer;
  function LoadResourceFile(AResName: string): string;

implementation

uses
  Classes, WEBLib.TMSFNCUtils;

function FloatToJSValue(const Value: Extended; const Format: String = '0.###'): string;
{$IFNDEF WEBLIB}
var
  myFormatSettings: TFormatSettings;
{$ENDIF}
begin
  {$IFNDEF WEBLIB}
  myFormatSettings.DecimalSeparator := '.';
  Result := FormatFloat(Format, Value, myFormatSettings);
  {$ENDIF}

  {$IFDEF WEBLIB}
  Result := FloatToStr(Value)
  {$ENDIF}
end;

function JSStringToFloat(const Value: string): Double;
{$IFNDEF WEBLIB}
var
  myFormatSettings: TFormatSettings;
{$ENDIF}
begin
  {$IFNDEF WEBLIB}
  myFormatSettings.DecimalSeparator := '.';
  Result := StrToFloat(Value, myFormatSettings);
  {$ENDIF}

  {$IFDEF WEBLIB}
  Result := StrToFloat(Value)
  {$ENDIF}
end;

function GetDesignHeaderTextHeight(APadding: Integer): Integer;
var
  g: TTMSFNCGraphics;
begin
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  try
    Result := Round(g.CalculateTextHeight('AAA') + APadding);
  finally
    g.EndScene;
    g.Free;
  end;
end;

function LoadResourceFile(AResName: string): string;
{$IFNDEF WEBLIB}
var
  RS: TResourceStream;
  ST: TStringList;
{$ENDIF}
begin
  Result := '';
{$IFNDEF WEBLIB}
  ST := TStringList.Create;
  begin
    RS := TTMSFNCUtils.GetResourceStream(AResName);
    try
      ST.LoadFromStream(RS{$IFNDEF LCLLIB}, TEncoding.UTF8{$ENDIF});
      Result := ST.text;
    finally
      RS.Free;
    end;
  end;
  ST.Free;
{$ENDIF}
end;

end.
