unit WEBLib.Clipboard;

{$DEFINE NOPP}

interface

uses
  Classes, web, js;

type
  TClipboardImageDataEvent = procedure(ADataURL: string) of object;
  TClipboardTextDataEvent = procedure(AData: string; AMimeType: string) of object;

  TClipboard = class(TComponent)
  private
    FOnImageData: TClipboardImageDataEvent;
    FOnTextData: TClipboardTextDataEvent;
  protected
    procedure BindEventListener;
    procedure UnbindEventListener;
    function DoHandlePaste(Event: TEventListenerEvent): Boolean;
    function HandleFileLoad(Event: TEventListenerEvent): Boolean;
    procedure ReadStringData(AItem: TJSDataTransferItem; AMimeType: string);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure CopyToClipboard(const AValue: string);
    function AsText: string; async;
    procedure SetAsText(const AValue: string);
    procedure SetAsImage(img: TJSHTMLImageElement); async;
  published
    property OnImageData: TClipboardImageDataEvent read FOnImageData write FOnImageData;
    property OnTextData: TClipboardTextDataEvent read FOnTextData write FOnTextData;
  end;

  TWebClipboard = class(TClipboard);

implementation

{ TClipboardImage }


function TClipboard.AsText: string;
var
  res: string;
begin
  asm
    res = await navigator.clipboard.readText();
  end;

  Result := res;
end;

procedure TClipboard.SetAsText(const AValue: string);
begin
  asm
    window.navigator.clipboard.writeText(AValue);
  end;
end;

procedure TClipboard.SetAsImage(img: TJSHTMLImageElement);
begin
  asm
    const response = await fetch(img.currentSrc);
    const blob = await response.blob();
    await navigator.clipboard.write([new ClipboardItem({'image/png': blob})]);
  end;
end;


procedure TClipboard.BindEventListener;
begin
  window.addEventListener('paste', @DoHandlePaste);
end;

procedure TClipboard.CopyToClipboard(const AValue: string);
begin
  asm
    if (window.navigator.clipboard != null) {
      window.navigator.clipboard.writeText(AValue);
    }
  end;
end;

constructor TClipboard.Create(AOwner: TComponent);
begin
  inherited;
  BindEventListener;
end;

destructor TClipboard.Destroy;
begin
  UnbindEventListener;
  inherited;
end;

procedure TClipboard.ReadStringData(AItem: TJSDataTransferItem;
  AMimeType: string);
begin
  AItem.getAsString(procedure (AData: string)
  begin
    if Assigned(OnTextData) then
      OnTextData(AData, AMimeType);
  end);
end;

function TClipboard.HandleFileLoad(Event: TEventListenerEvent): Boolean;
var
  du: string;
begin
  Result := True;
  asm
    du = Event.target.result;
  end;
  if Assigned(OnImageData) then
    OnImageData(du);
end;

function TClipboard.DoHandlePaste(Event: TEventListenerEvent): Boolean;
var
  data: TJSDataTransferItemList;
  I: Integer;
  blob: TJSBlob;
  fr: TJSFileReader;
begin
  Result := True;
  asm
    data = Event.clipboardData.items;
  end;
  for I := 0 to data.length - 1 do
  begin
    if (data.Items[I].kind = 'file') and (Pos('image', data.Items[I]._Type) > 0) then
    begin
      blob := data.Items[I].getAsFile;
      if Assigned(blob) then
      begin
        fr := TJSFileReader.new;
        fr.onload := HandleFileLoad;
        fr.readAsDataURL(blob);
      end;
    end
    else if data.Items[I].kind = 'string' then
      ReadStringData(data.Items[I], data.Items[I]._Type);
  end;
end;

procedure TClipboard.UnbindEventListener;
begin
  window.removeEventListener('paste', @DoHandlePaste);
end;

end.
